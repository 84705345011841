import React, { useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Paper } from '@material-ui/core';
import Global from '../../Contexts/Global';
import Form from '../../Components/Form';
import { emptyAutocompleteOptions } from '../../redux/actions/autocompleteActions';
import { emptyRequestDetail, getRequestDetail } from '../../redux/actions/requestDetailActions';
import LoadingSpinner from '../../Components/LoadingSpinner';
import useStyles from './style';
import {conditionalElement} from "../../utility";

function RequestDetail({ dispatch, autocompleteOptions, requestDetailData }) {
  const { i18n } = useContext(Global);
  const classes = useStyles();
  const location = useLocation();
  const [initialForm, setInitialForm] = useState({});
  const [originLocationsForm, setOriginLocationsForm] = useState();
  const [destinyLocationsForm, setDestinyLocationsForm] = useState();
  const [finalForm, setFinalForm] = useState({});
  const [vehiclesArray, setvehiclesArray] = useState();
  const [docsArray, setDocsArray] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const requestNumber = location.pathname.split('/')[location.pathname.split('/').length - 1];
  const date = new Date();
  let month = date.getMonth();
  if (month < 10) month = `0${month}`;
  let day = date.getDate();
  if (day < 10) day = `0${day}`;

  useEffect(() => {
    dispatch(getRequestDetail({ requestNumber, setIsLoading }));
  }, []);

  useEffect(() => {
    if (Object.keys(requestDetailData).length) {
      setInitialForm({
        inputs: [
          {
            label: i18n.t('ORDER_DATE'),
            placeholder: '123',
            textFieldProps: {
              fullWidth: true,
              InputLabelProps: { shrink: true },
              type: 'date',
              disabled: true,
              id: 'ORDER_DATE',
              value: requestDetailData.order_date?.slice(0, 10),
            },
            readOnly: true,
            gridProps: { xs: 12, sm: 6, md: 6, lg: 3 },
          },
          {
            label: i18n.t('REQUESTED_BY'),
            placeholder: '123',
            textFieldProps: {
              fullWidth: true,
              value: requestDetailData.user_id?.name,
              disabled: true,
              id: 'REQUESTED_BY',
            },
            readOnly: true,
            gridProps: { xs: 12, sm: 6, md: 6, lg: 3 },
          },
          {
            label: i18n.t('CHARGE_TO'),
            placeholder: i18n.t('BARCELONA'),
            textFieldProps: {
              fullWidth: true,
              id: 'charge_to_id',
              value: requestDetailData.charge_to?.name,
            },
            readOnly: true,
            gridProps: { xs: 12, sm: 6, md: 6, lg: 3 },
          },
          {
            label: i18n.t('EXTERNAL_REFERENCE'),
            placeholder: '135679',
            textFieldProps: {
              fullWidth: true,
              id: 'external_reference',
              value: requestDetailData.external_reference,
            },
            readOnly: true,
            gridProps: { xs: 12, sm: 6, md: 6, lg: 3 },
          },
        ],
        grid: { spacing: 3 },
      });
      setOriginLocationsForm({
        inputs: [
          {
            label: i18n.t('ORIGIN'),
            placeholder: i18n.t('BARCELONA'),
            textFieldProps: {
              fullWidth: true,
              id: 'origin_location_id',
              value: requestDetailData.origin?.name ?? 'Otros',
            },
            readOnly: true,
            gridProps: { xs: 12, sm: 12, md: 12, lg: 12 },
          },
          {
            label: i18n.t('ORIGIN_DIRECTION'),
            placeholder: '4490CBN',
            textFieldProps: {
              fullWidth: true,
              id: 'origin_address',
              value: requestDetailData.origin_address,
            },
            readOnly: true,
            gridProps: { xs: 12, sm: 6, md: 6, lg: 6 },

          },
          {
            label: i18n.t('ORIGIN_POSTAL_CODE'),
            placeholder: '4490CBN',
            textFieldProps: {
              fullWidth: true,
              id: 'origin_zipcode',
              value: requestDetailData.origin_zipcode,
            },
            readOnly: true,
            gridProps: { xs: 12, sm: 6, md: 6, lg: 6 },

          },
          {
            label: i18n.t('ORIGIN_LOCATION'),
            placeholder: '4490CBN',
            textFieldProps: {
              fullWidth: true,
              id: 'origin_location',
              value: requestDetailData.origin_location,
            },
            readOnly: true,
            gridProps: { xs: 12, sm: 6, md: 6, lg: 6 },

          },
          {
            label: i18n.t('ORIGIN_PROVINCE'),
            placeholder: '4490CBN',
            textFieldProps: {
              fullWidth: true,
              id: 'origin_province',
              value: requestDetailData.origin_province
                  || requestDetailData.origin?.province?.name
                  || requestDetailData.location_origin_province,
            },
            readOnly: true,
            gridProps: { xs: 12, sm: 6, md: 6, lg: 6 },

          },
          {
            label: i18n.t('ORIGIN_CONTACT_PERSON'),
            placeholder: '4490CBN',
            textFieldProps: {
              fullWidth: true,
              id: 'origin_contact',
              value: requestDetailData.origin_contact,
            },
            readOnly: true,
            gridProps: { xs: 12, sm: 6, md: 6, lg: 6 },

          },
          {
            label: i18n.t('ORIGIN_CONTACT_PHONE'),
            placeholder: '4490CBN',
            textFieldProps: {
              fullWidth: true,
              id: 'origin_phone',
              value: requestDetailData.origin_phone,
            },
            readOnly: true,
            gridProps: { xs: 12, sm: 6, md: 6, lg: 6 },

          },
          
          ...conditionalElement( ['otros', 'others'].includes(requestDetailData.origin?.name.toLowerCase()) , {
                  label: i18n.t('ORIGIN_START_DESC'),
                  placeholder: '4490CBN',
                  textFieldProps: {
                    fullWidth: true,
                    id: 'origin_start_desc',
                    value: requestDetailData?.origin_start_desc,
                  },
                  readOnly: true,
                  gridProps: { xs: 12, sm: 12, md: 12, lg: 12 },
                }),
        ],
        buttons: [],
        grid: { spacing: 3 },
      });
      setDestinyLocationsForm({
        inputs: [
          {
            label: i18n.t('DESTINY'),
            textFieldProps: {
              fullWidth: true,
              InputLabelProps: { shrink: true },
              id: 'destination_location_id',
              value: requestDetailData.destination?.name ?? 'Otros',
            },
            readOnly: true,
            placeholder: i18n.t('BILBAO'),
            gridProps: { xs: 12, sm: 12, md: 12, lg: 12 },
          },
          {
            label: i18n.t('DESTINY_DIRECTION'),
            placeholder: '4490CBN',
            textFieldProps: {
              fullWidth: true,
              id: 'destination_address',
              value: requestDetailData.destination_address,
            },
            readOnly: true,
            gridProps: { xs: 12, sm: 6, md: 6, lg: 6 },
          },
          {
            label: i18n.t('DESTINY_POSTAL_CODE'),
            placeholder: '4490CBN',
            textFieldProps: {
              fullWidth: true,
              id: 'destination_zipcode',
              value: requestDetailData.destination_zipcode,
            },
            readOnly: true,
            gridProps: { xs: 12, sm: 6, md: 6, lg: 6 },
          },
          {
            label: i18n.t('DESTINY_LOCATION'),
            placeholder: '4490CBN',
            textFieldProps: {
              fullWidth: true,
              id: 'destination_location',
              value: requestDetailData.destination_location,
            },
            readOnly: true,
            gridProps: { xs: 12, sm: 6, md: 6, lg: 6 },
          },
          {
            label: i18n.t('DESTINY_PROVINCE'),
            placeholder: '4490CBN',
            textFieldProps: {
              fullWidth: true,
              id: 'destination_province',
              value: requestDetailData.destination_province
                    || requestDetailData.destination?.province?.name
                    || requestDetailData.location_destination_province,
            },
            readOnly: true,
            gridProps: { xs: 12, sm: 6, md: 6, lg: 6 },
          },
          {
            label: i18n.t('DESTINY_CONTACT_PERSON'),
            placeholder: '4490CBN',
            textFieldProps: {
              fullWidth: true,
              id: 'destination_contact',
              value: requestDetailData.destination_contact,
            },
            readOnly: true,
            gridProps: { xs: 12, sm: 6, md: 6, lg: 6 },
          },
          {
            label: i18n.t('DESTINY_CONTACT_PHONE'),
            placeholder: '4490CBN',
            textFieldProps: {
              fullWidth: true,
              id: 'destination_phone',
              value: requestDetailData.destination_phone,
            },
            readOnly: true,
            gridProps: { xs: 12, sm: 6, md: 6, lg: 6 },
          },
          ...conditionalElement( ['otros', 'others'].includes(requestDetailData.destination?.name.toLowerCase()) , {
                  label: i18n.t('DESTINY_START_DESC'),
                  placeholder: '4490CBN',
                  textFieldProps: {
                    fullWidth: true,
                    id: 'destination_start_desc',
                    value: requestDetailData?.destination_start_desc,
                  },
                  readOnly: true,
                  gridProps: { xs: 12, sm: 12, md: 12, lg: 12 },
                }),
        ],
        buttons: [],
        grid: { spacing: 3 },
      });
      if(!requestDetailData.cars.length){
        requestDetailData.cars = Object.values(requestDetailData.cars)
      }
      setvehiclesArray(requestDetailData.cars);
      setFinalForm({
        inputs: [
          {
            label: i18n.t('OBSERVATIONS'),
            textFieldProps: {
              fullWidth: true,
              InputLabelProps: { shrink: true },
              multiline: true,
              id: 'observations',
              value: requestDetailData.observations,
            },
            readOnly: true,
            placeholder: i18n.t('OBSERVATIONS'),
            gridProps: { xs: 12, sm: 12, md: 12, lg: 12 },
          },
        ],
        buttons: [],
        grid: { spacing: 3 },
      });
      
      setDocsArray(requestDetailData.documents);
      
    }
  }, [requestDetailData, autocompleteOptions]);

  useEffect(() => () => {
    dispatch(emptyRequestDetail());
    dispatch(emptyAutocompleteOptions());
  }, []);

  return (
    <Paper>
      <div className={classes.mainContainer}>
        {isLoading
          ? (
            <LoadingSpinner />
          )
          : (
            <>
              <div className={classes.initialForm}>
                <Form newForm={initialForm} />
              </div>
              <div className={classes.locationsBlock}>
                <div className={classes.locationSection}>
                  <Form newForm={originLocationsForm} isNew />
                </div>
                <div className={classes.locationSection}>
                  <Form newForm={destinyLocationsForm} isNew />
                </div>
              </div>
              <div> 
              {vehiclesArray && vehiclesArray?.map((car) => {
                let accessories = car.accessories.map(function(acc) { return {title: acc.name}});
                const vehicleForm = {
                  grid: { spacing: 3 },
                  inputs: [
                    {
                      label: i18n.t('MODEL'),
                      placeholder: 'Turismo',
                      textFieldProps: {
                        id: 'model_id',
                        fullWidth: true,
                        value: car.model,
                      },
                      readOnly: true,
                      gridProps: { xs: 12, sm: 6, md: 3, lg: 3 },
                    },
                    {
                      label: i18n.t('REGISTRATION_AND_FRAME'),
                      placeholder: '4490CBN',
                      textFieldProps: {
                        fullWidth: true,
                        id: 'plate_number',
                        value: car.plate_number,
                      },
                      readOnly: true,
                      gridProps: { xs: 12, sm: 6, md: 3, lg: 3 },
                    },
                    {
                      label: i18n.t('ACCESSORIES'),
                      placeholder: i18n.t('CAGE'),
                      defaultValue: accessories ,
                      textFieldProps: {
                        id: 'accessory_id',
                        fullWidth: true,
                      },
                      multiselectProps: { options: car.accessories?.length ? car.accessories : [{ name: 'No' }] },
                      readOnly: true,
                      gridProps: { xs: 12, sm: 6, md: 3, lg: 3 },
                    },
                    {
                      label: i18n.t('STARTS'),
                      placeholder: i18n.t('YES'),
                      textFieldProps: {
                        id: 'start_id',
                        fullWidth: true,
                        value: i18n.t(car.start?.toUpperCase()),
                      },
                      readOnly: true,
                      gridProps: { xs: 12, sm: 6, md: 3, lg: 3 },
                    },
                  ],
                };
                return <Form newForm={vehicleForm} />;
              })}
              </div>
              <div> 
                { docsArray && docsArray?.map((doc) => {
                  const docForm = {
                    inputs: [
                      {
                        label: i18n.t('DOCUMENTATION'),
                        placeholder: i18n.t('FILE'),
                        textFieldProps: {
                          fullWidth: true,
                          required: true,
                          InputLabelProps: { shrink: true },
                          id: 'DOCUMENTATION',
                          disabled: true,
                          value: doc.name || i18n.t('NO_DOCUMENT_ATTACHED'),
                        },
                        documentProps: {
                          name: doc.name || i18n.t('DOCUMENT'),
                          id: doc.id,
                          hasValue: !!doc,
                          dispatch,
                        },
                        readOnly: true,
                        InputLabelProps: {
                          shrink: true,
                        },
                        gridProps: { xs: 12, sm: 12, md: 12, lg: 12 },
                      },
                    ]
                  } 
                  return <Form newForm={docForm} isNew />
                })}
                
                { !docsArray || docsArray.length === 0 && (
                  <Form newForm={
                    {inputs: [
                      {
                        label: i18n.t('DOCUMENTATION'),
                        placeholder: i18n.t('FILE'),
                        textFieldProps: {
                          fullWidth: true,
                          required: true,
                          InputLabelProps: { shrink: true },
                          id: 'DOCUMENTATION',
                          disabled: true,
                          value: i18n.t('NO_DOCUMENT_ATTACHED'),
                        },
                        documentProps: {
                          name: i18n.t('DOCUMENT'),
                          hasValue: false,
                          dispatch,
                        },
                        readOnly: true,
                        InputLabelProps: {
                          shrink: true,
                        },
                        gridProps: { xs: 12, sm: 12, md: 12, lg: 12 },
                      },
                    ]
                  }} isNew />
                )}
              </div>
              <Form newForm={finalForm} isNew />
            </>
          )}
      </div>
    </Paper>
  );
}

RequestDetail.propTypes = {
  dispatch: PropTypes.func.isRequired,
  requestDetailData: PropTypes.shape({
    model: PropTypes.shape({
      name: PropTypes.string,
    }),
    frame_number: PropTypes.string,
    accessory_id: PropTypes.shape({
      name: PropTypes.string,
    }),
    origin: PropTypes.shape({
      name: PropTypes.string,
      province: PropTypes.shape({
        name: PropTypes.string,
      }),
    }),
    destination: PropTypes.shape({
      name: PropTypes.string,
      province: PropTypes.shape({
        name: PropTypes.string,
      }),
    }),
    documents: PropTypes.arrayOf(PropTypes.shape({
      name: PropTypes.string,
      id: PropTypes.number,
      date: PropTypes.string,
    })),
    amount: PropTypes.string,
    plate_number: PropTypes.string,
    external_reference: PropTypes.string,
    observations: PropTypes.string,
    order_date: PropTypes.string,
    origin_address: PropTypes.string,
    location_origin_province: PropTypes.string,
    location_destination_province: PropTypes.string,
    cars: PropTypes.arrayOf(PropTypes.shape({
      model: PropTypes.shape({
        name: PropTypes.string,
      }),
      plate_number: PropTypes.string,
      start: PropTypes.string,
      accessory: PropTypes.string,
    })),
    user_id: PropTypes.shape({
      name: PropTypes.string,
    }),
    charge_to: PropTypes.shape({
      name: PropTypes.string,
    }),
    origin_zipcode: PropTypes.string,
    destination_address: PropTypes.string,
    destination_zipcode: PropTypes.string,
    origin_location: PropTypes.string,
    origin_province: PropTypes.string,
    destination_location: PropTypes.string,
    destination_province: PropTypes.string,
    origin_contact: PropTypes.string,
    origin_phone: PropTypes.string,
    destination_contact: PropTypes.string,
    destination_phone: PropTypes.string,
  }).isRequired,
  autocompleteOptions: PropTypes.shape({
    models: PropTypes.arrayOf(PropTypes.shape({})),
    accessories: PropTypes.arrayOf(PropTypes.shape({})),
    cities: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
};

function mapStateToProps({ autocompleteReducer, requestDetailReducer }) {
  return {
    autocompleteOptions: autocompleteReducer.autocompleteOptions,
    requestDetailData: requestDetailReducer.requestDetailData,
  };
}

export default connect(mapStateToProps)(RequestDetail);
