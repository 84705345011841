/* eslint-disable react/jsx-props-no-spreading */
import React, { useContext, useRef, useState } from 'react';
import {
  Button, Collapse, Grid, TextField, Typography,
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { AddCircleOutline, ArrowDropDown, ArrowDropUp } from '@material-ui/icons';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import Global from '../../Contexts/Global';
import MultiselectField from '../MultiselectField';
import NewMultiselectField from '../NewMultiselectField';
import TextFieldComponent from '../TextFieldComponent';
import FileFieldComponent from '../FileFieldComponent'
import { getDocument } from '../../redux/actions/documentActions';
import useStyles from './style';

const Form = ({ newForm, isNew }) => {
  const { i18n } = useContext(Global);
  const classes = useStyles();
  const [collapse, setCollapse] = useState(false);
  const form = useRef();

  return (
    <div className={classes.formContainer}>
      <form ref={form}>
        <Grid container {...newForm.grid}>
          {newForm && newForm.inputs?.map((item) => (
            <Grid item {...item.gridProps} className={classes.gridItems} key={item.label}>
              {item.outerLabel && (
                <Typography className={classes.outerLabel}>
                  {item.outerLabel}
                </Typography>
              )}
              {item.addBtn && (
                <Button
                  className={classes.button}
                  startIcon={<AddCircleOutline />}
                  onClick={item.addBtn.function}
                >
                  {item.addBtn.label}
                </Button>
              )}
              {
                item.space && <div />
              }
              { item.linkButtonProps
                && (
                  <div
                    className={classes.buttonLink}
                    onClick={() => item.linkButtonProps.onClick(item.linkButtonProps)}
                  >
                    <span>{i18n.t(item.label)}</span>
                  </div>
                )}
              { item.textProps
                && (
                  <div className={classes.simpleText}>
                    <span>{i18n.t(item.label)}</span>
                  </div>
                )}
              { !item.space && !item.multiselectProps && !item.textProps
              && !item.autoCompleteProps && !item.linkButtonProps && !item.documentProps
              && item.textFieldProps.type === 'file'
                  && (
                  <FileFieldComponent className={classes.addButton} item={item} />
                  )}
              { !item.space && !item.multiselectProps && !item.textProps
              && !item.autoCompleteProps && !item.linkButtonProps && !item.documentProps
              && item.textFieldProps.type !== 'file'   
                  && (
                  <TextFieldComponent item={item} />
                  )}
              { !item.space && !item.multiselectProps && !item.textProps
              && !item.autoCompleteProps && !item.linkButtonProps && item.documentProps
                  && (
                    <TextField
                      {...item.textFieldProps}
                      onClick={() => item.documentProps.hasValue
                        && item.documentProps.dispatch(getDocument({
                          id: item.documentProps.id,
                          name: item.documentProps.name,
                        }))}
                      inputProps={{
                        readOnly: false,
                        className: item.documentProps.hasValue && classes.textFieldLink,
                      }}
                      InputLabelProps={item.InputLabelProps}
                      select={false}
                      margin="dense"
                      label={item.label}
                      placeholder={item.placeholder}
                      className={classes.textField}
                      name={item.name}
                      variant="outlined"
                    />
                  )}
              { !item.space && item.multiselectProps && !item.autoCompleteProps && !isNew
                  && (
                  <MultiselectField item={item} />
                  )}
              { !item.space && item.multiselectProps && !item.autoCompleteProps && isNew
                  && (
                  <NewMultiselectField item={item} />
                  )}
              { !item.space && item.autoCompleteProps
              && item.autoCompleteProps?.options && item.readOnly
                  && (
                    <TextField
                      {...item.textFieldProps}
                      inputProps={{
                        readOnly: true,
                      }}
                      InputLabelProps={item.InputLabelProps}
                      select={false}
                      margin="dense"
                      label={item.label}
                      placeholder={item.placeholder}
                      className={classes.textField}
                      name={item.name}
                      variant="outlined"
                    />
                  )}
              
              { !item.space && item.autoCompleteProps
              && item.autoCompleteProps?.options && !item.readOnly
                  && (
                  <Autocomplete
                    options={item.autoCompleteProps.options}
                    getOptionLabel={(option) => option.title}
                    onChange={item.onChange}
                    fullWidth
                    disabled={item.autoCompleteProps?.disabled}
                    defaultValue={item.autoCompleteProps.defaultValue}
                    value={item.autoCompleteProps.options[0] !== null ? item.autoCompleteProps.defaultValue : item.autoCompleteProps.options[0]}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        {...item.textFieldProps}
                        InputLabelProps={item.InputLabelProps}
                        variant="outlined"
                        label={item.label}
                        placeholder={item.placeholder}
                        className={classes.textField}
                        margin="dense"
                      />
                    )}
                  />
                  )
              }
              
            </Grid>
          ))}
          { !collapse && newForm?.buttons?.map((button) => (
            <Grid className={classes.buttonGridItem} item {...button.gridProps} key={button.label}>
              {(button.linkProps && button.linkProps.href)
                ? (
                  <Link to="/order-requests" >
                    <Button
                      {...button.buttonProps}
                      color="primary"
                      onClick={() => button.onClick()}
                      className={classes.buttonField}
                    >
                      {button.label}
                    </Button>
                  </Link>
                )
                : (
                  <Button
                    {...button.buttonProps}
                    color="primary"
                    onClick={() => button.onClick()}
                    className={classes.buttonField}
                  >
                    {button.label}
                  </Button>
                )}
            </Grid>
          ))}
          {newForm?.collapsed && (
            <Collapse in={collapse}>
              <Grid container {...newForm.grid}>
                {newForm?.collapsed?.map((item) => (
                  <Grid item {...item.gridProps}>
                    {
                item.space ? <div /> : (
                  <TextField
                    {...item.textFieldProps}
                    margin="dense"
                    label={item.label}
                    placeholder={item.placeholder}
                    className={classes.textField}
                    name={item.name}
                  />
                )
                }
                  </Grid>
                ))}
                {collapse && newForm?.buttons?.map((btn) => <Grid item {...btn.gridProps}><Button {...btn.buttonProps} color="primary">{btn.label}</Button></Grid>) }
              </Grid>
            </Collapse>
          )}
        </Grid>
      </form>
      {newForm?.collapsed && (
      <Button
        className={classes.moreFilters}
        onClick={() => setCollapse(!collapse)}
        endIcon={collapse ? <ArrowDropUp /> : <ArrowDropDown />}
      >
        {i18n.t('MORE_FILTERS')}
      </Button>
      )}
    </div>
  );
};

Form.propTypes = {
  newForm: PropTypes.shape([]),
  isNew: PropTypes.bool,
};

Form.defaultProps = {
  newForm: {},
  isNew: false,
};

export default Form;
