const esES = {
  ORDER_REQUEST: 'Solicitud de pedido',
  ORDER_REQUESTS: 'Solicitudes de pedidos',
  NEW_ORDER_REQUEST: 'Nueva solicitud',
  ORDERS_IN_PROGRESS: 'Pedidos',
  ORDER_DATE: 'Fecha de pedido',
  PREVISIONS: 'Previsión contable',
  BILLS: 'Facturas',
  REQUESTED_BY: 'Solicitado por:',
  MODEL: 'Modelo',
  REGISTRATION: 'Matrícula',
  FRAME: 'Bastidor',
  VEHICLE_START: 'Arranca',
  ACCESSORIES: 'Accesorios',
  DOCUMENTATION: 'Documentación',
  ORIGIN: 'Origen',
  DESTINY: 'Destino',
  IMPORT: 'Importe',
  CHARGE_TO: 'Con cargo a',
  OBSERVATIONS: 'Observaciones',
  TURISM: 'Turismo',
  YES: 'Sí',
  NO: 'No',
  BACA: 'Baca',
  BALL: 'Bola',
  STAIR: 'Escalera',
  CAGE: 'Caja',
  CHEST: 'Cofre',
  MADRID: 'Madrid',
  BARCELONA: 'Barcelona',
  BILBAO: 'Bilbao',
  VALENCIA: 'Valencia',
  MALAGA: 'Málaga',
  HEADQUARTERS: 'Sede',
  CENTER: 'Centro',
  FILE: 'Archivo',
  SAVE: 'Guardar',
  DELETE_USER: 'Eliminar usuario',
  OTHER: 'Otro',
  ORIGIN_VIA: 'Vía de origen',
  ORIGIN_ADDRESS: 'Nombre de la vía de origen',
  ORIGIN_ADDRESS_NUMBER: 'Número de dirección de origen',
  ORIGIN_CITY: 'Ciudad de origen',
  ORIGIN_PROVINCE: 'Provincia de origen',
  ORIGIN_START_DESC: 'Empresa Origen',
  DESTINY_VIA: 'Vía de destino',
  DESTINY_ADDRESS: 'Nombre de la vía de destino',
  DESTINY_ADDRESS_NUMBER: 'Número de dirección de destino',
  DESTINY_CITY: 'Ciudad de destino',
  DESTINY_PROVINCE: 'Provincia de destino',
  DESTINY_START_DESC: 'Empresa Destino',
  STREET: 'Calle',
  FILTER_NAME: 'Nombre del filtro',
  SEARCH: 'Buscar',
  ASKED_BY: 'Solicitado por',
  DASHBOARD: 'Dashboard',
  'The amount field is required.': 'El campo importe es obligatorio.',
  'The name field is required.': 'El campo usuario es obligatorio.',
  'The password field is required.': 'El campo de contraseña es obligatorio.',
  'These credentials do not match our records.': 'Los datos introducidos no son correctos.',
  STATUS: 'Estado',
  CUSTOMER: 'Cliente',
  ORDER_IN_PROGRESS: 'Pedido',
  STARTS: 'Arranca',
  ADMIN_USERS: 'Usuarios administrador',
  ADMIN_CUSTOMERS: 'Clientes administrador',
  CUSTOMER_EMAIL: 'Editar Email',
  CUSTOMER_LOCATIONS: 'Localizaciones',
  CUSTOMER_CONTRACTS : 'Contratos',
  NAME_AND_LASTNAME: 'Nombre y apellidos',
  CLIENT: 'Cliente',
  DEFAULT_ORIGIN: 'Origen por defecto',
  MONTH: 'Mes',
  JANUARY: 'Enero',
  FEBRUARY: 'Febrero',
  MARCH: 'Marzo',
  APRIL: 'Abril',
  MAY: 'Mayo',
  JUNE: 'Junio',
  JULY: 'Julio',
  AUGUST: 'Agosto',
  SEPTEMBER: 'Septiembre',
  OCTOBER: 'Octubre',
  NOVEMBER: 'Noviembre',
  DECEMBER: 'Diciembre',
  ESTIMATED_ARRIVAL_DATE: 'Fecha estimada de entrega',
  EXPEDITION_NUMBER: 'Número de expedición',
  PLATE_NUMBER: 'Matrícula',
  FRAME_NUMBER: 'Bastidor',
  BILL_DATE: 'Fecha de factura',
  BILL_NUMBER: 'Número de factura',
  IVA: 'Iva',
  TOTAL: 'Total',
  END_DATE: 'Fecha de vencimiento',
  PAY_DATE: 'Fecha de cobro',
  NAME: 'Nombre',
  LASTNAME: 'Apellidos',
  CONFIRM_PASSWORD: 'Confirmar contraseña',
  EXPEDIENT_NUMBER: 'Número de expediente',
  EXTERNAL_REFERENCE: 'Referencia externa',
  EXPECTED_PICK_DATE: 'Fecha prevista de recogida',
  EXPECTED_DELIVERY_DATE: 'Fecha prevista de entrega',
  ATTACHMENT: 'Tiene adjunto',
  REAL_DELIVERY_DATE: 'Fecha real de entrega',
  EXTERNAL_REFERENCE_TYPE: 'Tipo de referencia externa',
  REGISTRATION_AND_FRAME: 'Matrícula / Bastidor',
  USER: 'Usuario',
  PASSWORD: 'Contraseña',
  TRACKING_DATES: 'Fechas de tracking',
  ACTIVE: 'Activo',
  INACTIVE: 'Inactivo',
  DOWNLOAD_EXCEL: 'Descargar Excel',
  YEAR: 'Año',
  ORIGIN_DIRECTION: 'Dirección de origen',
  ORIGIN_POSTAL_CODE: 'Código postal de origen',
  DESTINY_DIRECTION: 'Dirección de destino',
  DESTINY_POSTAL_CODE: 'Código postal de destino',
  ORIGIN_LOCATION: 'Localidad de origen',
  DESTINY_LOCATION: 'Localidad de destino',
  LOCATION: 'Localización',
  ORIGIN_CONTACT_PERSON: 'Persona de contacto de origen',
  ORIGIN_CONTACT_PHONE: 'Teléfono de contacto de origen',
  DESTINY_CONTACT_PERSON: 'Persona de contacto de destino',
  DESTINY_CONTACT_PHONE: 'Teléfono de contacto de destino',
  DATE: 'Fecha',
  TYPE: 'Tipo',
  TRACKING: 'Trazabilidad',
  EXPECTED_DATE: 'Fecha prevista',
  REAL_DATE: 'Fecha real',
  EMAIL: 'Email',
  CONTRACTS: 'Contratos',
  EVENT: 'Evento',
  ADD_NEW_VEHICLE: 'Añadir nuevo vehículo',
  ADD_NEW_DOCUMENT: 'Añadir nuevo documento',
  SAME_ORIGIN_DESTINY_FOR_VEHICLES: '(Todos los vehículos tendrán mismo origen y destino)',
  SEND: 'Enviar',
  DEFAULT_DESTINY: 'Destino por defecto',
  NEW_USER: 'Nuevo usuario',
  PENDANT: 'Pendiente',
  ROLE: 'Rol',
  ADMIN: 'Administrador',
  MANUAL: 'Manual',
  'AUTOMATIC-USER-NAME': 'Automático (Nombre de usuario)',
  TRANSPORTING: 'En transporte',
  DELIVERED: 'Entregado',
  FAILED: 'Fallido',
  CANCELED: 'Cancelado',
  LOGOUT: 'Cerrar sesión',
  DOCUMENTS: 'Documentos',
  BILL: 'Factura',
  NO_DOCUMENT_ATTACHED: 'Ningún documento asociado',
  SHOW: 'Ver',
  ALL: 'Todos',
  LAST_30_DAYS: 'Últimos 30 días',
  PENDIENTE: 'Pendiente',
  'EN TRANSPORTE': 'En transporte',
  ENTREGADO: 'Entregado',
  FALLIDO: 'Fallido',
  ANULADO: 'Anulado',
  SEE_DETAIL: 'Ver detalle',
  OTHERS: 'Otros',
  GET_BILLS: 'Descargar facturas',
  GETING_BILLS: 'Descargando facturas ...',
};

export default esES;
