import actionTypes from '../actions/actionTypes';

export default function contractReducer(state = {}, { type, data }) {
  let newState = null;

  switch (type) {
    case actionTypes.SET_CONTRACTS:
      newState = {
        ...state,
        contracts: data,
      };
      break;
    case actionTypes.EMPTY_CONTRACTS:
      newState = {
        ...state,
        contracts: [],
      };
      break;
    default:
      newState = state;
      break;
  }

  return newState;
}
