/* eslint-disable react/prop-types */
/* eslint-disable max-len */
import React, { useContext, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Paper } from '@material-ui/core';
import Table from '../../Components/Table';
import Global from '../../Contexts/Global';
import FlexForm from '../../Components/FlexForm';
import { emptyOrders, getOrders } from '../../redux/actions/orderActions';
import { emptyAutocompleteOptions, getAutocompleteOptions } from '../../redux/actions/autocompleteActions';
import LoadingSpinner from '../../Components/LoadingSpinner';
import useStyles from './style';
import {setFiltersAction} from "../../redux/actions/filterActions";

const initialTableData = {
  titles: [
    { align: 'left', title: 'ORDER_DATE' },
    { align: 'left', title: 'CHARGE_TO' },
    { align: 'left', title: 'EXTERNAL_REFERENCE' },
    { align: 'left', title: 'MODEL' },
    { align: 'left', title: 'REGISTRATION_AND_FRAME' },
    { align: 'left', title: 'ORIGIN' },
    { align: 'left', title: 'DESTINY' },
    { align: 'left', title: '' },
  ],
  rows: [],
};

const initialParams = {
  order: 'desc',
  orderBy: 'created_at',
};

function getColumnName(column) {
  const columns = {
    order_date: 'created_at',
    model: 'car_model',
    charge_to: 'charge_to_id',
    registration_and_frame: 'car_plate_number',
    origin: 'origin_province',
    destiny: 'destination_province',
  };
  const columnName = columns[column.toLowerCase()]?.toLowerCase() ?? column;
  return columnName;
}

function OrderRequests({ dispatch, orders, autocompleteOptions }) {
  const { i18n } = useContext(Global);
  const classes = useStyles();
  const [tableData, setTableData] = useState(initialTableData);
  const [filterValues, setFilterValues] = useState({
    external_reference: '',
    plate_number: '',
  });
  const [params, setParams] = useState(initialParams);
  const [orderBy, setOrderBy] = useState('');
  const [orderProducts, setOrderProducts] = useState('asc');
  const [isLoading, setIsLoading] = useState(false);
  const autocompleteIds = ['provinces', 'secondary-venues'];
  const date = new Date();
  let month = date.getMonth();
  if (month < 10) month = `0${month}`;
  let day = date.getDate();
  if (day < 10) day = `0${day}`;

  useEffect(() => dispatch(setFiltersAction(params)),[params])

  async function handleRequestSort(column) {
    const isAsc = orderBy === column && orderProducts === 'asc';
    const isDesc = orderBy === column && orderProducts === 'desc';
    const newParams = { ...params, order: isAsc ? 'desc' : 'asc', orderBy: getColumnName(column) };
    if (isDesc) {
      setOrderBy(false);
    } else {
      setOrderBy(column);
    }
    setOrderProducts(isAsc ? 'desc' : 'asc');
    setParams(newParams);
    dispatch(getOrders({ params: newParams, setIsLoading }));
  }

  function setAutocompleteOptions(id) {
    let newOptions = [];
    console.log({autocompleteOptions});
    if (autocompleteOptions && autocompleteOptions[id]) {
      newOptions = autocompleteOptions[id].map((option) => ({
        ...option,
        title: option.name,
      }));
    }
    return newOptions;
  }

  function searchOrders() {
    if (Object.values(params).length) {
      dispatch(getOrders({ params, setIsLoading }));
    } else {
      dispatch(getOrders({ setIsLoading }));
    }
  }

  const newForm = {
    inputs: [
      {
        label: i18n.t('DATE'),
        placeholder: 'Dato filtrado',
        textFieldProps: {
          value: filterValues.date,
          fullWidth: true,
          type: 'date',
          onChange: (event) => {
            setFilterValues({ ...filterValues, date: event.target.value });
            setParams({ ...params, date: event.target.value });
          } },
        InputLabelProps: { shrink: true },
      },
      {
        label: i18n.t('CHARGE_TO'),
        placeholder: 'Dato filtrado',
        textFieldProps: { fullWidth: true },
        autoCompleteProps: { options: setAutocompleteOptions('secondary-venues') },
        onChange: (event, value) => setParams({ ...params, chargeTo: value?.id }),
      },
      {
        label: i18n.t('EXTERNAL_REFERENCE'),
        placeholder: 'Dato filtrado',
        textFieldProps: {
          value: filterValues.external_reference,
          fullWidth: true,
          onChange: (event) => {
            setFilterValues({ ...filterValues, external_reference: event.target.value });
            setParams({ ...params, reference: event.target.value });
          },
          onKeyPress: (e) => { if(e.key === 'Enter'){ e.preventDefault(); searchOrders(); } },
        },
      },
      {
        label: i18n.t('REGISTRATION_AND_FRAME'),
        placeholder: 'Dato filtrado',
        textFieldProps: {
          value: filterValues.plate_number,
          fullWidth: true,
          onChange: (event) => {
            setFilterValues({ ...filterValues, plate_number: event.target.value });
            setParams({ ...params, frame: event.target.value });
          },
          onKeyPress: (e) => { if(e.key === 'Enter'){ e.preventDefault(); searchOrders(); } },
        },
      },
      {
        label: i18n.t('ORIGIN'),
        placeholder: i18n.t('BARCELONA'),
        textFieldProps: { fullWidth: true },
        autoCompleteProps: {
          options: setAutocompleteOptions('provinces').sort((a, b) => a.title.localeCompare(b.title)),
        },
        onChange: (event, value) => setParams({ ...params, origin: value?.title }),
      },
      {
        label: i18n.t('DESTINY'),
        placeholder: i18n.t('BARCELONA'),
        textFieldProps: { fullWidth: true },
        autoCompleteProps: {
          options: setAutocompleteOptions('provinces').sort((a, b) => a.title.localeCompare(b.title)),
        },
        onChange: (event, value) => setParams({ ...params, destination: value?.title }),
      },
    ],
    buttons: [
      { label: i18n.t('SEARCH'), buttonProps: { variant: 'contained' }, onClick: () => searchOrders() },
    ],
  };

  useEffect(() => {
    
    console.log({orders});
    if (orders && Object.values(orders)) {
      setTableData({
        ...tableData,
        rows: Object.values(orders).map((order) => ({
          orderDate: order.order_date?.slice(0, 10).split('-').reverse().join('-') ?? '',
          chargeTo: order.charge_to ?? '',
          externalReference: order.external_reference ?? '',
          model: order.car_model ?? '',
          registration: order.car_plate_number ?? '',
          origin: order.origin_province ?? order.origin?.province?.name ?? order.location_origin_province ?? '',
          destiny: order.destination_province ?? order.destination?.province?.name ?? order.location_destination_province ?? '',
          link: {
            title: 'Ver detalle',
            reference: 'Detail',
            link: true,
            url: order.order_id ? `/order-request/${order.order_id}` : '/order-request/1',
            label: 'detail',
          },
         color: order.rp_failed === 1 ? 'red' : null
        })),
      });
    }
  }, [orders]);

  useEffect(() => {
    dispatch(getOrders({ params, setIsLoading }));
    autocompleteIds.forEach((id) => {
      dispatch(getAutocompleteOptions(id));
    });
  }, []);

  useEffect(() => () => {
    dispatch(emptyOrders());
    dispatch(emptyAutocompleteOptions());
  }, []);

  return (
    <Paper>
      <div className={classes.mainContainer}>
        <FlexForm form={newForm} />
        <div style={{ marginTop: '20px' }}>
          {isLoading
            ? (
              <LoadingSpinner />
            )
            : (
              <Table
                tableData={tableData}
                hasStatusColors
                orderBy={orderBy}
                orderProducts={orderProducts}
                onOrderProducts={handleRequestSort}
              />
            )}
        </div>
      </div>
    </Paper>
  );
}

OrderRequests.propTypes = {
  dispatch: PropTypes.func.isRequired,
  orders: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  autocompleteOptions: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

function mapStateToProps({ orderReducer, autocompleteReducer }) {
  return {
    orders: orderReducer.orders,
    autocompleteOptions: autocompleteReducer.autocompleteOptions,
  };
}

export default connect(mapStateToProps)(OrderRequests);
