import apiClient from '../../domain/fetchers/apiClient';

function loadError(error) {
  console.log('ERROR', error);
}

function loadValidatedSyncStatus(syncStatus) {
  return {
    data: syncStatus,
  };
}

export async function  getSyncStatus() {
    let endpoint = 'sync-status';
    try {
      const response = await apiClient.get(endpoint);
      if (response.status === 200 || response.status === 204) return response;
    } catch (error) {
      return {error};
    }
}