import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { Collapse, IconButton, makeStyles } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { Close } from '@material-ui/icons';
import Global from '../../Contexts/Global';

const useStyles = makeStyles({
  collapse: {
    margin: '12px',
    zIndex: 10000,
  },
  alert: { },
});

function PushNotification({
  open: opend,
  severity = 'error',
  message,
  handleClose,
  anchor
}) {
  const { i18n } = useContext(Global);
  const [open, setOpen] = useState(opend);
  const classes = useStyles();

  useEffect(() => {
    setOpen(opend);
    if (opend) {
      setTimeout(() => {
        handleClose();
      }, 5000);
    }
  }, [opend]);

  return (
    <Collapse
      id="alert-notify"
      in={open}
      className={classes.collapse}
      style={
        anchor && {
          position: 'fixed',
          top: '-5px',
          right: '10px',
          width: '100vw',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '50px',
        }
      }
    >
      <Alert
        severity={severity}
        className={classes.alert}
        action={
          handleClose && (
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                setOpen(false);
                handleClose();
              }}
            >
              <Close fontSize="inherit" />
            </IconButton>
          )
        }
      >
        {i18n.t(message)}
      </Alert>
    </Collapse>
  );
}

PushNotification.propTypes = {
  open: PropTypes.bool,
  severity: PropTypes.string,
  message: PropTypes.string,
  handleClose: PropTypes.func,
  anchor: PropTypes.shape({
    top: PropTypes.string,
    bottom: PropTypes.string,
    left: PropTypes.string,
    right: PropTypes.string,

  }),
  timeout: PropTypes.number,

};

PushNotification.defaultProps = {
  open: false,
  severity: 'error',
  message: '',
  handleClose: () => {},
  anchor: { top: '154px', right: '50%' },
  timeout: 2000,
};

export default PushNotification;
