/* eslint-disable max-len */
/* eslint-disable react/prop-types */
import React, { useContext } from 'react';
import { connect } from 'react-redux';
import { useLocation, useHistory } from 'react-router-dom';
import {
  AppBar,
  Toolbar,
  IconButton,
  Typography,
} from '@material-ui/core';
import { Menu as MenuIcon } from '@material-ui/icons';
import HomeIcon from '@material-ui/icons/Home';
import BasicButton from '../BasicButton';
import Global from '../../Contexts/Global';
import { getExcel } from '../../redux/actions/documentActions';
import useStyles from './style';

function capitalizeString(string) {
  const firstChar = string[0].toUpperCase();
  const newString = firstChar + string.slice(1);
  return newString;
}

const NavigationBar = ({
  dispatch, menuActive, handleToggleDrawer, order, validatedOrderDetail, actualCustomer, filters
}) => {
  const classes = useStyles();
  const { i18n } = useContext(Global);
  const location = useLocation();
  const drawerWidth = '180px';
  const downloadExcelTabs = ['order-requests', 'previsions', 'orders-in-progress', 'bills'];
  const statusTabs = ['order-in-progress'];
  const newOrderPath = 'order-requests';
  const requestNumber = location.pathname.split('/')[location.pathname.split('/').length - 1];
  const history = useHistory();

  function turnToLiteral(string) {
    let literalPath = string.replaceAll('-', '_');
    if (i18n.t(literalPath.toUpperCase()) !== literalPath.toUpperCase()) {
      literalPath = i18n.t(literalPath.toUpperCase()).toLowerCase();
    }
    return capitalizeString(literalPath);
  }

  function setBreadcrumbs(path) {
    const dividedPath = path.split('/');
    let newPath = '';

    dividedPath.forEach((subPath) => {

      if (subPath.length) {
        if (!Number.isNaN(+subPath)) {
          if (requestNumber === newOrderPath) {
            newPath += ` / ${turnToLiteral(subPath)}`;
          } else if (["customer-locations", "customer-contracts"].includes(dividedPath[1])) {
            newPath += ` / ${actualCustomer || ''}`;
          } else if (validatedOrderDetail || order) {
            newPath += ` / ${(validatedOrderDetail.external_reference || order.external_reference) || ''}`;
          }
        } else {
          newPath += ` / ${turnToLiteral(subPath)}`;
        }
      }
    });

    return newPath;
  }

  return (
    <AppBar position="fixed" key="bar" style={{ backgroundColor: 'rgb(46,15,227)' }}>
      <Toolbar>
        <IconButton
          onClick={handleToggleDrawer}
          edge="start"
          color="inherit"
          aria-label="menu"
          className={classes.menuButton}
        >
          <MenuIcon />
        </IconButton>
        <div className={classes.root}>
          <div className={classes.startHeader} style={{ marginLeft: menuActive && drawerWidth }}>
            <div style={{ display: 'flex', color: '#fff' }}>
              <HomeIcon color="inherit" style={{ marginRight: '3px' }} />
              <Typography>{setBreadcrumbs(location.pathname)}</Typography>
            </div>
          </div>
          {downloadExcelTabs.includes(location.pathname.split('/')[1]) && (
            <div className={classes.endHeader}>
              <BasicButton
                  title={i18n.t('DOWNLOAD_EXCEL')}
                  variant="outlined" contrast
                  onClickFunction={() => dispatch(getExcel({ route: location.pathname.split('/')[1], filters }))} />
            </div>
          )}
          {location.pathname.split('/')[1] === 'admin-users' && (
          <div className={classes.endHeader}>
            <BasicButton
              title={i18n.t('NEW_USER')}
              variant="outlined"
              onClickFunction={() => history.push('/user')}
              contrast
            />
          </div>
          )}
          {(statusTabs.includes(location.pathname.split('/')[1]) && (order?.status?.name || validatedOrderDetail?.status?.name)) && (
          <div className={classes.endHeader}>
            <div style={{ padding: '1.2vh 7vw', backgroundColor: (order?.status?.color || validatedOrderDetail?.status?.color), color: 'black', fontWeight: 600 }}>
              <span>
                {i18n.t((order?.status?.name || validatedOrderDetail?.status?.name)?.toUpperCase())}
              </span>
            </div>
          </div>
          )}
        </div>
      </Toolbar>
    </AppBar>
  );
};

function mapStateToProps({ requestDetailReducer, orderReducer, userReducer, filtersReducer }) {
  return {
    order: requestDetailReducer.requestDetailData,
    validatedOrderDetail: orderReducer.validatedOrderDetail,
    actualCustomer: userReducer.actualCustomer,
    filters: filtersReducer.filters
  };
}

export default connect(mapStateToProps)(NavigationBar);
