/* eslint-disable max-len */
import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Paper } from '@material-ui/core';
import Form from '../../Components/Form';
import { emptyAutocompleteOptions, getAutocompleteOptions } from '../../redux/actions/autocompleteActions';
import Table from '../../Components/Table';
import Global from '../../Contexts/Global';
import { emptyContracts, getContracts } from '../../redux/actions/contractActions';
import LoadingSpinner from '../../Components/LoadingSpinner';
import useStyles from './style';
import moment from "moment";

const initialTableData = {
  titles: [
    { align: 'left', title: 'DATE', sortColumn: 'contracts.created_at'},
    { align: 'left', title: 'TYPE', sortColumn: 'contract_types.name' },
    { align: 'left', title: 'NAME', sortColumn: 'contracts.name'},
  ],
  rows: [],
};

const initialParams = {
  order: 'desc',
  orderBy: 'contracts.created_at',
};

function getColumnName(column) {
  const columns = {
    date: 'created_at',
    model: 'car_model',
    charge_to: 'charge_to_id',
    registration_and_frame: 'car_plate_number',
    origin: 'origin_province',
    destiny: 'destination_province',
  };
  const columnName = columns[column.toLowerCase()]?.toLowerCase() ?? column;
  return columnName;
}

function Contratcs({ dispatch, contracts }) {
  const { i18n } = useContext(Global);
  const classes = useStyles();
  const [tableData, setTableData] = useState(initialTableData);
  const [filterValues, setFilterValues] = useState({
    type: '',
  });
  const [params, setParams] = useState(initialParams);
  const [orderBy, setOrderBy] = useState('');
  const [orderProducts, setOrderProducts] = useState('asc');
  const [isLoading, setIsLoading] = useState(false);
  const autocompleteIds = ['models', 'accessories', 'cities'];
  const date = new Date();
  let month = date.getMonth();
  if (month < 10) month = `0${month}`;
  let day = date.getDate();
  if (day < 10) day = `0${day}`;

  function searchContracts() {
    if (Object.values(params).length) {
      dispatch(getContracts({ params, setIsLoading }));
    } else {
      dispatch(getContracts({ setIsLoading }));
    }
  }

  async function handleRequestSort(column) {
    const isAsc = orderBy === column && orderProducts === 'asc';
    const isDesc = orderBy === column && orderProducts === 'desc';
    const newParams = { ...params, order: isAsc ? 'desc' : 'asc', orderBy: getColumnName(column) };
    if (isDesc) {
      setOrderBy(false);
    } else {
      setOrderBy(column);
    }
    setOrderProducts(isAsc ? 'desc' : 'asc');
    setParams(newParams);
    dispatch(getContracts({ params: newParams, setIsLoading }));
  }

  const form = {
    inputs: [
      {
        label: i18n.t('DATE'),
        placeholder: '13572468',
        textFieldProps: {
          value: filterValues.date,
          fullWidth: true,
          type: 'date',
          onChange: (event) => {
            setFilterValues({ ...filterValues, date: event.target.value });
            setParams({ ...params, date: event.target.value });
          },
        },
        InputLabelProps: { shrink: true },
        gridProps: { xs: 12, sm: 4, md: 4, lg: 3 },
      },
      {
        label: i18n.t('TYPE'),
        placeholder: '13572468',
        textFieldProps: {
          value: filterValues.type,
          fullWidth: true,
          onChange: (event) => {
            setFilterValues({ ...filterValues, type: event.target.value });
            setParams({ ...params, type: event.target.value });
          },
          onKeyPress: (e) => { if(e.key === 'Enter'){ e.preventDefault(); searchContracts(); } },
        },
        gridProps: { xs: 12, sm: 4, md: 4, lg: 3 },
      },
    ],
    buttons: [
      { label: i18n.t('SEARCH'), gridProps: { xs: 12, sm: 4, md: 4, lg: 6 }, buttonProps: { variant: 'contained' }, onClick: () => searchContracts() },
    ],
    grid: { spacing: 3 },
  };

  useEffect(() => {
    dispatch(getContracts({ params, setIsLoading }));
    autocompleteIds.forEach((id) => {
      dispatch(getAutocompleteOptions(id));
    });
  }, []);

  useEffect(() => {
    if (contracts) {
      setTableData({
        ...tableData,
        rows: Object.values(contracts).map((contract) => ({
          date: moment(contract.creation_date).format('DD/MM/yyyy') ?? '',
          type: contract.type?.name ?? 'No disponible',
          name: {
            title: contract.name || '',
            document: true,
            putExtension: false,
            id: contract.document?.id || null,
          },
        })),
      });
    }
  }, [contracts]);

  useEffect(() => () => {
    dispatch(emptyContracts());
    dispatch(emptyAutocompleteOptions());
  }, []);

  return (
    <Paper>
      <div className={classes.mainContainer}>
        <Form newForm={form} />
        <div className={classes.tableContainer}>
          {isLoading
            ? (
              <LoadingSpinner />
            )
            : (
              <Table
                tableData={tableData}
                hasStatusColors
                orderBy={orderBy}
                orderProducts={orderProducts}
                onOrderProducts={handleRequestSort}
              />
            )}

        </div>
      </div>
    </Paper>
  );
}

Contratcs.propTypes = {
  dispatch: PropTypes.func.isRequired,
  requestDetailData: PropTypes.shape({
    model_id: PropTypes.shape({
      name: PropTypes.string,
    }),
    frame_number: PropTypes.string,
    accessory_id: PropTypes.shape({
      name: PropTypes.string,
    }),
    origin_city_id: PropTypes.shape({
      name: PropTypes.string,
    }),
    destination_city_id: PropTypes.shape({
      name: PropTypes.string,
    }),
    amount: PropTypes.string,
    plate_number: PropTypes.string,
    observations: PropTypes.string,
  }).isRequired,
  autocompleteOptions: PropTypes.shape({
    models: PropTypes.arrayOf(PropTypes.shape({})),
    accessories: PropTypes.arrayOf(PropTypes.shape({})),
    cities: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
  contracts: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

function mapStateToProps({ autocompleteReducer, requestDetailReducer, contractReducer }) {
  return {
    autocompleteOptions: autocompleteReducer.autocompleteOptions,
    requestDetailData: requestDetailReducer.requestDetailData,
    contracts: contractReducer.contracts,
  };
}

export default connect(mapStateToProps)(Contratcs);
