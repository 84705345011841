import { combineReducers } from 'redux';
import orderReducer from './orderReducer';
import userReducer from './userReducer';
import newRequestReducer from './newRequestReducer';
import autocompleteReducer from './autocompleteReducer';
import alertReducer from './alertReducer';
import requestDetailReducer from './requestDetailReducer';
import previsionReducer from './previsionReducer';
import billReducer from './billReducer';
import contractReducer from './contractReducer';
import documentReducer from './documentReducer';
import filtersReducer from './filtersReducer';

const rootReducer = combineReducers({
  orderReducer,
  userReducer,
  newRequestReducer,
  autocompleteReducer,
  alertReducer,
  requestDetailReducer,
  previsionReducer,
  billReducer,
  contractReducer,
  documentReducer,
  filtersReducer
});

export default rootReducer;
