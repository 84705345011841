import actionTypes from '../actions/actionTypes';

export default function filtersReducer(state = {}, { type, data }) {
    let newState;

    switch (type) {
        case actionTypes.SAVE_FILTERS:
            newState = {
                ...state,
                filters: data,
            };
            break;
        default:
            newState = state;
            break;
    }

    return newState;
}
