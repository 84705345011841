import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    padding: theme.spacing(3),
  },
  tableContainer: {
    marginTop: '30px',
  },
}));
export default useStyles;
