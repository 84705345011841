import React from 'react';
import { ThemeProvider } from '@material-ui/core';
import { Provider } from 'react-redux';
import Global from './Contexts/Global';
import Routes from './routes';
import configureStore from './redux/configureStore';
import theme from './DefaultStyles/styles';
import i18nFactory from './Literals';
import './App.css';

const i18n = i18nFactory({ lang: 'es-ES' });

const store = configureStore({
  orderReducer: { 
    validatedOrderDetail: {}, 
    orderOrigin: {}, 
    orderDestiny: {},  
  },
  userReducer: {},
  newRequestReducer: { newRequestData: { cars: [{}] } },
  autocompleteReducer: { autocompleteOptions: {} },
  alertReducer: { alerts: [] },
  requestDetailReducer: { requestDetailData: {} },
  previsionReducer: { previsions: [] },
  billReducer: { bills: [] },
  contractReducer: { contracts: [] },
  documentReducer: { document: {} },
});

function App() {
  return (
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <Global.Provider value={{ i18n }}>
          <Routes />
        </Global.Provider>
      </ThemeProvider>
    </Provider>
  );
}

export default App;
