import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Clear } from '@material-ui/icons';
import { InputAdornment, TextField } from '@material-ui/core';
import useStyles from './style';

export default function TextFieldComponent({ item }) {
  const classes = useStyles();
  const [isFocus, setIsFocus] = useState(false);
  const [isHover, setIsHover] = useState(false);

  const clearIcon = (
    <InputAdornment
      style={{
        cursor: (isFocus && item.textFieldProps?.value) && 'pointer',
        padding: (item.textFieldProps.type === 'date') ? '10px 1px' : '10px 0px',
        paddingRight: '0',
        borderRadius: '50%',
        backgroundColor: (isHover && isFocus && item.textFieldProps?.value) ? 'rgb(240,240,240)' : 'white',
      }}
      position="end"
      onMouseEnter={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
      onClick={() => (isFocus && item.textFieldProps?.value && item.textFieldProps?.onChange) && item.textFieldProps.onChange({ target: { value: '' } })}
    >
      <Clear
        style={{
          color: (isFocus && item.textFieldProps?.value) ? 'gray' : 'white',
          width: '0.8em',
          height: 'auto',
        }}
      />
    </InputAdornment>
  );

  return (
    <TextField
      {...item.textFieldProps}
      InputProps={{
        readOnly: item.readOnly,
        endAdornment: (!item.readOnly && item.textFieldProps?.onChange) && clearIcon,
      }}
      InputLabelProps={item.InputLabelProps}
      select={false}
      margin="dense"
      label={item.label}
      placeholder={item.placeholder}
      className={classes.textField}
      name={item.name}
      variant="outlined"
      onMouseEnter={() => setIsFocus(true)}
      onMouseLeave={() => setIsFocus(false)}
    />
  );
}

TextFieldComponent.propTypes = {
  item: PropTypes.shape({
    readOnly: PropTypes.bool,
    label: PropTypes.string,
    placeholder: PropTypes.string,
    name: PropTypes.string,
    textFieldProps: PropTypes.shape({
      onChange: PropTypes.func,
      value: PropTypes.string,
      type: PropTypes.string,
    }),
    InputLabelProps: PropTypes.shape({}),
  }).isRequired,
};
