/* eslint-disable react/prop-types */
/* eslint-disable max-len */
import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Paper } from '@material-ui/core';
import Table from '../../Components/Table';
import Global from '../../Contexts/Global';
import Form from '../../Components/Form';
import { emptyBills, getBills } from '../../redux/actions/billActions';
import { emptyAutocompleteOptions, getAutocompleteOptions } from '../../redux/actions/autocompleteActions';
import LoadingSpinner from '../../Components/LoadingSpinner';
import useStyles from './style';
import {setFiltersAction} from "../../redux/actions/filterActions";

const initialTableData = {
  titles: [
    { align: 'center', title: 'BILL_DATE' },
    { align: 'center', title: 'BILL_NUMBER' },
    { align: 'center', title: 'IMPORT' },
    { align: 'center', title: 'IVA' },
    { align: 'center', title: 'TOTAL' },
    //    { align: 'left', title: 'END_DATE' },
    { align: 'left', title: 'STATUS', sortColumn: 'invoice_status' },
    //    { align: 'left', title: 'PAY_DATE' },
    { align: 'center', title: '' },
  ],
  rows: [],
};

const initialParams = {
  order: 'desc',
  orderBy: 'bill_date',
};

function getColumnName(column) {
  const columns = {
    import: 'amount',
  };
  const columnName = columns[column.toLowerCase()] ?? column;
  return columnName;
}

function getFormattedImport(actualImport) {
  const formattedImport = actualImport + '';
  const [unities, decimals] = formattedImport.split('.');
  let formattedUnities = '';

  [...unities].forEach((unity, index) => {
    if (index === unities.length - 4 && unity[index] !== '-') {
      formattedUnities += `${unity}.`;
    } else {
      formattedUnities += unity;
    }
  });

  return `${formattedUnities},${decimals} €`;
}

function Bills({ dispatch, bills }) {
  const classes = useStyles();
  const { i18n } = useContext(Global);
  const [tableData, setTableData] = useState(initialTableData);
  const [filterValues, setFilterValues] = useState({
    bill_number: '',
  });
  const [params, setParams] = useState(initialParams);
  const [orderBy, setOrderBy] = useState('');
  const [orderProducts, setOrderProducts] = useState('asc');
  const [isLoading, setIsLoading] = useState(false);
  const autocompleteIds = ['bill-statuses'];
  const date = new Date();
  const year = [date.getFullYear()];
  const yearsCount = 21;
  let month = date.getMonth();
  if (month < 10) month = `0${month}`;
  let day = date.getDate();
  if (day < 10) day = `0${day}`;

  const months = [
    { title: i18n.t('JANUARY'), id: 1 },
    { title: i18n.t('FEBRUARY'), id: 2 },
    { title: i18n.t('MARCH'), id: 3 },
    { title: i18n.t('APRIL'), id: 4 },
    { title: i18n.t('MAY'), id: 5 },
    { title: i18n.t('JUNE'), id: 6 },
    { title: i18n.t('JULY'), id: 7 },
    { title: i18n.t('AUGUST'), id: 8 },
    { title: i18n.t('SEPTEMBER'), id: 9 },
    { title: i18n.t('OCTOBER'), id: 10 },
    { title: i18n.t('NOVEMBER'), id: 11 },
    { title: i18n.t('DECEMBER'), id: 12 },
  ];

  useEffect(() => dispatch(setFiltersAction(params)),[params])

  const years = [];
  year.forEach((actualYear) => {
    for (let index = 0; index < yearsCount; index += 1) {
      years.push({ title: `${actualYear - index}`, id: actualYear - index });
    }
  });

  function searchBills() {
    if (Object.values(params).length) {
      dispatch(getBills({ params, setIsLoading }));
    } else {
      dispatch(getBills({ params, setIsLoading }));
    }
  }

  async function handleRequestSort(column) {
    const isAsc = orderBy === column && orderProducts === 'asc';
    const isDesc = orderBy === column && orderProducts === 'desc';
    let newParams = { ...params };
    if (isDesc) {
      setOrderBy(false);
    } else {
      newParams = { ...params, order: isAsc ? 'desc' : 'asc', orderBy: getColumnName(column) };
      setOrderBy(column);
    }
    setOrderProducts(isAsc ? 'desc' : 'asc');
    setParams(newParams);
    dispatch(getBills({ params: newParams, setIsLoading }));
  }

  const newForm = {
    inputs: [
      {
        label: i18n.t('MONTH'),
        placeholder: 'Enero',
        textFieldProps: { fullWidth: true },
        autoCompleteProps: {
          options: months,
        },
        onChange: (event, value) => setParams({ ...params, month: value?.id }),
        gridProps: { xs: 12, sm: 6, md: 6, lg: 3 },
      },
      {
        label: i18n.t('YEAR'),
        placeholder: '2021',
        textFieldProps: { fullWidth: true },
        autoCompleteProps: {
          options: years,
        },
        onChange: (event, value) => setParams({ ...params, year: value?.id }),
        gridProps: { xs: 12, sm: 6, md: 6, lg: 3 },
      },
      {
        label: i18n.t('BILL_NUMBER'),
        placeholder: '13572468',
        textFieldProps: {
          value: filterValues.bill_number,
          fullWidth: true,
          onChange: (event) => {
            setFilterValues({ ...filterValues, bill_number: event.target.value });
            setParams({ ...params, billNumber: event.target.value });
          },
          onKeyPress: (e) => { if(e.key === 'Enter'){ e.preventDefault(); searchBills();} },
        },
        gridProps: { xs: 12, sm: 6, md: 6, lg: 3 },
      },
    ],
    buttons: [
      { label: i18n.t('SEARCH'), gridProps: { xs: 12, sm: 6, md: 6, lg: 3 }, buttonProps: { variant: 'contained' }, onClick: () => searchBills() },
    ],
    grid: { spacing: 3 },
  };

  useEffect(() => {
    if (bills) {
      setTableData({
        ...tableData,
        rows: Object.values(bills).map((bill) => ({
          billDate: bill.bill_date?.slice(0, 10).split('-').reverse().join('-') || '',
          billNumber: bill.bill_number || '',
          import: bill.amount ? { title: getFormattedImport(bill.amount), alignCell: 'center' } : '',
          iva: { title: getFormattedImport(bill.iva), alignCell: 'center' } || '',
          total: { title: getFormattedImport(bill.total), alignCell: 'center' } || '',
          // endDate: bill.end_date || '',
           status: bill.invoice_status || '',
          // payDate: bill.pay_date || '',
          document: {
            title: i18n.t('SEE_DETAIL'),
            putExtension: true,
            document: !!bill.document_id,
            id: `${bill.document_id}`,
            nameDoc: bill.bill_number
          },
        })),
      });
    }
  }, [bills]);

  useEffect(() => {
    dispatch(getBills({ params, setIsLoading }));
    autocompleteIds.forEach((id) => {
      dispatch(getAutocompleteOptions(id));
    });
  }, []);

  useEffect(() => () => {
    dispatch(emptyBills());
    dispatch(emptyAutocompleteOptions());
  }, []);

  return (
    <Paper>
      <div className={classes.mainContainer}>
        <Form newForm={newForm} />
        <div style={{ marginTop: '20px' }}>
          {isLoading
            ? (
              <LoadingSpinner />
            )
            : (
              <Table
                tableData={tableData}
                hasStatusColors
                orderBy={orderBy}
                orderProducts={orderProducts}
                onOrderProducts={handleRequestSort}
              />
            )}
        </div>
      </div>
    </Paper>
  );
}

Bills.propTypes = {
  dispatch: PropTypes.func.isRequired,
  bills: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

function mapStateToProps({ billReducer }) {
  return {
    bills: billReducer.bills,
  };
}

export default connect(mapStateToProps)(Bills);
