import actionTypes from '../actions/actionTypes';

export default function previsionReducer(state = {}, { type, data }) {
  let newState = null;

  switch (type) {
    case actionTypes.SET_PREVISIONS:
      newState = {
        ...state,
        previsions: data,
      };
      break;
    case actionTypes.EMPTY_PREVISIONS:
      newState = {
        ...state,
        previsions: [],
      };
      break;
    default:
      newState = state;
      break;
  }

  return newState;
}
