/* eslint-disable react/prop-types */
/* eslint-disable max-len */
import React, { useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Paper } from '@material-ui/core';
import Global from '../../Contexts/Global';
import Form from '../../Components/Form';
import Table from '../../Components/Table';
import { emptyRequestDetail } from '../../redux/actions/requestDetailActions';
import { getValidatedOrderDetail, getOrderOrigin, getOrderDestiny } from '../../redux/actions/orderActions';
import LoadingSpinner from '../../Components/LoadingSpinner';
import useStyles from './style';

const tableDataDocuments = {
  titles: [
    { align: 'left', title: 'DATE' },
    // { align: 'left', title: 'TYPE' },
    { align: 'left', title: 'NAME' },
  ],
  rows: [],
};

const tableDataTracking = {
  titles: [
    { align: 'left', title: 'EVENT' },
    { align: 'left', title: 'EXPECTED_DATE' },
    { align: 'left', title: 'REAL_DATE' },
  ],
  rows: [
    { traking: 'Fecha pedido', expectedDate: '', realDate: '' },
    { traking: 'Recogida', expectedDate: '', realDate: '' },
    { traking: 'Entrega', expectedDate: '', realDate: '' },
    { traking: 'Fecha tope de entrega', expectedDate: '', realDate: '' },
  ],
};

function OrderDetail({ dispatch, autocompleteOptions, validatedOrderDetail, orderOrigin, orderDestiny }) {
  const { i18n } = useContext(Global);
  const classes = useStyles();
  const location = useLocation();
  const [initialForm, setInitialForm] = useState({});
  const [carsForm, setCarsForm] = useState({});
  const [finalForm, setFinalForm] = useState({});
  const [originLocationsForm, setOriginLocationsForm] = useState();
  const [destinyLocationsForm, setDestinyLocationsForm] = useState();
  const [documentsTable, setDocumentsTable] = useState(tableDataDocuments);
  const [trackingTable, setTrackingTable] = useState(tableDataTracking);
  const [orderBy, setOrderBy] = useState('');
  const [orderProducts, setOrderProducts] = useState('asc');
  const [isLoading, setIsLoading] = useState(false);
  const requestNumber = location.pathname.split('/')[location.pathname.split('/').length - 1];

  const date = new Date();
  let month = date.getMonth();
  if (month < 10) month = `0${month}`;
  let day = date.getDate();
  if (day < 10) day = `0${day}`;

  async function handleRequestSort(column) {
    const isAsc = orderBy === column && orderProducts === 'asc';
    const isDesc = orderBy === column && orderProducts === 'desc';
    if (isDesc) {
      setOrderBy(false);
    } else {
      setOrderBy(column);
    }
    setOrderProducts(isAsc ? 'desc' : 'asc');
  }

  useEffect(() => {
    dispatch(getValidatedOrderDetail({ requestNumber, setIsLoading }));
    dispatch(getOrderOrigin({ requestNumber, setIsLoading })); 
    dispatch(getOrderDestiny({ requestNumber, setIsLoading }));
  }, []);
  
  useEffect(() => {
    // console.log({ validatedOrderDetail });
    // console.log({ orderOrigin });
    // console.log({ orderDestiny });
    if (Object.keys(validatedOrderDetail).length) {
      setInitialForm({
        inputs: [
          {
            label: i18n.t('ORDER_DATE'),
            placeholder: '01/01/2020',
            textFieldProps: {
              fullWidth: true,
              InputLabelProps: { shrink: true },
              value: validatedOrderDetail.tracking_order_date?.slice(0, 10).split('-').reverse().join('-'),
            },
            gridProps: { xs: 12, sm: 6, md: 6, lg: 3 },
            readOnly: true,
          },
          {
            label: i18n.t('REQUESTED_BY'),
            placeholder: 'user',
            textFieldProps: { fullWidth: true, value: validatedOrderDetail.user_id?.name },
            gridProps: { xs: 12, sm: 6, md: 6, lg: 3 },
            readOnly: true,
          },
          {
            label: i18n.t('CHARGE_TO'),
            placeholder: i18n.t('BARCELONA'),
            textFieldProps: {
              fullWidth: true, value: validatedOrderDetail.charge_to?.name },
            gridProps: { xs: 12, sm: 6, md: 6, lg: 3 },
            readOnly: true,
          },
          {
            label: i18n.t('EXTERNAL_REFERENCE'),
            placeholder: '135679',
            textFieldProps: {
              fullWidth: true,
              value: validatedOrderDetail.external_reference,
            },
            gridProps: { xs: 12, sm: 6, md: 6, lg: 3 },
            readOnly: true,
          },
        ],
        grid: { spacing: 3 },
      });
      if (Object.keys(orderOrigin).length) {
        setOriginLocationsForm({
          inputs: [
            {
              label: i18n.t('ORIGIN'),
              placeholder: i18n.t('BARCELONA'),
              textFieldProps: {
                fullWidth: true,
                value: orderOrigin.name ,
              },
              gridProps: { xs: 12, sm: 12, md: 12, lg: 12 },
              readOnly: true,
            },
            {
              label: i18n.t('ORIGIN_DIRECTION'),
              placeholder: '4490CBN',
              textFieldProps: {
                fullWidth: true,
                value: orderOrigin.address,
              },
              gridProps: { xs: 12, sm: 6, md: 6, lg: 6 },
              readOnly: true,
            },
            {
              label: i18n.t('ORIGIN_POSTAL_CODE'),
              placeholder: '4490CBN',
              textFieldProps: {
                fullWidth: true,
                value: orderOrigin.zipcode,
              },
              gridProps: { xs: 12, sm: 6, md: 6, lg: 6 },
              readOnly: true,
            },
            {
              label: i18n.t('ORIGIN_LOCATION'),
              placeholder: '4490CBN',
              textFieldProps: {
                fullWidth: true,
                value: orderOrigin.location,
              },
              gridProps: { xs: 12, sm: 6, md: 6, lg: 6 },
              readOnly: true,
            },
            {
              label: i18n.t('ORIGIN_PROVINCE'),
              placeholder: '4490CBN',
              textFieldProps: {
                fullWidth: true,
                value:  validatedOrderDetail.origin_province
                      || validatedOrderDetail.origin?.province?.name
                      || validatedOrderDetail.location_origin_province,
              },
              gridProps: { xs: 12, sm: 6, md: 6, lg: 6 },
              readOnly: true,
            },
            {
              label: i18n.t('ORIGIN_CONTACT_PERSON'),
              placeholder: '4490CBN',
              textFieldProps: {
                fullWidth: true,
                value: orderOrigin.contact_name,
              },
              gridProps: { xs: 12, sm: 6, md: 6, lg: 6 },
              readOnly: true,
            },
            {
              label: i18n.t('ORIGIN_CONTACT_PHONE'),
              placeholder: '4490CBN',
              textFieldProps: {
                fullWidth: true,
                value: orderOrigin.contact_phone,
              },
              gridProps: { xs: 12, sm: 6, md: 6, lg: 6 },
              readOnly: true,
            },
          ],
          grid: { spacing: 3 },
        });
      }
      if (Object.keys(orderDestiny).length) {
        setDestinyLocationsForm({
          inputs: [
            {
              label: i18n.t('DESTINY'),
              textFieldProps: {
                fullWidth: true,
                InputLabelProps: { shrink: true },
                value: orderDestiny.name,
              },
              placeholder: i18n.t('BILBAO'),
              gridProps: { xs: 12, sm: 12, md: 12, lg: 12 },
              readOnly: true,
            },
            {
              label: i18n.t('DESTINY_DIRECTION'),
              placeholder: '4490CBN',
              textFieldProps: {
                fullWidth: true,
                value: orderDestiny.address,
              },
              gridProps: { xs: 12, sm: 6, md: 6, lg: 6 },
              readOnly: true,
            },
            {
              label: i18n.t('DESTINY_POSTAL_CODE'),
              placeholder: '4490CBN',
              textFieldProps: {
                fullWidth: true,
                value: orderDestiny.zipcode,
              },
              gridProps: { xs: 12, sm: 6, md: 6, lg: 6 },
              readOnly: true,
            },
            {
              label: i18n.t('DESTINY_LOCATION'),
              placeholder: '4490CBN',
              textFieldProps: {
                fullWidth: true,
                value: orderDestiny.location,
              },
              gridProps: { xs: 12, sm: 6, md: 6, lg: 6 },
              readOnly: true,
            },
            {
              label: i18n.t('DESTINY_PROVINCE'),
              placeholder: '4490CBN',
              textFieldProps: {
                fullWidth: true,
                value: validatedOrderDetail.destination_province
                      || validatedOrderDetail.destination?.province?.name
                      || validatedOrderDetail.location_destination_province,
              },
              gridProps: { xs: 12, sm: 6, md: 6, lg: 6 },
              readOnly: true,
            },
            {
              label: i18n.t('DESTINY_CONTACT_PERSON'),
              placeholder: '4490CBN',
              textFieldProps: {
                fullWidth: true,
                value: orderDestiny.contact_name,
              },
              gridProps: { xs: 12, sm: 6, md: 6, lg: 6 },
              readOnly: true,
            },
            {
              label: i18n.t('DESTINY_CONTACT_PHONE'),
              placeholder: '4490CBN',
              textFieldProps: {
                fullWidth: true,
                value: orderDestiny.contact_phone,
              },
              gridProps: { xs: 12, sm: 6, md: 6, lg: 6 },
              readOnly: true,
            },
          ],
          grid: { spacing: 3 },
        });
      }
      setCarsForm({
        inputs: validatedOrderDetail.cars.map((car) => ([
          {
            label: i18n.t('MODEL'),
            placeholder: 'Turismo',
            textFieldProps: {
              fullWidth: true,
              value: car.model,
            },
            gridProps: { xs: 12, sm: 6, md: 3, lg: 3 },
            readOnly: true,
          },
          {
            label: i18n.t('REGISTRATION_AND_FRAME'),
            placeholder: '4490CBN',
            textFieldProps: {
              fullWidth: true,
              value: car.plate_number,
            },
            gridProps: { xs: 12, sm: 6, md: 3, lg: 3 },
            readOnly: true,
          },
          {
            label: i18n.t('ACCESSORIES'),
            placeholder: i18n.t('CAGE'),
            defaultValue: car.accessories?.length ? car.accessories : [{ name: 'No' }],
            textFieldProps: {
              id: 'accessory_id',
              fullWidth: true,
            },
            multiselectProps: { options: car.accessories?.length ? car.accessories : [{ name: 'No' }] },
            gridProps: { xs: 12, sm: 6, md: 3, lg: 3 },
            readOnly: true,
          },
          {
            label: i18n.t('STARTS'),
            placeholder: i18n.t('YES'),
            textFieldProps: {
              fullWidth: true,
              value: i18n.t(car.start?.toUpperCase()),
            },
            gridProps: { xs: 12, sm: 6, md: 3, lg: 3 },
            readOnly: true,
          },
        ])).flat(),
        grid: { spacing: 3 },
      });
      setFinalForm({
        inputs: [
          {
            label: i18n.t('OBSERVATIONS'),
            textFieldProps: {
              fullWidth: true,
              InputLabelProps: { shrink: true },
              multiline: true,
              value: validatedOrderDetail.observations,
            },
            placeholder: i18n.t('OBSERVATIONS'),
            gridProps: { xs: 12, sm: 12, md: 6, lg: 6 },
            readOnly: true,
          },
          {
            label: i18n.t('EXPEDIENT_NUMBER'),
            placeholder: '21-00041/D',
            textFieldProps: {
              fullWidth: true,
              value: validatedOrderDetail.file || '',
            },
            gridProps: { xs: 12, sm: 6, md: 3, lg: 3 },
            readOnly: true,
          },
          {
            label: i18n.t('IMPORT'),
            placeholder: '0.00 €',
            textFieldProps: {
              fullWidth: true,
              value: validatedOrderDetail.amount,
            },
            gridProps: { xs: 12, sm: 6, md: 3, lg: 3 },
            readOnly: true,
          },
        ],
        grid: { spacing: 3 },
      });
      setDocumentsTable({
        ...tableDataDocuments,
        rows: validatedOrderDetail.documents?.map((document) => ({
          date: document?.date.slice(0, 10).split('-').reverse().join('-') || '',
          // type: document?.type?.name || '',
          name: {
            title: document?.name || '',
            document: true,
            id: document?.id,
          },
        })) || [{ date: '', type: '', name: '' }],
      });
      setTrackingTable({
        ...trackingTable,
        rows: [
          {
            traking: 'Fecha pedido',
            expectedDate: '-',
            realDate: validatedOrderDetail.tracking_order_date?.slice(0, 10).split('-').reverse().join('-') ?? '',
          },
          {
            traking: 'Recogida desde',
            expectedDate: validatedOrderDetail.tracking_pickup_expected?.slice(0, 10).split('-').reverse().join('-') ?? '',
            realDate: validatedOrderDetail.tracking_pickup_real?.slice(0, 10).split('-').reverse().join('-') ?? '',
          },
          {
            traking: 'Entrega hasta',
            expectedDate: validatedOrderDetail.tracking_delivery_expected?.slice(0, 10).split('-').reverse().join('-') ?? '',
            realDate: [3,4,5].includes(validatedOrderDetail.status?.id)
                ? validatedOrderDetail.tracking_delivery_real?.slice(0, 10).split('-').reverse().join('-') ?? ''
                : '',
          },
          {
            traking: 'Fecha tope de entrega',
            expectedDate: '-',
            realDate: validatedOrderDetail.tracking_delivery_real_max?.slice(0, 10).split('-').reverse().join('-') ?? '',
          },
        ],
      });
    }
  }, [validatedOrderDetail, autocompleteOptions, orderOrigin, orderDestiny]);

  useEffect(() => () => {
    dispatch(emptyRequestDetail());
  }, []);

  return (
    <Paper>
      <div className={classes.mainContainer}>
        {isLoading 
          ? (
            <LoadingSpinner />
          )
          : (
            <>
              <div className={classes.initialForm}>
                <Form newForm={initialForm} />
              </div>
              <div className={classes.locationsBlock}>
                <div className={classes.locationSection}>
                  <Form newForm={originLocationsForm} isNew />
                </div>
                <div className={classes.locationSection}>
                  <Form newForm={destinyLocationsForm} isNew />
                </div>
              </div>
              <div style={{ marginTop: '10px' }}>
                <Form newForm={carsForm} />
              </div>
              <div style={{ marginTop: '10px' }}>
                <Form newForm={finalForm} />
              </div>
              <div style={{ marginTop: '30px' }}>
                <h4>{i18n.t('DOCUMENTS')}</h4>
                <Table
                  tableData={documentsTable}
                  hasStatusColors
                  orderBy={orderBy}
                  orderProducts={orderProducts}
                  onOrderProducts={handleRequestSort}
                />
              </div>
              <div style={{ marginTop: '20px' }}>
                <h4>Tracking</h4>
                <Table
                  tableData={trackingTable}
                  hasStatusColors
                  orderBy={orderBy}
                  orderProducts={orderProducts}
                  onOrderProducts={handleRequestSort}
                />
              </div>
            </>
          )}

      </div>
    </Paper>
  );
}

OrderDetail.propTypes = {
  dispatch: PropTypes.func.isRequired,
  validatedOrderDetail: PropTypes.shape({
    model_id: PropTypes.shape({
      name: PropTypes.string,
    }),
    frame_number: PropTypes.string,
    accessory_id: PropTypes.shape({
      name: PropTypes.string,
    }),
    origin_city_id: PropTypes.shape({
      name: PropTypes.string,
    }),
    destination_city_id: PropTypes.shape({
      name: PropTypes.string,
    }),
    amount: PropTypes.string,
    plate_number: PropTypes.string,
    observations: PropTypes.string,
  }).isRequired,
  autocompleteOptions: PropTypes.shape({
    models: PropTypes.arrayOf(PropTypes.shape({})),
    accessories: PropTypes.arrayOf(PropTypes.shape({})),
    cities: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
};

function mapStateToProps({ autocompleteReducer, orderReducer }) {
  return {
    orderOrigin: orderReducer.orderOrigin,
    orderDestiny: orderReducer.orderDestiny,
    autocompleteOptions: autocompleteReducer.autocompleteOptions,
    validatedOrderDetail: orderReducer.validatedOrderDetail,
  };
}

export default connect(mapStateToProps)(OrderDetail);
