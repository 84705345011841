/* eslint-disable max-len */
import React, {useContext, useEffect, useState} from 'react';
import { Paper } from '@material-ui/core';
import Table from '../../Components/Table';
import Global from '../../Contexts/Global';
import Form from '../../Components/Form';
import LoadingSpinner from '../../Components/LoadingSpinner';
import {
    getCustomerLocations,
    deleteCustomerLocation,
    addLocation, 
} from "../../redux/actions/customerActions";
import useStyles from './style'
import {connect} from "react-redux";
import {useLocation} from "react-router-dom";
import {getAutocompleteOptions} from "../../redux/actions/autocompleteActions";

const initialTableData = {
    titles: [
        { align: 'center', title: 'LOCALIZACION', sortColumn: 'description' },
        { align: 'center', title: 'DIRECCIÓN', sortColumn: 'address' },
        { align: 'center', title: 'POBLACIÓN', sortColumn: 'province_name' },
        { align: 'center', title: 'CODIGO POSTAL', sortColumn: 'zipcode' },
        { align: 'center', title: '' },
    ],
    rows: [],
};
const emptyLocations = []; 

function CustomerLocations({ autocompleteOptions, dispatch}) {
    const location = useLocation();
    const { i18n } = useContext(Global);
    const classes = useStyles();
    const [tableData, setTableData] = useState(initialTableData);
    const [isLoading, setIsLoading] = useState(false);
    const autocompleteIds = ['locations/all'];
    const [locationSelected, setLocationSelected] = useState(emptyLocations);
    const [orderProducts, setOrderProducts] = useState('asc');
    const [orderBy, setOrderBy] = useState('');
    const [params, setParams] = useState({});

    const customerId = location.pathname.split('/')[location.pathname.split('/').length - 1];

    async function handleRequestSort(column) {
        const isAsc = orderBy === column && orderProducts === 'asc';
        const newParams = { ...params, order: isAsc ? 'desc' : 'asc', orderBy: column };
            setOrderBy(column);
        setOrderProducts(isAsc ? 'desc' : 'asc');
        setParams(newParams);
        handleGetCustomerLocations(newParams)
    }

    function setAutocompleteOptions(id) {
        let newOptions = [];
        if (autocompleteOptions && autocompleteOptions[id]) {
            newOptions = autocompleteOptions[id].map((option) => ({
                ...option,
                title: option.name + ( (' - ' + option.address) || ' ') + ( ( ' - ' + option.province) || ' '),
            }));
        }
        return newOptions;
    }
    
    const resetLocations = () => {
        setLocationSelected(emptyLocations);
        
    }

    const handleGetCustomerLocations = (params) => {
        setIsLoading(true)
        getCustomerLocations({
            params: {
                customer: customerId,
                    ...params
            },
            onSuccess: (locations) => {
                setIsLoading(false)
                resetLocations();
                setTableData({
                    ...tableData,
                    rows: locations.map((location) => ({
                        location: location?.name,
                        address: location?.address,
                        region: location?.province ?? '',
                        zipcode: location?.zipcode,
                        link: {
                            title: 'Eliminar',
                            reference: 'Detail',
                            link: true,
                            row: location,
                            onClick: (row) => {
                                const locationId = row.id
                                deleteCustomerLocation({
                                    params: {
                                         customer_id: parseInt(customerId),
                                         location_id: parseInt(locationId)
                                    },
                                    onSuccess: () => {
                                    //    handleGetCustomerLocations()
                                        setTableData(tableData => {
                                            const rows = tableData.rows.filter(t => t.link.row.id !== locationId);
                                            return {
                                                ...tableData,
                                                rows
                                            }
                                        })
                                    }
                                 })
                            },
                            label: 'detail',
                        },

                    }))
                });
            },
            onFailure:(error) => {}
        })
    }

    useEffect(() => {
        autocompleteIds.forEach((id) => {
            dispatch(getAutocompleteOptions(id));
        });
        if (!customerId) {
            return
        }

        handleGetCustomerLocations()
    }, [])


    function add() {
        if (locationSelected.length == 0){
            return
        }
        setIsLoading(true)
        locationSelected.forEach((location) => {
            addLocation({
                params: {
                    customerId: parseInt(customerId),
                    locationId: parseInt(location?.id)
                },
                onSuccess: () => {
                    resetLocations();
                    handleGetCustomerLocations();
                }
            })
        });
    }

    const newForm = {
        inputs: [
            {
                label: i18n.t('LOCATION'),
                placehoder: 'Localizaciones',
                defaultValue: locationSelected,
                textFieldProps: {
                    id: 'location',
                    fullwidth: 'true', 
                },
                onChange: (event, value) => {
                    if(typeof event.target.value !== 'undefined') {
                        const ids = []; 
                        const locs = value.filter((loc) => {
                            const exists = ids.includes(loc.id);
                            if(!exists){
                                ids.push(loc.id); 
                                return true; 
                            } else {
                                return false;
                            }
                        });
                        if(locationSelected.length !== locs.length) {
                            setLocationSelected(locs);
                        }
                    } else {
                        resetLocations();
                    }
                },
                multiselectProps: {
                    options: setAutocompleteOptions('locations/all'),
                },
                gridProps: { xs: 12, sm: 6, md: 10, lg: 10 },
            },
        ],
        buttons: [
            { label: 'Añadir', gridProps: { xs: 12, sm: 6, md: 2, lg: 2 }, buttonProps: { variant: 'contained' }, onClick: () => add() },
        ],
        grid: { spacing: 3 },
    };
        
    return (
        <Paper>
            <div className={classes.mainContainer}>
                    {Boolean(tableData.titles?.length)}
                    {isLoading
                        ? (
                            <LoadingSpinner />
                        )
                        : (
                        <div>
                    <Form newForm={newForm} />
                    <div style={{ marginTop: '20px' }}>
                            <Table
                                key={'1'}
                                tableData={tableData}
                                hasStatusColors
                                orderBy={orderBy}
                                orderProducts={orderProducts}
                                onOrderProducts={handleRequestSort}
                            />
                    </div>
                    </div>
                        )}
            </div>
        </Paper>
    );
}


CustomerLocations.propTypes = {};

function mapStateToProps({ autocompleteReducer }) {
    return {
        autocompleteOptions: autocompleteReducer.autocompleteOptions,
    };
}

export default connect(mapStateToProps)(CustomerLocations);
