import { createTheme } from '@material-ui/core';

const theme = createTheme({
  palette: {
    primary: {
      main: 'rgb(6,67,224)',
      contrastText: '#fff',
      light: '#dde8f0',
    },
  },
  overrides: {
    MuiAutocomplete: {
      root: {
        marginRight: '16px',
      },
    },
    MuiListItemIcon: {
      root: {
        minWidth: '36px',
      },
    },
    MuiTabs: {
      root: {
        borderBottom: '.7px solid #D3DAE3',
      },
    },
    MuiListItem: {
      gutters: {
        paddingLeft: '8px',
        paddingRight: '8px',
      },
      root: {
        borderRadius: '5px',
        // backgroundColor: "#2e1f46",
        margin: '3px 0',
        '&$selected': {
          backgroundColor: 'rgb(46, 15, 227)',
          color: '#fff',
          '&:hover': {
            color: '#fff',
            backgroundColor: '#0f6be3',
          },
        },
      },
      button: {
        '&:hover': {
          backgroundColor: '#e1e1ec',
        },
      },
    },
    MuiDrawer: {
      paper: {
        top: 0,
        bottom: 0,
      },
    },
    MuiList: {
      root: {
        margin: '0 8px',
      },
    },
  },
  props: {
    MuiPaper: {
      elevation: 10,
    },
  },
});

export default theme;
