import actionTypes from '../actions/actionTypes';

export default function documentReducer(state = {}, { type, data }) {
  let newState = null;

  switch (type) {
    case actionTypes.SET_DOCUMENT:
      newState = {
        ...state,
        document: data,
      };
      break;
    case actionTypes.EMPTY_DOCUMENT:
      newState = {
        ...state,
        document: {},
      };
      break;
    default:
      newState = state;
      break;
  }

  return newState;
}
