import apiClient from "../../domain/fetchers/apiClient";
import {getEndpointParams} from "../../utility";


export const getCustomers = async ({ params, onSuccess = () => { }, onFailure = () => { }}) => {
    const endpoint = getEndpointParams('customers', params);

    try {
        const {data} = await apiClient.get(endpoint);
        onSuccess(data.data)
    } catch (error) {
        onFailure(error)
    }
}

export const getCustomerLocations = async ({params, onSuccess = () => {}, onFailure = () => {}}) => {
    const endpoint = getEndpointParams(`customers/${params.customer}/locations`, params);
    try {
        const {data} = await apiClient.get(endpoint);
        onSuccess(data.data)
    } catch (error) {
        onFailure(error)
    }
}

export const setCustomerEmail = async ({params, onSuccess = () => {}}) => {
    const endpoint = getEndpointParams(`customers/${params.customer}`);
    try {
        const {data} = await apiClient.get(endpoint); 
        onSuccess(data.data.email);
    } catch (error){
        
    }
}

export const getCustomerContracts = async ({ params, onSuccess = () => {}, onFailure = () => {}}) => {
    const endpoint = getEndpointParams('contracts/all', params);

    try {
        const {data} = await apiClient.get(endpoint);
        onSuccess(data.data)
    } catch (error) {
        onFailure(error)
    }
}

export const uploadContracts = async ({params, onSuccess = () => {}, onFailure = () => {}}) => {
    const endpoint =  'contracts';

    try {
        const {data} = await apiClient.post(endpoint, params);
        onSuccess(data.data)
    } catch (error) {
        onFailure(error)
    }
}

export const updateCustomerEmail = async ({params, onSuccess = () => {}}) =>{
    const endpoint = 'customers/email';
    if (!params.customer_id || !params.email){
        onSuccess(false);
    
        
    } else {
        try {
            const response = await apiClient.put(endpoint , {
                email: params.email,
                customer_id: params.customer_id,
            });
            
            onSuccess(true); 
        
        } catch (error) {
            return;
        }
    }
}

export const deleteContract = async ({id, onSuccess = () => {}, onFailure = () => {}}) => {
    if (!id) {
        return
    }
    const endpoint = 'contracts/' + id;

    try {
        const {data} = await apiClient.delete(endpoint);
        onSuccess(data.data)
    } catch (error) {
        onFailure(error)
    }
}
export const deleteCustomerLocation = async ({params, onSuccess = () => {}, onFailure = () => {}}) => {
    const endpoint = 'customer/locations';

    try {
        const {data} = await apiClient.delete(endpoint, { params: params});
        onSuccess(data.data)
    } catch (error) {
        onFailure(error)
    }
}

export const addLocation = async ({params, onSuccess = () => {}, onFailure = () => {}}) => {
    const {
        locationId
    } = params
    const endpoint = 'customers/' + params.customerId +'/locations';

    try {
        const {data} = await apiClient.put(endpoint, {
            location_id: locationId
        });
        onSuccess(data.data)
    } catch (error) {
        onFailure(error)
    }
}

export const emptyLocations = async({onSuccess = () => {}}) => {
    onSuccess(true); 
}