import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    padding: theme.spacing(3),
  },
  checboxArea: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  checboxContainer: {
    width: '99%',
    margin: '50px 0',
    display: 'flex',
    justifyContent: 'space-between',
  },
  checboxBox: {
    width: '12%',
    padding: '10px 20px',
    border: '1px solid gray',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '5px',
    // backgroundColor: '#dde8f0',
    transition: 'transform .2s',
    '&:hover': {
      '-ms-transform': 'scale(1.05)', /* IE 9 */
      '-webkit-transform': 'scale(1.05)', /* Safari 3-8 */
      transform: 'scale(1.05)',
    },
  },
}));
export default useStyles;
