import axios from 'axios';
import { REACT_APP_API_ROOT } from '../../settings/config'

const apiClient = axios.create({
  baseURL: REACT_APP_API_ROOT,
  withCredentials: true,
  timeout: 1000*60*2,
  headers: {
    'Content-type': 'application/json',
    'Accept': 'application/json',
  },
});

apiClient.interceptors.response.use(
  (response) => {
    if (!response?.data?.data?.success && response?.data?.data?.code === 401) {
      localStorage.clear();
      window.location.href = '/login';
    }
    return response;
  },
  (error) => {
    if (error.response?.status === 401) {
      localStorage.clear();
      window.location.href = '/login';
    }
    return error;
  },
);

// axios.interceptors.request.use(
//   (config) => {
//     const newConfig = config;

//     newConfig.headers = {
//       'Content-type': 'application/json',
//       Accept: 'application/json',
//       'Access-Control-Allow-Origin': 'localhost:3000',
//       Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
//     };

//     return newConfig;
//   }, (error) => Promise.reject(error),

// );

export default apiClient;
