/* eslint-disable */
import React from 'react';
import { Route, Switch, Redirect, BrowserRouter } from 'react-router-dom';
import AppRoute from './AppRoute';
import Layout from './Layout';
import OrderRequest from './Pages/OrderRequests';
import NewOrderRequest from './Pages/NewOrderRequest';
import Login from './Pages/Login';
import RequestDetail from './Pages/RequestDetail';
import OrdersInProgress from './Pages/OrdersInProgress';
import OrderDetail from './Pages/OrderDetail';
import AdminUsers from './Pages/AdminUsers';
import UserDetail from './Pages/UserDetail';
import Previsions from './Pages/Previsions';
import Bills from './Pages/Bills';
import Contracts from './Pages/Contracts';
import NewUser from './Pages/NewUser'
import AdminCustomers from "./Pages/AdminCustomers";
import CustomerLocations from "./Pages/CustomerLocations";
import CustomerDocuments from "./Pages/CustomerDocuments";
import CustomerEmail from     "./Pages/CustomerEmail";

const Routes = () => (
  <BrowserRouter>
    <Switch>
      <Route path="/login" component={Login} />
      <AppRoute path="/order-requests" component={OrderRequest} layout={Layout} />
      <AppRoute path="/order-request/:requestNumber" component={RequestDetail} layout={Layout} />
      <AppRoute path="/new-order-request" component={NewOrderRequest} layout={Layout} />
      <AppRoute path="/orders-in-progress" component={OrdersInProgress} layout={Layout} />
      <AppRoute path="/order-in-progress/:orderNumber" component={OrderDetail} layout={Layout} />
      <AppRoute path="/admin-users" component={AdminUsers} layout={Layout} />
      <AppRoute path="/admin-customers" component={AdminCustomers} layout={Layout} />
      <AppRoute path="/customer-contracts/:customerId" component={CustomerDocuments} layout={Layout} />
      <AppRoute path="/customer-locations/:locationId" component={CustomerLocations} layout={Layout} />
      <AppRoute path="/customer-email/:customerId" component={CustomerEmail} layout={Layout} />
      <AppRoute path="/user/:userNumber" component={UserDetail} layout={Layout} />
      <AppRoute path="/user" component={NewUser} layout={Layout} />
      <AppRoute path="/previsions" component={Previsions} layout={Layout} />
      <AppRoute path="/bills" component={Bills} layout={Layout} />
      <AppRoute path="/contracts" component={Contracts} layout={Layout} />
      <Route exact path="/">
        <Redirect to="login" />
      </Route>
    </Switch>
  </BrowserRouter>
);

export default Routes;
