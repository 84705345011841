import { CircularProgress } from '@material-ui/core';
import React from 'react';
import useStyles from './style';

export default function LoadingSpinner() {
  const classes = useStyles();

  return (
    <div className={classes.spinnerContainer}>
      <CircularProgress color="primary" size="5%" />
    </div>
  );
}
