import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexWrap: 'wrap',
    width: '100%',
    backgroundColor: 'lightbrown',
  },
  outerLabel: {
    display: 'flex',
    alignItems: 'center',
    marginRight: theme.spacing(1),
  },
  button: {
    position: 'absolute',
    top: 0,
    right: 0,
    margin: theme.spacing(1),
    zIndex: 100,
  },
  buttonLink: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    color: 'blue',
    textDecoration: 'underline',
    marginLeft: '8px',
  },
  simpleText: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginLeft: '8px',
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: '100%',
  },
  dateField: {
    width: '100%',
  },
  textFieldLink: {
    cursor: 'pointer',
    color: 'blue',
  },
  buttonField: {
    marginTop: theme.spacing(1),
  },
  buttonContainer: {
    display: 'block',
    marginLeft: 'auto',
    marginRight: 0,
  },
  [theme.breakpoints.only('xs')]: {
    filterInput: {
      minWidth: '100%',
    },
  },
  [theme.breakpoints.only('sm')]: {
    filterInput: {
      minWidth: '45%',
    },
  },
  [theme.breakpoints.only('md')]: {
    filterInput: {
      minWidth: '32%',
    },
  },
  [theme.breakpoints.up('lg')]: {
    filterInput: {
      minWidth: '15%',
    },
  },
  
}));

export default useStyles;
