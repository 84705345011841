import fileDownload from 'js-file-download';
import apiClient from '../../domain/fetchers/apiClient';
import actionTypes from './actionTypes';
import { loadError } from './newRequestActions';
import {getEndpointParams} from "../../utility";

export function setDocument({ document }) {
  return {
    type: actionTypes.SET_DOCUMENT,
    data: document,
  };
}


export function getDocument({ id, name, putExtension = false }) {
  return async (dispatch) => {
    const endpoint = `/documents/${id}`;
    const config = { responseType: 'blob' };

    try {
      const { data } = await apiClient.get(endpoint, config);
      const fileName = putExtension ? `${name}.pdf` : name
      fileDownload(data, fileName);
    } catch (error) {
      dispatch(loadError(error));
    }
  };
}

export function getExcel({ route, filters = {} }) {
  const downloadExcelTabs = ['order-requests', 'previsions', 'orders-in-progress', 'bills'];
  let url = null;
  let fileName = null
  const config = { responseType: 'blob' };

  switch (route) {
    case downloadExcelTabs[0]:
      url = '/orders';
      fileName = 'solicitudes'
      break;
    case downloadExcelTabs[1]:
      url = '/previsions';
      fileName = 'previsiones'
      break;
    case downloadExcelTabs[2]:
      url = '/validated-orders';
      fileName = 'pedidos'
      break;
    case downloadExcelTabs[3]:
      url = '/bills';
      fileName = 'facturas'
      break;
    default:
      url = '/orders';
      fileName = 'pedidos'
      break;
  }
  
  if (filters && filters.customer){
    fileName += '-' + filters.customer; 
  } 

  return async (dispatch) => {
    url += '/excel';
    const endpoint = getEndpointParams(url, filters);

    try {
      const { data } = await apiClient.get(endpoint, config);
      
      if(data) fileDownload(data, `${fileName}.xlsx`);
      else fileDownload("", `${fileName}.xlsx`);
    } catch (error) {
      dispatch(loadError(error));
    }
  };
}

