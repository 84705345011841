/* eslint-disable react/prop-types */
/* eslint-disable max-len */
import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Paper } from '@material-ui/core';
import Global from '../../Contexts/Global';
import Form from '../../Components/Form';
import { setRequestData, sendNewOrderRequest, emptyNewRequestData, goToOrders } from '../../redux/actions/newRequestActions';
import { emptyAutocompleteOptions, getAutocompleteOptions } from '../../redux/actions/autocompleteActions';
import {getExternalReference, getUserDetail} from '../../redux/actions/userActions';
import {emptyAlerts, setAlert} from '../../redux/actions/alertActions';
import useStyles from './style';
import {conditionalElement} from "../../utility";

const validationFormIDs = [
  'external_reference',
  'origin_location_id',
  'destination_location_id',
  'origin_address',
  'origin_zipcode',
  'destination_address',
  'destination_zipcode',
  'origin_location',
  'origin_province',
  'destination_location',
  'destination_province',
  'origin_contact',
  'origin_phone',
  'destination_contact',
  'charge_to_id',
  'destination_phone',
];

const validationVehiclesIDs = [
  'model_id',
  'plate_number',
  'accessories',
  'start_id',
];

const alert = {
  message: 'Rellena todos los campos primero.',
  severity: 'error',
};

const alertDuplicatedPlates = {
  message: 'No pueden haber matrículas duplicadas.',
  severity: 'error',
};

const date = new Date();

function NewOrderRequest({ dispatch, newRequestData, autocompleteOptions, userDetail }) {
  const getDefaultCar = () => {
    return {
      id: Math.random()
    }
  }
  
  const getDefaultDoc = () => {
    return {
      id: Math.random()
    }
  }

  const { i18n } = useContext(Global);
  const classes = useStyles();
  const autocompleteIds = ['models', 'accessories', 'cities', 'locations', 'starts', 'secondary-venues', 'provinces'];
  const otherLocationsName = i18n.t('OTHERS');
  const [initialForm, setInitialForm] = useState();
  const [originLocationsForm, setOriginLocationsForm] = useState();
  const [destinyLocationsForm, setDestinyLocationsForm] = useState();
  const [finalForm, setFinalForm] = useState();
  const [observationForm, setObsForm] = useState(); 
  const [cars, setCars] = useState([getDefaultCar()]);
  const [documents, setDocuments] = useState([getDefaultDoc()]); 
  const [errors, setErrors] = useState({ cars: [{}] });
  const [carsKey, setCarsKey] = useState(Math.random())
  const [actualDocument, setActualDocument] = useState([]);
  const [automaticExternalReference, setAutomaticExternalReference] = useState(null)
  const [readOnly] = useState({});
  const user = JSON.parse(localStorage.getItem('user'));
  const year = date.getFullYear();
  const [isSetDefault, setisSetDefault] = useState(false); 
  let month = date.getMonth() + 1;
  if (month < 10) month = `0${month}`;
  let day = date.getDate();
  if (day < 10) day = `0${day}`;
  let shouldSend = true;

  const handleChange = ({ id, value }) => {
    dispatch(setRequestData({ id, value }));
  };

  useEffect(() => {
    if (user?.external_reference_type_id?.name !== 'manual') {
      getExternalReference({
          userId: user?.id,
          onSuccess: (data) => {
            handleChange({ id: 'external_reference', value: data });
            setAutomaticExternalReference(data)
          },
          onError: () => {}
      })
    }
  }, [])

  function setAutocompleteOptions(id) {
    let isModel = id == 'models';
    let newOptions = [];
    if (autocompleteOptions && autocompleteOptions[id]) {
      newOptions = autocompleteOptions[id].map((option) => ({
        ...option,
        title: option.name,
      }));
    }
    if(isModel){
      let sortOptions = newOptions => [...newOptions].sort((a,b) => (parseInt(a.name) > 0 ? parseInt(a.name) : a.name) > (parseInt(b.name) > 0 ? parseInt(b.name) : b.name) ? 1 : -1 );
      for(let i=0; i<7; i++) newOptions = sortOptions(newOptions);
    }
    return newOptions;
  }

  const deleteVehicule = id => {
    const newCars = cars.filter(car => car.id !== id)
    setCars(newCars)
    handleChange({ id: 'cars', value: newCars });
  }
  
  function handleDeleteDoc (id) {
    const newDocs = actualDocument.filter(doc => doc.index != id);
    let index = 0; 
    newDocs.forEach((doc) => {
      doc.index = index;
      index= index + 1; 
    });
    setActualDocument(newDocs); 
  }
  
  const deleteDoc = id => {
    let count = 0;
    let found = -1; 
    documents.forEach((doc) =>  {
      if (doc.id === id) found = count; 
      else count += 1; 
    }); 
    
    const newDocs = documents.filter(doc => doc.id !== id);
    setDocuments(newDocs);
    
    handleDeleteDoc(found);  
  }

  function addVehicle() {
    const newCars = [...cars, getDefaultCar()];
    const newErrors = { ...errors, cars: [...errors.cars, {}] };
    setCars(newCars);
    setErrors(newErrors);
  }
  
  function addDocument() {
    const newDocs = [...documents, getDefaultDoc()]; 
    setDocuments(newDocs); 
  }

  function handleChangeOrigin(value) {
    handleChange({ id: 'origin_description', value: value?.name});
    handleChange({ id: 'origin_address', value: value?.address || '' });
    handleChange({ id: 'origin_zipcode', value: value?.zipcode || '' });
    handleChange({ id: 'origin_location', value: value?.location || '' });
    handleChange({ id: 'origin_province', value: value?.province || '' });
    handleChange({ id: 'origin_contact', value: value?.contact_name || '' });
    handleChange({ id: 'origin_phone', value: value?.contact_phone || '' });
    handleChange({ id: 'origin_location_id', value });
    handleChange({ id: 'origin_start_desc', value: value?.origin_start_desc || ''});

    if (value?.address) {
      readOnly.origin_address = true;
    } else {
      readOnly.origin_address = false;
    } if (value?.zipcode) {
      readOnly.origin_zipcode = true;
    } else {
      readOnly.origin_zipcode = false;
    } if (value?.location && value?.location !== 'Otros') {
      readOnly.origin_location = true;
    } else {
      readOnly.origin_location = false;
    } if (value?.province) {
      readOnly.origin_province = true;
    } else {
      readOnly.origin_province = false;
    } if (value?.contact_name) {
      readOnly.origin_contact = true;
    } else {
      readOnly.origin_contact = false;
    } if (value?.contact_phone) {
      readOnly.origin_phone = true;
    } else {
      readOnly.origin_phone = false;
    }
  }

  function handleChangeDestiny(value) {
    handleChange({ id: 'origin_description', value: value?.name});
    handleChange({ id: 'destination_address', value: value?.address || '' });
    handleChange({ id: 'destination_zipcode', value: value?.zipcode || '' });
    handleChange({ id: 'destination_location', value: value?.location || '' });
    handleChange({ id: 'destination_province', value: value?.province || '' });
    handleChange({ id: 'destination_contact', value: value?.contact_name || '' });
    handleChange({ id: 'destination_phone', value: value?.contact_phone || '' });
    handleChange({ id: 'destination_location_id', value });
    handleChange({ id: 'destination_start_desc', value: value?.destination_start_desc || ''});

    if (value?.address) {
      readOnly.destination_address = true;
    } else {
      readOnly.destination_address = false;
    } if (value?.zipcode) {
      readOnly.destination_zipcode = true;
    } else {
      readOnly.destination_zipcode = false;
    } if (value?.location && value?.location !== 'Otros') {
      readOnly.destination_location = true;
    } else {
      readOnly.destination_location = false;
    } if (value?.province) {
      readOnly.destination_province = true;
    } else {
      readOnly.destination_province = false;
    } if (value?.contact_name) {
      readOnly.destination_contact = true;
    } else {
      readOnly.destination_contact = false;
    } if (value?.contact_phone) {
      readOnly.destination_phone = true;
    } else {
      readOnly.destination_phone = false;
    }
  }

  function handleUserPreferences(userObject) {
    handleChange({ id: 'origin_description', value: userObject?.preferred_origin_id?.name});
    handleChange({ id: 'origin_address', value: userObject?.preferred_origin_id?.address });
    handleChange({ id: 'origin_zipcode', value: userObject?.preferred_origin_id?.zipcode });
    handleChange({ id: 'origin_location', value: userObject?.preferred_origin_id?.location });
    handleChange({ id: 'origin_province', value: userObject?.origin_province?.name });
    handleChange({ id: 'origin_contact', value: userObject?.preferred_origin_id?.contact_name });
    handleChange({ id: 'origin_phone', value: userObject?.preferred_origin_id?.contact_phone });
    handleChange({ id: 'origin_location_id', value: userObject?.preferred_origin_id });

    handleChange({ id: 'destination_description', value: userObject?.preferred_destination_id?.name});
    handleChange({ id: 'destination_address', value: userObject?.preferred_destination_id?.address });
    handleChange({ id: 'destination_zipcode', value: userObject?.preferred_destination_id?.zipcode });
    handleChange({ id: 'destination_location', value: userObject?.preferred_destination_id?.location });
    handleChange({ id: 'destination_province', value: userObject?.destination_province?.name });
    handleChange({ id: 'destination_contact', value: userObject?.preferred_destination_id?.contact_name });
    handleChange({ id: 'destination_phone', value: userObject?.preferred_destination_id?.contact_phone });
    handleChange({ id: 'destination_location_id', value: userObject?.preferred_destination_id });

   // if (userObject?.external_reference_type_id?.name !== 'manual') {
   //   handleChange({ id: 'external_reference', value: `${userObject?.username}-${uniqueID}` });
   // }

    if (userObject?.preferred_origin_id?.address) {
      readOnly.origin_address = true;
    } if (userObject?.preferred_origin_id?.zipcode) {
      readOnly.origin_zipcode = true;
    } if (userObject?.preferred_origin_id?.name) {
      readOnly.origin_location = true;
    } if (userObject?.origin_province) {
      readOnly.origin_province = true;
    } if (userObject?.preferred_origin_id?.contact_name) {
      readOnly.origin_contact = true;
    } if (userObject?.preferred_origin_id?.contact_phone) {
      readOnly.origin_phone = true;
    } if (userObject?.preferred_destination_id?.address) {
      readOnly.destination_address = true;
    } if (userObject?.preferred_destination_id?.zipcode) {
      readOnly.destination_zipcode = true;
    } if (userObject?.preferred_destination_id?.name) {
      readOnly.destination_location = true;
    } if (userObject?.destination_province) {
      readOnly.destination_province = true;
    } if (userObject?.preferred_destination_id?.contact_name) {
      readOnly.destination_contact = true;
    } if (userObject?.preferred_destination_id?.contact_phone) {
      readOnly.destination_phone = true;
    }
  }

  function oldHandleChangeFiles(fileList) {
    const fileObject = fileList[0];
    
    const fileobjects = []; 
    Object.entries(fileList).map(file => {
        const fObject = file;
        fObject.toJSON = () => ({
          lastModified: fObject.lastModified,
          lastModifiedDate: fObject.lastModifiedDate,
          name: fObject.name,
          size: fObject.size,
          type: fObject.type,
        });
      fileobjects.push(fObject);
    });
    
    setActualDocument(fileobjects);
  }
  
  function handleChangeFiles(fileList, index) {
    const fileObject = fileList[0];

    if (fileObject) {
      fileObject.toJSON = () => ({
        lastModified: fileObject.lastModified,
        lastModifiedDate: fileObject.lastModifiedDate,
        name: fileObject.name,
        size: fileObject.size,
        type: fileObject.type
      });
    }
    fileObject['index'] = index; 
    const newFiles = [...actualDocument, fileObject]; 
    setActualDocument(newFiles);
  }

  const sendNewRequest = () => {
    
    console.log("sendNewRequest");
    
    let allFilled = true;
    let duplicatedPlate = false;
    let carPlates = [];
  
    validationFormIDs.forEach((id) => {
      if (!newRequestData[id]) {
        allFilled = false;
        errors[id] = true;
        //handleChange({ id, value: '' });
        shouldSend = true;
      } else {
        errors[id] = false;
      }
    });

    if(!shouldSend) return;
    shouldSend = false;

    cars.forEach((car, index) => {
      validationVehiclesIDs.forEach((id) => {
        if (errors?.cars && errors.cars[index]) {
          if (!newRequestData.cars?.[index]?.[id]) {
            allFilled = false;
            errors.cars[index][id] = true;
            shouldSend = true;
          } else {
            errors.cars[index][id] = false;
            if(id == 'plate_number'){
              let currentPlate = newRequestData.cars?.[index]?.[id];
              if(carPlates.indexOf(currentPlate?.toLowerCase()) == -1) carPlates.push(currentPlate?.toLowerCase())
              else{
                allFilled = false;
                duplicatedPlate = true;
                errors.cars[index][id] = true;
                shouldSend = true;
              }
            }
          }
        }
      });
    });

    handleChange({ id: 'cars', value: cars });

    if (allFilled) {
      setErrors({ cars: [{}] });
      dispatch(sendNewOrderRequest(newRequestData, actualDocument));
      dispatch(goToOrders());
    } else {
      setCarsKey(Math.random())
      if(!duplicatedPlate) dispatch(setAlert(alert));
      else dispatch(setAlert(alertDuplicatedPlates));
    }
    // handleChange({ id: 'cars', value: cars });
  };

  useEffect(() => {
    return () => {
      dispatch(emptyAlerts())
    }
  }, [])
  
  function setDefaultChargeTo() {
    if(autocompleteOptions && autocompleteOptions['secondary-venues']){
      if(autocompleteOptions['secondary-venues'].length === 1 ){
        let options = autocompleteOptions['secondary-venues'];
        let id = 'charge_to_id';
        let value = { ...options[0], title: options[0].name}
        if (value && !isSetDefault) { 
          setisSetDefault(true)
          handleChange({ id, value });
        }
        return value;
      }
    }
    return null; 
  }

  useEffect(() => {
    if (newRequestData) {
      console.log({newRequestData});
      setInitialForm({
        inputs: [
          {
            label: i18n.t('ORDER_DATE'),
            placeholder: '123',
            textFieldProps: {
              fullWidth: true,
              InputLabelProps: { shrink: true },
              type: 'date',
              value: `${year}-${month}-${day}`,
              disabled: true,
              id: 'ORDER_DATE',
            },
            gridProps: { xs: 12, sm: 6, md: 6, lg: 3 },
          },
          {
            label: i18n.t('REQUESTED_BY'),
            placeholder: '123',
            textFieldProps: {
              fullWidth: true,
              value: user?.username,
              disabled: true,
              id: 'REQUESTED_BY',
            },
            gridProps: { xs: 12, sm: 6, md: 6, lg: 3 },
          },
          {
            label: i18n.t('CHARGE_TO'),
            placeholder: i18n.t('BARCELONA'),
            textFieldProps: {
              fullWidth: true,
              InputLabelProps: { shrink: true },
              id: 'charge_to_id',
              error: errors.charge_to_id,
            },
            onChange: (event, value) => {
              handleChange({ id: 'charge_to_id', value });
            },
            autoCompleteProps: {
              options: setAutocompleteOptions('secondary-venues'),
              defaultValue: newRequestData?.charge_to_id ? newRequestData.charge_to_id : setDefaultChargeTo(),
            },
            readOnly: readOnly.charge_to_id,
            gridProps: { xs: 12, sm: 6, md: 6, lg: 3},
          },
          {
            label: '',
            placeholder: 'Referencia Externa',
            textFieldProps: user?.external_reference_type_id?.name === 'manual'
              ? {
                fullWidth: true,
                id: 'external_reference',
                error: errors.external_reference,
                onChange: (event) => {
                  handleChange({ id: 'external_reference', value: event.target.value });
                },
                defaultValue: '',
              }
              : {
                fullWidth: true,
                id: 'external_reference',
                error: errors.external_reference,
                  textFieldProps: {
                    readOnly: true,
                    value: automaticExternalReference,
                  },
                value: automaticExternalReference,
              },
            gridProps: { xs: 12, sm: 6, md: 6, lg: 3 },
          },
        ],
        grid: { spacing: 3 },
      });
      
      setOriginLocationsForm({
        inputs: [
          {
            label: i18n.t('ORIGIN'),
            placeholder: i18n.t('BARCELONA'),
            textFieldProps: {
              value: newRequestData?.origin_location_id?.name,
              fullWidth: true,
              id: 'origin_location_id',
              error: errors.origin_description,
            },
            onChange: (event, value) => {
              handleChangeOrigin(value);
            },
            autoCompleteProps: {
              options: [...setAutocompleteOptions('locations'), { title: otherLocationsName, name: otherLocationsName, id: null }],
              defaultValue: { ...newRequestData.origin_location_id, title: newRequestData?.origin_location_id?.name },
              value: { ...newRequestData.origin_location_id, title: newRequestData?.origin_location_id?.name },
            },
            gridProps: { xs: 12, sm: 12, md: 12, lg: 12 },
          },
          
          ...conditionalElement( ['otros', 'others'].includes(newRequestData?.origin_location_id?.name?.toLowerCase()) , {
                  label: i18n.t('ORIGIN_START_DESC'),
                  placeholder: '4490CBN',
                  textFieldProps: {
                    value: newRequestData?.origin_start_desc,
                    fullWidth: true,
                    id: 'origin_start_desc',
                    error: errors.origin_start_desc,
                    onChange: (event) => {
                      handleChange({ id: 'origin_start_desc', value: event.target.value });
                    },
                  },
                  InputLabelProps: {
                    shrink: !!newRequestData?.origin_start_desc,
                  },
                  gridProps: { xs: 12, sm: 12, md: 12, lg: 12 },
                }),
          
          {
            label: i18n.t('ORIGIN_DIRECTION'),
            placeholder: '4490CBN',
            textFieldProps: {
              value: newRequestData?.origin_address,
              fullWidth: true,
              id: 'origin_address',
              error: errors.origin_address,
              onChange: (event) => {
                handleChange({ id: 'origin_address', value: event.target.value });
              },
            },
            readOnly: readOnly.origin_address,
            InputLabelProps: {
              shrink: !!newRequestData?.origin_address,
            },
            gridProps: { xs: 12, sm: 6, md: 6, lg: 6 },
          },
          {
            label: i18n.t('ORIGIN_POSTAL_CODE'),
            placeholder: '4490CBN',
            textFieldProps: {
              value: newRequestData?.origin_zipcode,
              fullWidth: true,
              id: 'origin_zipcode',
              error: errors.origin_zipcode,
              onChange: (event) => {
                handleChange({ id: 'origin_zipcode', value: event.target.value });
              },
            },
            readOnly: readOnly.origin_zipcode,
            InputLabelProps: {
              shrink: !!newRequestData?.origin_zipcode,
            },
            gridProps: { xs: 12, sm: 6, md: 6, lg: 6 },
          },
          {
            label: i18n.t('ORIGIN_LOCATION'),
            placeholder: '4490CBN',
            textFieldProps: {
              value: newRequestData?.origin_location,
              fullWidth: true,
              id: 'origin_location',
              error: errors.origin_location,
              onChange: (event) => {
                handleChange({ id: 'origin_location', value: event.target.value });
              },
            },
            readOnly: readOnly.origin_location,
            InputLabelProps: {
              shrink: !!newRequestData?.origin_location,
            },
            gridProps: { xs: 12, sm: 6, md: 6, lg: 6 },
          },
          {
            label: i18n.t('ORIGIN_PROVINCE'),
            textFieldProps: {
              value: newRequestData?.origin_province instanceof Object ? newRequestData?.origin_province?.name : newRequestData?.origin_province,
              fullWidth: true,
              InputLabelProps: { shrink: true },
              id: 'origin_province',
              error: errors.origin_province,
            },
            placeholder: 'Álava',
            onChange: (event, value) => {
              handleChange({ id: 'origin_province', value });
            },
            autoCompleteProps: {
              options: setAutocompleteOptions('provinces'),
            },
            readOnly: readOnly.origin_province,
            gridProps: { xs: 12, sm: 6, md: 6, lg: 6 },
          },
          {
            label: i18n.t('ORIGIN_CONTACT_PERSON'),
            placeholder: '4490CBN',
            textFieldProps: {
              value: newRequestData?.origin_contact,
              fullWidth: true,
              id: 'origin_contact',
              error: errors.origin_contact,
              onChange: (event) => {
                handleChange({ id: 'origin_contact', value: event.target.value });
              },
            },
            readOnly: readOnly.origin_contact,
            InputLabelProps: {
              shrink: !!newRequestData?.origin_contact,
            },
            gridProps: { xs: 12, sm: 6, md: 6, lg: 6 },
          },
          {
            label: i18n.t('ORIGIN_CONTACT_PHONE'),
            placeholder: '4490CBN',
            textFieldProps: {
              value: newRequestData?.origin_phone,
              fullWidth: true,
              id: 'origin_phone',
              error: errors.origin_phone,
              onChange: (event) => {
                handleChange({ id: 'origin_phone', value: event.target.value });
              },
            },
            readOnly: readOnly.origin_phone,
            InputLabelProps: {
              shrink: !!newRequestData?.origin_phone,
            },
            gridProps: { xs: 12, sm: 6, md: 6, lg: 6 },
          },
        ],
        grid: { spacing: 3 },
      }
      
      );
      setDestinyLocationsForm({
        inputs: [
          {
            label: i18n.t('DESTINY'),
            textFieldProps: {
              value: { ...newRequestData.destination_location_id, title: newRequestData?.destination_location_id?.name },
              fullWidth: true,
              InputLabelProps: { shrink: true },
              id: 'destination_location_id',
              error: errors.destination_description,
            },
            placeholder: i18n.t('MADRID'),
            onChange: (event, value) => {
              handleChangeDestiny(value);
            },
            autoCompleteProps: {
              options: [...setAutocompleteOptions('locations'), { title: otherLocationsName, name: otherLocationsName, id: null }],
              defaultValue: { ...newRequestData.destination_location_id, title: newRequestData?.destination_location_id?.name },
              value: { ...newRequestData.destination_location_id, title: newRequestData?.destination_location_id?.name },
            },
            gridProps: { xs: 12, sm: 12, md: 12, lg: 12 },
          },
          
            ...conditionalElement( ['otros', 'others'].includes(newRequestData?.destination_location_id?.name?.toLowerCase()) , {
                  label: i18n.t('DESTINY_START_DESC'),
                  placeholder: '4490CBN',
                  textFieldProps: {
                    value: newRequestData?.destination_start_desc,
                    fullWidth: true,
                    id: 'destination_start_desc',
                    error: errors.destination_start_desc,
                    onChange: (event) => {
                      handleChange({ id: 'destination_start_desc', value: event.target.value });
                    },
                  },
                  InputLabelProps: {
                    shrink: !!newRequestData?.destination_start_desc,
                  },
                  gridProps: { xs: 12, sm: 12, md: 12, lg: 12 },
                }),
          
          {
            label: i18n.t('DESTINY_DIRECTION'),
            placeholder: '4490CBN',
            textFieldProps: {
              value: newRequestData?.destination_address,
              fullWidth: true,
              id: 'destination_address',
              error: errors.destination_address,
              onChange: (event) => {
                handleChange({ id: 'destination_address', value: event.target.value });
              },
            },
            readOnly: readOnly.destination_address,
            InputLabelProps: {
              shrink: !!newRequestData?.destination_address,
            },
            gridProps: { xs: 12, sm: 6, md: 6, lg: 6 },
          },
          {
            label: i18n.t('DESTINY_POSTAL_CODE'),
            placeholder: '4490CBN',
            textFieldProps: {
              value: newRequestData?.destination_zipcode,
              fullWidth: true,
              id: 'destination_zipcode',
              error: errors.destination_zipcode,
              onChange: (event) => {
                handleChange({ id: 'destination_zipcode', value: event.target.value });
              },
            },
            readOnly: readOnly.destination_zipcode,
            InputLabelProps: {
              shrink: !!newRequestData?.destination_zipcode,
            },
            gridProps: { xs: 12, sm: 6, md: 6, lg: 6 },
          },
          {
            label: i18n.t('DESTINY_LOCATION'),
            placeholder: '4490CBN',
            textFieldProps: {
              value: newRequestData?.destination_location,
              fullWidth: true,
              id: 'destination_location',
              error: errors.destination_location,
              onChange: (event) => {
                handleChange({ id: 'destination_location', value: event.target.value });
              },
            },
            readOnly: readOnly.destination_location,
            InputLabelProps: {
              shrink: !!newRequestData?.destination_location,
            },
            gridProps: { xs: 12, sm: 6, md: 6, lg: 6 },
          },
          {
            label: i18n.t('DESTINY_PROVINCE'),
            textFieldProps: {
              value: newRequestData?.destination_province instanceof Object ? newRequestData?.destination_province?.name : newRequestData?.destination_province,
              fullWidth: true,
              InputLabelProps: { shrink: true },
              id: 'destination_province',
              error: errors.destination_province,
            },
            placeholder: 'Álava',
            onChange: (event, value) => {
              handleChange({ id: 'destination_province', value });
            },
            autoCompleteProps: {
              options: setAutocompleteOptions('provinces'),
            },
            readOnly: readOnly.destination_province,
            gridProps: { xs: 12, sm: 6, md: 6, lg: 6 },
          },
          {
            label: i18n.t('DESTINY_CONTACT_PERSON'),
            placeholder: '4490CBN',
            textFieldProps: {
              value: newRequestData?.destination_contact,
              fullWidth: true,
              id: 'destination_contact',
              error: errors.destination_contact,
              onChange: (event) => {
                handleChange({ id: 'destination_contact', value: event.target.value });
              },
            },
            readOnly: readOnly.destination_contact,
            InputLabelProps: {
              shrink: !!newRequestData?.destination_contact,
            },
            gridProps: { xs: 12, sm: 6, md: 6, lg: 6 },
          },
          {
            label: i18n.t('DESTINY_CONTACT_PHONE'),
            placeholder: '4490CBN',
            textFieldProps: {
              value: newRequestData?.destination_phone,
              fullWidth: true,
              id: 'destination_phone',
              error: errors.destination_phone,
              onChange: (event) => {
                handleChange({ id: 'destination_phone', value: event.target.value });
              },
            },
            readOnly: readOnly.destination_phone,
            InputLabelProps: {
              shrink: !!newRequestData?.destination_phone,
            },
            gridProps: { xs: 12, sm: 6, md: 6, lg: 6 },
          },
        ],
        grid: { spacing: 3 },
      });
      setFinalForm({
        inputs: [
          {
            label: i18n.t('ADD_NEW_VEHICLE'),
            linkButtonProps: {
              onClick: () => addVehicle(),
            },
          },
          {
            label: i18n.t('SAME_ORIGIN_DESTINY_FOR_VEHICLES'),
            textProps: {},
            gridProps: { xs: 12 },
          },
          {
            space: true, gridProps: { xs: 12 },
          },
        ],
        
        grid: { spacing: 3 },
      });
      
      setObsForm({
            inputs: [
              {
            label: i18n.t('ADD_NEW_DOCUMENT'),
            linkButtonProps: {
              onClick: () => addDocument(),
            },
          },
          {
            space: true, gridProps: { xs: 12 },
          },
              {
                label: i18n.t('OBSERVATIONS'),
                textFieldProps: {
                  fullWidth: true,
                  InputLabelProps: { shrink: true },
                  multiline: true,
                  id: 'observations',
                  onChange: (event) => {
                    handleChange({ id: 'observations', value: event.target.value });
                  },
                },
                placeholder: i18n.t('OBSERVATIONS'),
                gridProps: { xs: 12, sm: 12 },
              },
            ],
            buttons: [
              {
                label: i18n.t('SEND'),
                gridProps: { xs: 12 },
                buttonProps: { variant: 'contained' },
                onClick: () => sendNewRequest(),
              },
            ],
            grid: { spacing: 3 },
      });
    }
  }, [newRequestData, autocompleteOptions, actualDocument, cars]);
  

  useEffect(() => {
    autocompleteIds.forEach((id) => {
      dispatch(getAutocompleteOptions(id));
    });
    if (user) {
      dispatch(getUserDetail({ userNumber: user.id }));
    }
  }, []);

  useEffect(() => {
    if (userDetail) {
      handleUserPreferences(userDetail);
    }
  }, [userDetail]);

  useEffect(() => () => {
    dispatch(emptyNewRequestData());
    dispatch(emptyAutocompleteOptions());
  }, []);
 

  return (
    <Paper>
      <div className={classes.mainContainer}>
        <div className={classes.initialForm}>
          <Form newForm={initialForm} isNew />
        </div>
        <div className={classes.locationsBlock}>
          <div className={classes.locationSection}>
            <Form newForm={originLocationsForm} isNew />
          </div>
          <div className={classes.locationSection}>
            <Form newForm={destinyLocationsForm} isNew />
          </div>
        </div>
        <div key={carsKey}>
        {cars.map((car, index) => {
          const vehicleForm = {
            grid: { spacing: 3 },
            inputs: [
              {
                label: i18n.t('MODEL'),
                placeholder: 'Turismo',
                textFieldProps: {
                  id: 'model_id',
                  error: errors?.cars?.[index]?.model_id,
                  fullWidth: true,
                  value: car.model_id,
                },
                onChange: (event, value) => {
                  const newCars = [...cars];
                  newCars[index].model_id = value?.id;
                  newCars[index].model_name = value?.name;
                  setCars(newCars);
                  handleChange({ id: 'cars', value: newCars });
                },
                autoCompleteProps: {
                  options: setAutocompleteOptions('models'),
                  defaultValue: car.model_id ? { id: car.model_id, name: car.start_name, title: car.model_name } : {},
                },
                gridProps: { xs: 12, sm: 6, md: 3, lg: 3 },
              },
              {
                label: i18n.t('REGISTRATION_AND_FRAME'),
                placeholder: '4490CBN',
                textFieldProps: {
                  fullWidth: true,
                  id: 'plate_number',
                  error: errors?.cars?.[index]?.plate_number,
                  value: car.plate_number,
                  onChange: (event, newVal) => {
                    const newCars = [...cars];
                    newCars[index].plate_number = event.target.value.trim();
                    setCars(newCars);
                    handleChange({ id: 'cars', value: newCars });
                  },
                },
                gridProps: { xs: 12, sm: 6, md: 3, lg: 3 },
              },
              {
                label: i18n.t('ACCESSORIES'),
                placeholder: i18n.t('CAGE'),
                defaultValue: car.accessories ? car.accessories : [],
                textFieldProps: {
                  id: 'accessories',
                  error: errors?.cars?.[index]?.accessories,
                  fullWidth: true,
                },
                onChange: (event, value) => {
                  const newCars = [...cars];
                  const ids = []; 
                  const accessories = value.filter((car) => {
                    const exists = ids.includes(car.id);
                    if(!exists){
                      ids.push(car.id); 
                      return true;
                    } else {
                      return false; 
                    }
                  });
                  newCars[index].accessories = accessories;
                  if (newCars[index].accessories.length === 0) {
                    newCars[index].accessories = null
                  }
                  setCars(newCars);
                  handleChange({ id: 'cars', value: cars });
                },
                multiselectProps: {
                  options: setAutocompleteOptions('accessories'),
                },
                gridProps: { xs: 12, sm: 6, md: 3, lg: 3 },
              },
              {
                label: i18n.t('STARTS'),
                placeholder: i18n.t('YES'),
                textFieldProps: {
                  id: 'start_id',
                  error: errors?.cars?.[index]?.start_id,
                  fullWidth: true,
                },
                onChange: (event, value) => {
                  const newCars = [...cars];
                  newCars[index].start_id = value?.id;
                  newCars[index].start_name = value?.name;
                  setCars(newCars);
                  handleChange({ id: 'cars', value: cars });
                },
                autoCompleteProps: {
                  options: setAutocompleteOptions('starts').map((option) => ({ ...option, title: i18n.t(option.title?.toUpperCase()) })),
                  defaultValue: car.start_id ? { id: car.start_id, name: car.start_name, title: i18n.t(car.start_name?.toUpperCase()) } : {},
                },
                gridProps: { xs: 12, sm: 6, md: 3, lg: 2},
              },
              ...conditionalElement(index > 0, {
                  label: 'Eliminar',
                  linkButtonProps: {
                    id: car.id,
                    onClick: object => deleteVehicule(object.id),
                  },
                }),
            ],
            key: index,
          };
          return <Form newForm={vehicleForm} isNew key={`vehicleForm - ${vehicleForm.key}`} />;
        })}
        </div>
        <Form newForm={finalForm} isNew />
        <div>
        { documents.map((doc, index) => {
          const docForm ={
            inputs: [
              {
                label: i18n.t('DOCUMENTATION'),
                placeholder: i18n.t('FILE'),
                value: actualDocument[index]?.name ? actualDocument[index].name : 'Seleccionar Archivo',
                textFieldProps: {
                  fullWidth: true,
                  required: true,
                  InputLabelProps: { shrink: true },
                  type: 'file',
                  inputProps: {
                    multiple: false
                  },
                  id: 'DOCUMENTATION_'+index,
                  onChange: (event) => handleChangeFiles(event.target.files, index),
                  hidden: true,  
                },
                InputLabelProps: {
                  shrink: true,
                },
                gridProps: { xs: 9, sm: 8, md: 5, lg: 4 },
              },
              ...conditionalElement(index > 0, {
                  label: 'Eliminar',
                  linkButtonProps: {
                    id: doc.id,
                    onClick: object => deleteDoc(object.id),
                  },
                }),
            ]
          }
          return <Form newForm={docForm} isNew key={'docForm - ' + index} />
        })}
        </div>
        <Form newForm={observationForm} isNew />
      </div>
    </Paper>
  );
}

NewOrderRequest.propTypes = {
  dispatch: PropTypes.func.isRequired,
  newRequestData: PropTypes.shape({}).isRequired,
  autocompleteOptions: PropTypes.shape({
    models: PropTypes.arrayOf(PropTypes.shape({})),
    accessories: PropTypes.arrayOf(PropTypes.shape({})),
    cities: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
};

function mapStateToProps({ newRequestReducer, autocompleteReducer, userReducer }) {
  return {
    newRequestData: newRequestReducer.newRequestData,
    autocompleteOptions: autocompleteReducer.autocompleteOptions,
    userDetail: userReducer.userDetail,
  };
}

export default connect(mapStateToProps)(NewOrderRequest);
