/* eslint-disable max-len */
import React, {useContext, useEffect, useState} from 'react';
import { Paper } from '@material-ui/core';
import Table from '../../Components/Table';
import Global from '../../Contexts/Global';
import Form from '../../Components/Form';
import LoadingSpinner from '../../Components/LoadingSpinner';
import {
    getCustomerContracts, uploadContracts, deleteContract
} from "../../redux/actions/customerActions";
import useStyles from './style'
import {connect} from "react-redux";
import {useLocation} from "react-router-dom";
import {getAutocompleteOptions} from "../../redux/actions/autocompleteActions";
import moment from 'moment'

const initialTableData = {
    titles: [
        { align: 'left', title: 'DATE', sortColumn: 'created_at' },
        { align: 'left', title: 'TYPE',  sortColumn: 'contract_types.name' },
        { align: 'left', title: 'NAME', sortColumn: 'contracts.name' },
        { align: 'left', title: '' },
    ],
    rows: [],
};

const initialParams = {
    order: 'desc',
    orderBy: 'contracts.created_at',
};

function CustomerDocuments({ autocompleteOptions, dispatch}) {
    const location = useLocation();
    const { i18n } = useContext(Global);
    const classes = useStyles();
    const [tableData, setTableData] = useState(initialTableData);
    const [isLoading, setIsLoading] = useState(false);
    const autocompleteIds = ['contract-types'];
    const [documentSeleted, setDocumentSelected] = useState(null);
    const [documentTypeIdSelected, setDocumentTypeIdSelected] = useState(null);
    const [denormalizedType, setDenormalizedType] = useState(null);
    const customerId = location.pathname.split('/')[location.pathname.split('/').length - 1];
    const [params, setParams] = useState(initialParams);
    const [orderBy, setOrderBy] = useState('');
    const [orderProducts, setOrderProducts] = useState('asc');


    async function handleRequestSort(column) {
        const isAsc = orderBy === column && orderProducts === 'asc';
        const isDesc = orderBy === column && orderProducts === 'desc';
        let newParams = { ...params };
        if (isDesc) {
            newParams = { ...initialParams };
            setOrderBy(false);
        } else {
            newParams = { ...params, order: isAsc ? 'desc' : 'asc', orderBy: column };
            setOrderBy(column);
        }
        setOrderProducts(isAsc ? 'desc' : 'asc');
        setParams(newParams);
        handleGetCustomerContracts(newParams)
    }


    function setAutocompleteOptions(id) {
        let newOptions = [];
        if (autocompleteOptions && autocompleteOptions[id]) {
            newOptions = autocompleteOptions[id].map((option) => ({
                ...option,
                title: option.name,
            }));
        }
        return newOptions;
    }

    const handleGetCustomerContracts = (params) => {
        setIsLoading(true)
        getCustomerContracts({
            params: {
                customer: customerId,
                ...params
            },
            onSuccess: (contracts) => {
                setIsLoading(false)
                setTableData({
                    ...tableData,
                    rows: contracts.map((contract) => ({
                        date: moment(contract.creation_date).format('DD/MM/yyyy') ?? '',
                        type: contract.type?.name,
                        name: {
                            title: contract.name || '',
                            document: true,
                            putExtension: false,
                            id: contract.document?.id || null,
                        },
                        link: {
                            title: 'Eliminar',
                            reference: 'Detail',
                            link: true,
                            row: contract,
                            onClick: (row) => {
                                deleteContract({
                                    id: parseInt(row.id),
                                    onSuccess: () => {
                                        //    handleGetCustomerLocations()
                                        setTableData(tableData => {
                                            const rows = tableData.rows.filter(t => t.link.row.id !== row.id);
                                            return {
                                                ...tableData,
                                                rows
                                            }
                                        })
                                    }
                                }).then(() =>{})
                            },
                            label: 'detail',
                        },
                    })),
                });
            },
            onFailure: (error) => {
            }
         }).then(r => {})
    }

    useEffect(() => {
        autocompleteIds.forEach((id) => {
            dispatch(getAutocompleteOptions(id));
        });
        if (!customerId) {
            return
        }

        handleGetCustomerContracts()
    }, [])

    const handleSetSelectedFile = (fileList) => {
        const fileObject = fileList[0];

        if (!fileObject) {
            setDocumentSelected(null)
            return
        }

        setDocumentSelected(fileObject);
    }

    const upload = () => {
        if (!documentSeleted || (!documentTypeIdSelected && !denormalizedType) || !customerId) {
            return
        }
        const formData = new FormData()
        formData.append('document', documentSeleted);
        formData.append('name', documentSeleted?.name || 'file');
        if (documentTypeIdSelected) {
            formData.append('contract_type_id', documentTypeIdSelected?.id);
        }
        formData.append('type', denormalizedType);
        formData.append('customer_id', customerId);
        uploadContracts({
            params: formData,
            onSuccess: () => {
                dispatch(getAutocompleteOptions('contract-types'))
                handleGetCustomerContracts()
            },
            onFailure: () => console.log('error')
        })
    }

    const newForm = {
        inputs: [
            {
                value: 'Seleccionar archivo',
                label: i18n.t('DOCUMENTATION'),
                placeholder: i18n.t('FILE'),
                textFieldProps: {
                    fullWidth: true,
                    required: true,
                    InputLabelProps: { shrink: true },
                    type: 'file',
                    id: 'DOCUMENTATION',
                    onChange: (event) => handleSetSelectedFile(event.target.files),
                },
                InputLabelProps: {
                    shrink: true,
                },
                gridProps: { xs: 12, sm: 3 },
            },
            {
                label: 'Tipo de contrato',
                textFieldProps: {
                    id: 'documentType',
                },
                onChange: (event, value) => setDocumentTypeIdSelected(value),
                autoCompleteProps: {
                    options: setAutocompleteOptions('contract-types'),
                },
                gridProps: { xs: 3, sm: 3, md: 3, lg: 3 },
            },
            {
                label: 'Tipo de contrato desnormalizado',
                textFieldProps: {
                    fullWidth: true,
                    onChange: (event) => setDenormalizedType (event.target.value) ,
                },
                gridProps: { xs: 3, sm: 3 , md: 3, lg: 3 },
            },
        ],
        buttons: [
            {
                label: 'Subir documento',
                gridProps: { xs: 12, sm: 6, md: 6, lg: 3 },
                disabled: true,
                buttonProps: { variant: 'contained' },
                onClick: () => upload() },

        ],
        grid: { spacing: 3 },
    };


    return (
        <Paper>
            <div className={classes.mainContainer}>
                <Form newForm={newForm} />
                {Boolean(tableData.titles?.length)}
                <div style={{ marginTop: '20px' }}>
                    {isLoading
                        ? (
                            <LoadingSpinner />
                        )
                        : (
                            <Table
                                key={'1'}
                                tableData={tableData}
                                hasStatusColors
                                orderBy={orderBy}
                                orderProducts={orderProducts}
                                onOrderProducts={handleRequestSort}
                            />
                        )}
                </div>
            </div>
        </Paper>
    );
}


CustomerDocuments.propTypes = {
};

function mapStateToProps({ autocompleteReducer }) {
    return {
        autocompleteOptions: autocompleteReducer.autocompleteOptions,
    };
}

export default connect(mapStateToProps)(CustomerDocuments);
