import actionTypes from '../actions/actionTypes';

export default function orderReducer(state = {}, { type, data }) {
  let newState = null;

  switch (type) {
    case actionTypes.LOAD_ERROR:
      newState = { ...state, error: data };
      break;
    case actionTypes.LOAD_ORDERS:
      newState = { ...state, orders: data };
      break;
    case actionTypes.EMPTY_ORDERS:
      newState = { ...state, orders: null };
      break;
    case actionTypes.LOAD_VALIDATED_ORDERS:
      newState = { ...state, validatedOrders: data };
      break;
    case actionTypes.LOAD_VALIDATED_ORDER_DETAIL:
      newState = { ...state, validatedOrderDetail: data };
      break;
    case actionTypes.EMPTY_VALIDATED_ORDERS:
      newState = { ...state, validatedOrders: [] };
      break;
    case actionTypes.LOAD_TRACKING:
      newState = { ...state, tracking: data };
      break;
    case actionTypes.EMPTY_TRACKING:
      newState = { ...state, tracking: {} };
      break;
    case actionTypes.LOAD_ORIGIN:
      newState = { ...state, orderOrigin: data };
      break;
    case actionTypes.EMPTY_ORIGIN:
        newState = { ...state, orderOrigin: {}};
        break;
    case actionTypes.LOAD_DESTINY:
      newState = { ...state, orderDestiny: data };
      break; 
    case actionTypes.EMPTY_DESTINY:
      newState = { ...state, orderDestiny: {}};
      break;
    default:
      newState = state;
      break;
  }

  return newState;
}
