import React, { useContext, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Paper, InputAdornment, CircularProgress } from '@material-ui/core';
import { AccountCircle, Lock } from '@material-ui/icons';
import { useHistory } from 'react-router-dom';
import { loginUser } from '../../redux/actions/userActions';
import Form from '../../Components/Form';
import logo from '../../assets/logo-gruas.png';
import PushNotification from '../../Components/PushNotification';
import { emptyAlerts } from '../../redux/actions/alertActions';
import Global from '../../Contexts/Global';
import useStyles from './style';

function Login({ dispatch, actualUser, alerts }) {
  const { i18n } = useContext(Global);
  const [userLogin, setUserLogin] = useState({ name: '', password: '' });
  const classes = useStyles();
  const history = useHistory();
  const [openAlert, setOpenAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [alertSeverity, setAlertSeverity] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const enterKey = 'Enter';

  const form = {
    inputs: [
      {
        label: i18n.t('USER'),
        name: 'user',
        placeholder: '',
        textFieldProps: {
          onChange: (event) => setUserLogin({ ...userLogin, name: event.target.value }),
          fullWidth: true,
          InputProps: {
            startAdornment: (
              <InputAdornment position="start">
                <AccountCircle />
              </InputAdornment>
            ),
          },
        },
        gridProps: { xs: 12, md: 12, lg: 12 },
      },
      {
        label: i18n.t('PASSWORD'),
        name: 'password',
        placeholder: '',
        textFieldProps: {
          onChange: (event) => setUserLogin({ ...userLogin, password: event.target.value }),
          fullWidth: true,
          type: 'password',
          InputProps: {
            startAdornment: (
              <InputAdornment position="start">
                <Lock />
              </InputAdornment>
            ),
          },
        },
        gridProps: { xs: 12 },
      },
    ],
    buttons: [
      {
        label: 'LOGIN',
        buttonProps: {
          type: 'button',
          variant: 'contained',
          fullWidth: true,
          className: classes.loginButton,
        },
        onClick: () => dispatch(loginUser({ user: userLogin, setIsLoading })),
        gridProps: { xs: 12, style: { display: 'flex', justifyContent: 'center', paddingTop: '30px' } },
      },
    ],
  };

  if (actualUser || actualUser === '' || localStorage.getItem('user')) {
    if (actualUser?.create_order === 1) {
      history.push('/new-order-request');
    }
    if (actualUser?.forecast === 1) {
      history.push('/previsions');
    }
    if (actualUser?.contracts === 1) {
      history.push('/contracts');
    }
    if (actualUser?.invoices === 1) {
      history.push('/bills');
    }
    if (actualUser?.orders === 1) {
      history.push('/orders-in-progress');
    }
  }

  useEffect(() => {
    if (alerts.length) {
      setAlertMessage(alerts[0].message);
      setAlertSeverity(alerts[0].severity);
      setOpenAlert(true);
    }
  }, [alerts.length]);

  const handleCloseAlert = () => {
    setOpenAlert(false);
    dispatch(emptyAlerts());
  };

  return (
    <div className={classes.loginContainer}>
      <div className={classes.formContainer}>
        <Paper
          className={classes.paper}
          onKeyDown={(event) => event.key === enterKey
            && dispatch(loginUser({ user: userLogin, setIsLoading }))}
        >
          <div className={classes.logoCont}>
            <img src={logo} alt="Gruas Logo" className={classes.logo} />
          </div>
          {isLoading
            ? (
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <div style={{ padding: '7vh 15vw' }}>
                  <CircularProgress
                    color="primary"
                  />
                </div>
              </div>
            )
            : <Form newForm={form} />}
        </Paper>
      </div>
      <PushNotification
        open={openAlert}
        message={alertMessage}
        handleClose={handleCloseAlert}
        anchor={{ top: '-3px', right: '10px' }}
        severity={alertSeverity}
      />
    </div>
  );
}

Login.propTypes = {
  dispatch: PropTypes.func.isRequired,
  actualUser: PropTypes.bool.isRequired,
  alerts: PropTypes.arrayOf(PropTypes.shape({
    message: PropTypes.string,
    severity: PropTypes.string,
  })).isRequired,
};

function mapStateToProps({ userReducer, alertReducer }) {
  return {
    actualUser: userReducer.actualUser,
    alerts: alertReducer.alerts,
  };
}

export default connect(mapStateToProps)(Login);
