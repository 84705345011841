/* eslint-disable max-len */
import React, { useContext, useState } from 'react';
import { Paper } from '@material-ui/core';
import Table from '../../Components/Table';
import Global from '../../Contexts/Global';
import Form from '../../Components/Form';
import LoadingSpinner from '../../Components/LoadingSpinner';
import {getCustomers} from "../../redux/actions/customerActions";
import useStyles from './style'
import {setActualCustomer} from "../../redux/actions/userActions";
import PropTypes from "prop-types";
import {connect} from "react-redux";

const initialTableData = {
    titles: [
        { align: 'center', title: 'CLIENTE', sortColumn: 'description' },
        { align: 'center', title: 'DIRECCION', sortColumn: 'address' },
        { align: 'center', title: 'POBLACIÓN', sortColumn: 'location' },
        { align: 'center', title: 'CORREO', sortColumn: 'email'},
        { align: 'center', title: '' },
        { align: 'center', title: '' },
        { align: 'center', title: '' },
    ],
    rows: [],
};
const initialFilters = {}
function getColumnName(column) {
    const columns = ['order_date', 'model', 'charge_to'];
    const columnNames = ['created_at', 'orders.craneModel', 'charge_to_id'];
    let columnName = column;
    if (columns.includes(column.toLowerCase())) {
        columnName = columnNames[columns.findIndex((name) => name === column.toLowerCase())];
    }
    return columnName;
}

function AdminCustomers({ dispatch }) {
    const { i18n } = useContext(Global);
    const classes = useStyles();
    const [tableData, setTableData] = useState(initialTableData);
    const [isLoading, setIsLoading] = useState(false);
    const [filters, setFilters] = useState(initialFilters)
    const [orderBy, setOrderBy] = useState('');
    const [orderProducts, setOrderProducts] = useState('asc');

    async function handleRequestSort(column) {
        const isAsc = orderBy === column && orderProducts === 'asc';
        const newParams = { ...filters, order: isAsc ? 'desc' : 'asc', orderBy: getColumnName(column) };
        setOrderBy(column);
        setOrderProducts(isAsc ? 'desc' : 'asc');
        setFilters(newParams);
        searchCustomers()
    }

    function searchCustomers() {
        setIsLoading(true)
        getCustomers({
            params: filters,
            onSuccess: customers => {
                setIsLoading(false)
                if (!customers) {
                    return
                }
                setTableData({
                        ...tableData,
                        rows: customers.map((customer) => ({
                            customer: customer.name,
                            address: customer.address,
                            location: customer.location,
                            email: customer.email ? customer.email : ' ',
                            link3: {
                                title: 'Configurar email',
                                reference: 'Detail',
                                link: true,
                                url: `/customer-email/${customer.id}`,
                                label: 'detail',
                                onClick:() => {
                                    dispatch(setActualCustomer(customer.id))
                                }
                            },
                            link: {
                                title: 'Configurar localizaciones',
                                reference: 'Detail',
                                link: true,
                                url: `/customer-locations/${customer.id}`,
                                label: 'detail',
                                onClick:() => {
                                    dispatch(setActualCustomer(customer.name))
                                }
                            },
                            link2: {
                                title: 'Administrar  contratos',
                                reference: 'Detail',
                                link: true,
                                url: `/customer-contracts/${customer.id}`,
                                label: 'detail',
                                onClick:() => {
                                    dispatch(setActualCustomer(customer.name))
                                }
                            }
                        }))
                });
            },
            onFailure: (error) => setIsLoading(false)

        })
    }

    const newForm = {
        inputs: [
            {
                label: 'Cliente',
                textFieldProps: {
                    fullWidth: true,
                    onChange: (event) => {
                        setFilters({ ...filters, name: event.target.value });
                    },
                    onKeyPress: (e) => { if(e.key === 'Enter'){ e.preventDefault(); searchCustomers();} },
                },
                gridProps: { xs: 12, sm: 6, md: 4, lg: 4 },
            },
            
            {
                label: 'Dirección', 
                
                textFieldProps: {
                    fullWidth: true, 
                    onChange: (event) => {
                        setFilters({...filters, address: event.target.value}); 
                    }, 
                    onKeyPress: (e) => { if(e.key === 'Enter') { e.preventDefault(); searchCustomers(); }},
                },
                gridProps: { xs: 12, sm: 6, md: 4, lg: 4},
            },
        ],
        buttons: [
            { label: i18n.t('SEARCH'), gridProps: { xs: 12, sm: 12, md: 4, lg: 4 }, buttonProps: { variant: 'contained' }, onClick: () => searchCustomers() },
        ],
        grid: { spacing: 3 },
    };


    return (
        <Paper>
            <div className={classes.mainContainer}>
                <Form newForm={newForm} />
                {Boolean(tableData.titles?.length)}
                <div style={{ marginTop: '20px' }}>
                    {isLoading
                        ? (
                            <LoadingSpinner />
                        )
                        : (
                            <Table
                                tableData={tableData}
                                hasStatusColors
                                orderBy={orderBy}
                                orderProducts={orderProducts}
                                onOrderProducts={handleRequestSort}
                            />
                        )}
                </div>
            </div>
        </Paper>
    );
}

AdminCustomers.propTypes = {
    dispatch: PropTypes.func.isRequired,
};
function mapStateToProps({ requestDetailReducer }) {
    return {
        order: requestDetailReducer.requestDetailData,
    };
}

export default connect(mapStateToProps)(AdminCustomers);
