import actionTypes from './actionTypes';
import apiClient from '../../domain/fetchers/apiClient';
import { loadError } from './newRequestActions';

export function setRequestDetail(detail) {
  return {
    type: actionTypes.SET_REQUEST_DETAIL,
    data: detail,
  };
}

export function emptyRequestDetail() {
  return {
    type: actionTypes.EMPTY_REQUEST_DETAIL,
  };
}

export function getRequestDetail({ requestNumber, setIsLoading = () => {} }) {
  setIsLoading(true);
  return async (dispatch) => {
    if (requestNumber) {
      const endpoint = 'orders/' + requestNumber;
      try {
        const { status, data } = await apiClient.get(endpoint);
        if (status === 200) {
          dispatch(setRequestDetail(data.data));
        }
      } catch (error) {
        dispatch(loadError(error));
      }
    }
    setIsLoading(false);
  };
}

export function editRequestDetail({ id, value }) {
  return {
    type: actionTypes.EDIT_REQUEST_DETAIL,
    data: { id, value },
  };
}
