import actionTypes from './actionTypes';
import apiClient from '../../domain/fetchers/apiClient';
import { loadError } from './newRequestActions';
import {getEndpointParams} from "../../utility";
import { setAlert } from './alertActions';

export function setBills(bills) {
  return {
    type: actionTypes.SET_BILLS,
    data: bills,
  };
}

export function emptyBills() {
  return {
    type: actionTypes.EMPTY_BILLS,
  };
}

export function createAlert(alert, dispatch) {
  dispatch(setAlert(alert));
}

export function getBills({ params, setIsLoading = () => {} }) {
  setIsLoading(true);
  return async (dispatch) => {
    let endpoint = 'bills';

    if (params instanceof Object && Object.keys(params).length) {
      const filters = [];

      Object.keys(params).forEach((key, index) => {
        if (params[key] || params[key] === 0) {
          if (index !== 0) {
            filters.push('&');
          }
          filters.push(`${key}=${params[key]}`);
        }
      });

      endpoint += `?${filters.join('')}`;
    }

    try {
      const { status, data } = await apiClient.get(endpoint);
      if (status === 200) {
        dispatch(setBills(data.data));
      }
    } catch (error) {
      dispatch(loadError(error));
    }
    setIsLoading(false);
  };
}

export function erpSync({ route, filters = {} }) {
  let url = null;
  let alert = null;
  return async (dispatch) => {
    url = '/bills/erpsync'; 
    const endpoint = getEndpointParams(url, filters);
    try {
      const { data } = await apiClient.get(endpoint);
      alert = {
          message: data?.message || 'Facturas sincronizadas correctamente',
          severity: data.status = 200 ? 'success' : 'error',
      };
      dispatch(setAlert(alert));
    } catch (error) {
      dispatch(loadError(error));
    }
  };
}
