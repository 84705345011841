/* eslint-disable max-len */
import React, { useContext, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Paper } from '@material-ui/core';
import Table from '../../Components/Table';
import Global from '../../Contexts/Global';
import Form from '../../Components/Form';
import { emptyUsers, getUsers } from '../../redux/actions/userActions';
import { getAutocompleteOptions } from '../../redux/actions/autocompleteActions';
import LoadingSpinner from '../../Components/LoadingSpinner';
import useStyles from './style';

const initialTableData = {
  titles: [
    { align: 'center', title: 'CUSTOMER' },
    { align: 'center', title: 'USER' },
    { align: 'center', title: 'STATUS' },
    { align: 'center', title: '' },
  ],
  rows: [],
};

const initialParams = {
  order: 'desc',
  orderBy: 'created_at',
};

function getColumnName(column) {
  const columns = ['order_date', 'model', 'charge_to'];
  const columnNames = ['created_at', 'orders.craneModel', 'charge_to_id'];
  let columnName = column;
  if (columns.includes(column.toLowerCase())) {
    columnName = columnNames[columns.findIndex((name) => name === column.toLowerCase())];
  }
  return columnName;
}

function AdminUsers({ dispatch, users, autocompleteOptions }) {
  const { i18n } = useContext(Global);
  const classes = useStyles();
  const [tableData, setTableData] = useState(initialTableData);
  const [params, setParams] = useState(initialParams);
  const [orderBy, setOrderBy] = useState('');
  const [orderProducts, setOrderProducts] = useState('asc');
  const [isLoading, setIsLoading] = useState(false);
  const autocompleteIds = ['customers', 'users'];
  const date = new Date();
  let month = date.getMonth();
  if (month < 10) month = `0${month}`;
  let day = date.getDate();
  if (day < 10) day = `0${day}`;

  async function handleRequestSort(column) {
    const isAsc = orderBy === column && orderProducts === 'asc';
    const isDesc = orderBy === column && orderProducts === 'desc';
    let newParams = { ...params };

    if (isDesc) {
      newParams = { ...initialParams };
      setOrderBy(false);
    } else {
      newParams = { ...params, order: isAsc ? 'desc' : 'asc', orderBy: getColumnName(column) };
      setOrderBy(column);
    }
    setOrderProducts(isAsc ? 'desc' : 'asc');
    setParams(newParams);
    dispatch(getUsers({ params: newParams, setIsLoading }));
  }

  function setAutocompleteOptions(id) {
    let newOptions = [];
    if (autocompleteOptions && autocompleteOptions[id]) {
      newOptions = autocompleteOptions[id].map((option) => ({
        ...option,
        title: option.name || option.username || '',
      }));
    }
    return newOptions;
  }

  function searchUsers() {
    if (Object.values(params).length) {
      dispatch(getUsers({ params, setIsLoading }));
    } else {
      dispatch(getUsers({ setIsLoading }));
    }
  }
  
  const newForm = {
    inputs: [
      {
        label: i18n.t('CUSTOMER'),
        placeholder: 'Dato filtrado',
        textFieldProps: { fullWidth: true, value: 'Dato filtrado' },
        autoCompleteProps: {
          options: setAutocompleteOptions('customers'),
        },
        onChange: (event, value) => setParams({ ...params, client: value?.id }),
        gridProps: { xs: 12, sm: 6, md: 6, lg: 3 },
      },
      {
        label: i18n.t('USER'),
        placeholder: 'Dato filtrado',
        textFieldProps: { fullWidth: true, value: 'Dato filtrado' },
        autoCompleteProps: {
          options: setAutocompleteOptions('users'),
        },
        onChange: (event, value) => setParams({ ...params, user: value?.id }),
        gridProps: { xs: 12, sm: 6, md: 6, lg: 3 },
      },
      {
        label: i18n.t('STATUS'),
        placeholder: 'Dato filtrado',
        textFieldProps: { fullWidth: true, value: 'Dato filtrado' },
        gridProps: { xs: 12, sm: 6, md: 6, lg: 3 },
        autoCompleteProps: {
          options: [{ title: i18n.t('ACTIVE'), id: 1 }, { title: i18n.t('INACTIVE'), id: 0 }],
        },
        onChange: (event, value) => setParams({ ...params, status: value?.id }),
      },
    ],
    buttons: [
      { label: i18n.t('SEARCH'), gridProps: { xs: 12, sm: 6, md: 6, lg: 3 }, buttonProps: { variant: 'contained' }, onClick: () => searchUsers() },
    ],
    grid: { spacing: 3 },
  };

  useEffect(() => {
    dispatch(getUsers({ params, setIsLoading }));
    autocompleteIds.forEach((id) => {
      dispatch(getAutocompleteOptions(id));
    });
  }, []);

  useEffect(() => {
    if (users) {
      setTableData({
        ...tableData,
        rows: users.map((user) => ({
          client: user.client_id?.name || '',
          userName: user.username || '',
          status: user.active ? i18n.t('ACTIVE') : i18n.t('INACTIVE'),
          link: {
            title: 'Ver detalle',
            reference: 'Detail',
            link: true,
            url: `/user/${user.id}`,
            label: 'detail',
          },
        })),
      });
    }
  }, [users]);

  useEffect(() => () => {
    dispatch(emptyUsers());
  }, []);

  return (
    <Paper>
      <div className={classes.mainContainer}>
        <Form newForm={newForm} />
        {Boolean(tableData.titles?.length)}
        <div style={{ marginTop: '20px' }}>
          {isLoading
            ? (
              <LoadingSpinner />
            )
            : (
              <Table
                tableData={tableData}
                hasStatusColors
                orderBy={orderBy}
                orderProducts={orderProducts}
                onOrderProducts={handleRequestSort}
              />
            )}
        </div>
      </div>
    </Paper>
  );
}

AdminUsers.propTypes = {
  dispatch: PropTypes.func.isRequired,
  users: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  autocompleteOptions: PropTypes.shape({}).isRequired,
};

function mapStateToProps({ userReducer, autocompleteReducer }) {
  return {
    users: userReducer.users,
    autocompleteOptions: autocompleteReducer.autocompleteOptions,
  };
}

export default connect(mapStateToProps)(AdminUsers);
