import actionTypes from '../actions/actionTypes';

export default function autocompleteReducer(state = {}, { type, data }) {
  let newState = null;

  switch (type) {
    case actionTypes.SET_ALERT:
      newState = {
        ...state,
        alerts: [data],
      };
      break;
    case actionTypes.EMPTY_ALERTS:
      newState = {
        ...state,
        alerts: [],
      };
      break;
    default:
      newState = state;
      break;
  }

  return newState;
}
