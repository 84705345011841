import { makeStyles } from '@material-ui/core';

const paperPaddingX = 6;
const paperPaddingY = 3;

const useStyles = makeStyles((theme) => ({
  loginContainer: {
    minWidth: '260px',
    height: '100vh',
    backgroundColor: theme.palette.primary.light,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  formContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '40%',
  },
  paper: {
    paddingLeft: theme.spacing(paperPaddingX),
    paddingRight: theme.spacing(paperPaddingX),
    paddingTop: theme.spacing(paperPaddingY),
    paddingBottom: theme.spacing(paperPaddingY),
    minWidth: '180px',
  },
  logoCont: {
    display: 'flex',
    justifyContent: 'center',
    padding: theme.spacing(2),
    borderTopLeftRadius: '50px',
    borderBottomRightRadius: '50px',
    marginBottom: theme.spacing(2),
  },
  logo: {
    width: '8vw',
    minWidth: '80px',
    height: 'auto',
  },
  loginButton: {
    backgroundColor: theme.palette.primary.main,
  },
}));

export default useStyles;
