import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    padding: theme.spacing(3),
  },
  initialForm: {
    marginBottom: '10px',
  },
  [theme.breakpoints.down('sm')]: {
    locationsBlock: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      marginBottom: '10px',
      width: '100%',
    },
    locationSection: {
      margin: '15px 0px 20px 0px',
      boxShadow: '0px 3px 5px -1px rgb(0 0 0 / 20%), 0px 5px 8px 0px rgb(0 0 0 / 14%), 0px 1px 14px 0px rgb(0 0 0 / 12%)',
      // outline: '1px solid gray',
      borderRadius: '10px',
      // marginBottom: '5px',
      width: '100%',
      padding: '5px 0',
    },
  },
  [theme.breakpoints.up('md')]: {
    locationsBlock: {
      display: 'flex',
      justifyContent: 'space-between',
      marginBottom: '10px',
    },
    locationSection: {
      margin: '15px 0px 20px 0px',
      boxShadow: '0px 3px 5px -1px rgb(0 0 0 / 20%), 0px 5px 8px 0px rgb(0 0 0 / 14%), 0px 1px 14px 0px rgb(0 0 0 / 12%)',
      // outline: '1px solid gray',
      borderRadius: '10px',
      // marginBottom: '5px',
      width: '49%',
      padding: '5px 0',
    },
  },
}));
export default useStyles;
