import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    // width: '100%',
  },
  textFieldLink: {
    cursor: 'pointer',
    color: 'blue',
  },
  buttonField: {
    marginTop: theme.spacing(1),
  },
  button: {
    position: 'absolute',
    top: 0,
    right: 0,
    margin: theme.spacing(1),
    zIndex: 100,
  },
  gridItems: {
    display: 'flex',
    justifyContent: 'flex',
    position: 'relative',
  },
  buttonGridItem: {
    display: 'flex', 
    justifyContent: 'end',
  },
  formContainer: {
    position: 'relative',
    width: '100%',
  },
  moreFilters: {
    position: 'absolute',
    right: 0,
    top: 0,
  },
  outerLabel: {
    display: 'flex',
    alignItems: 'center',
    marginRight: theme.spacing(1),
  },
  buttonLink: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    color: 'blue',
    textDecoration: 'underline',
    marginLeft: '8px',
  },
  simpleText: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginLeft: '8px',
  },
  addButton: {
    marginLeft: '8px',
    marginBottom: '8px',
  }
}));

export default useStyles;
