const actionTypes = {
  LOAD_ERROR: 'LOAD_ERROR',
  LOAD_USER: 'LOAD_USER',
  LOAD_ORDERS: 'LOAD_ORDERS',
  EMPTY_ORDERS: 'EMPTY_ORDERS',
  SET_NEW_ORDER: 'SET_NEW_ORDER',
  SET_REQUEST_DATA: 'SET_REQUEST_DATA',
  EMPTY_NEW_REQUEST_DATA: 'EMPTY_NEW_REQUEST_DATA',
  SET_AUTOCOMPLETE_OPTIONS: 'SET_AUTOCOMPLETE_OPTIONS',
  EMPTY_AUTOCOMPLETE_OPTIONS: 'EMPTY_AUTOCOMPLETE_OPTIONS',
  SET_ALERT: 'SET_ALERT',
  EMPTY_ALERTS: 'EMPTY_ALERTS',
  SET_REQUEST_DETAIL: 'SET_REQUEST_DETAIL',
  EMPTY_REQUEST_DETAIL: 'EMPTY_REQUEST_DETAIL',
  EDIT_REQUEST_DETAIL: 'EDIT_REQUEST_DETAIL',
  SET_PREVISIONS: 'SET_PREVISIONS',
  EMPTY_PREVISIONS: 'EMPTY_PREVISIONS',
  SET_BILLS: 'SET_BILLS',
  EMPTY_BILLS: 'EMPTY_BILLS',
  SET_CONTRACTS: 'SET_CONTRACTS',
  EMPTY_CONTRACTS: 'EMPTY_CONTRACTS',
  LOAD_VALIDATED_ORDERS: 'LOAD_VALIDATED_ORDERS',
  EMPTY_VALIDATED_ORDERS: 'EMPTY_VALIDATED_ORDERS',
  LOAD_USERS: 'LOAD_USERS',
  EMPTY_USERS: 'EMPTY_USERS',
  SET_USER_DETAIL: 'SET_USER_DETAIL',
  EDIT_USER_DETAIL: 'EDIT_USER_DETAIL',
  EMPTY_USER_DETAIL: 'EMPTY_USER_DETAIL',
  LOAD_VALIDATED_ORDER_DETAIL: 'LOAD_VALIDATED_ORDER_DETAIL',
  LOAD_TRACKING: 'LOAD_TRACKING',
  LOAD_ORIGIN: 'LOAD_ORIGIN',
  EMPTY_ORIGIN: 'EMPTY_ORIGIN',
  LOAD_DESTINY: 'LOAD_DESTINY',
  EMPTY_DESTINY: 'EMPRTY_DESTINY',
  EMPTY_TRACKING: 'EMPTY_TRACKING',
  SET_DOCUMENT: 'SET_DOCUMENT',
  EMPTY_DOCUMENT: 'EMPTY_DOCUMENT',
  ACTUAL_CUSTOMER: 'ACTUAL_CUSTOMER',
  SAVE_FILTERS: 'SAVE_FILTERS',
  EDIT_CUSTOMER_DETAIL: 'EDIT_CUSTOMER_DETAIL',
};

export default actionTypes;
