import actionTypes from '../actions/actionTypes';

export default function orderReducer(state = {}, { type, data }) {
  let newState = null;

  switch (type) {
    case actionTypes.LOAD_ERROR:
      newState = { ...state, error: data };
      break;
    case actionTypes.LOAD_USER:
      newState = { ...state, actualUser: data };
      break;
    case actionTypes.LOAD_USERS:
      newState = { ...state, users: data };
      break;
    case actionTypes.EMPTY_USERS:
      newState = { ...state, users: [] };
      break;
    case actionTypes.SET_USER_DETAIL:
      newState = { ...state, userDetail: data };
      break;
    case actionTypes.EMPTY_USER_DETAIL:
      newState = { ...state, userDetail: null };
      break;
    case actionTypes.ACTUAL_CUSTOMER:
      newState = { ...state, actualCustomer: data };
      break;
    case actionTypes.EDIT_CUSTOMER_DETAIL:
      newState = { 
        ...state, 
        actualCustomer: { ...state.actualCustomer, [data.id]: data.value},
      };
      break; 
    case actionTypes.EDIT_USER_DETAIL:
      newState = {
        ...state,
        userDetail: { ...state.userDetail, [data.id]: data.value },
      };
      break;
    default:
      newState = state;
      break;
  }

  return newState;
}
