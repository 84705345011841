import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
  },
  startHeader: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    width: '50%',
    transition: theme.transitions.create(['margin', 'height'], {
      easing: theme.transitions.easing.sharpen,
      duration: theme.transitions.duration.standard,
    }),
  },
  endHeader: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    width: '50%',
  },
  menuButton: {
    color: '#fff',
    marginRight: theme.spacing(2),
  },
  logoContainer: {
    flexGrow: 1,
  },
}));

export default useStyles;
