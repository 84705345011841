import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import useStyles from './BasicButtonStyles';

export default function BasicButton({
  title, children, onClickFunction, variant, contrast, style,
}) {
  const classes = useStyles();

  return (
    <Button
      variant={variant}
      onClick={onClickFunction}
      className={contrast ? classes.contrast : classes.button}
      style={{ ...style }}
    >
      {title || children}
    </Button>
  );
}

BasicButton.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node,
  variant: PropTypes.string,
  contrast: PropTypes.bool,
  onClickFunction: PropTypes.func,
  style: PropTypes.shape({}),
};

BasicButton.defaultProps = {
  title: '',
  children: null,
  variant: 'contained',
  contrast: false,
  onClickFunction: () => {},
  style: {},
};
