/* eslint-disable */
import React from 'react';

export const setAutocompleteOptions = (id, autocompleteOptions) => {
    let newOptions = [];
    if (autocompleteOptions && autocompleteOptions[id]) {
        newOptions = autocompleteOptions[id].map((option) => ({
            ...option,
            title: option?.name,
        }));
    }
    return newOptions;
}

export const checboxes = [
    { title: 'Crear solicitud', key: 'create_order' },
    { title: 'Ver solicitud', key: 'view_order' },
    { title: 'Pedidos', key: 'orders' },
    { title: 'Previsiones', key: 'forecast' },
    { title: 'Facturas', key: 'invoices' },
    { title: 'Contratos', key: 'contracts'  },
]

export const getConfig = props => {
    const {
        i18n,
        autocompleteOptions,
        onChangeFormElement,
    } = props

    const initialForm = {
        inputs: [],
        grid: { spacing: 3 },
    };

    initialForm.inputs =  [
        {
            key: 'client_id',
            label: i18n.t('CLIENT'),
            placeholder: 'Client 1',
            textFieldProps: {
                fullWidth: true,
            },
            onChange: (event, value) => onChangeFormElement('client_id', value?.id),
            autoCompleteProps: {
                options: setAutocompleteOptions('customers', autocompleteOptions),
            },
            gridProps: { lg: 3 },
        },
        {
            key: 'status_id',
            label: i18n.t('STATUS'),
            placeholder: 'Active',
            textFieldProps: {
                fullWidth: true,
            },
            onChange: (event, value) =>  onChangeFormElement('status_id', value?.id),
            autoCompleteProps: {
                options: [{ id: 0, title: 'Activo' }, { id: 1, title: 'Inactivo' }],
                // defaultValue: userDetail.active ? { id: 0, title: 'Activo' } : { id: 1, title: 'Inactivo' },
            },
            gridProps: { lg: 3 },
        },
        {
            key: 'name',
            label: i18n.t('NAME'),
            placeholder: 'Juan',
            textFieldProps: {
                fullWidth: true,
                onChange: event => onChangeFormElement('name',  event.target.value),
            },
            gridProps: { lg: 3 },
        },
        {
            key: 'lastname',
            label: i18n.t('LASTNAME'),
            placeholder: 'Gómez',
            textFieldProps: {
                fullWidth: true,
                onChange: event => onChangeFormElement('lastname',  event.target.value),
            },
            gridProps: { lg: 3 },
        },
        {
            key: 'user',
            label: i18n.t('USER'),
            textFieldProps: {
                fullWidth: true,
                onChange: (event, value) => onChangeFormElement('user',  event.target.value),
            },
            gridProps: { lg: 3 },
        },
        {
            key: 'email',
            label: i18n.t('EMAIL'),
            placeholder: 'user@mail.com',
            textFieldProps: {
                fullWidth: true,
                onChange: (event, value) => onChangeFormElement('email',  event.target.value),

            },
            gridProps: { lg: 3 },
        },
        {
            key: 'password',
            label: i18n.t('PASSWORD'),
            placeholder: '',
            textFieldProps: {
                fullWidth: true,
                onChange: (event) => onChangeFormElement('password',  event.target.value),
                type: 'password',
            },
            gridProps: { lg: 3 },
        },
        {
            key: 'cpassword',
            label: i18n.t('CONFIRM_PASSWORD'),
            placeholder: '',
            textFieldProps: {
                fullWidth: true,
                onChange: (event) =>  onChangeFormElement('password_confirmation',  event.target.value),
                type: 'password',
            },
            gridProps: { lg: 3 },
        },
        {
            key: 'preferred_origin_id',
            label: i18n.t('DEFAULT_ORIGIN'),
            placeholder: 'Barcelona',
            textFieldProps: {
                fullWidth: true,
            },
            onChange: (event, value) => onChangeFormElement('preferred_origin_id', value?.id),
            autoCompleteProps: {
                options: setAutocompleteOptions('locations', autocompleteOptions),
            },
            gridProps: { lg: 3 },
        },
        {
            key: 'preferred_destination_id',
            label: i18n.t('DEFAULT_DESTINY'),
            placeholder: 'Barcelona',
            textFieldProps: {
                fullWidth: true,
            },
            onChange: (event, value) => onChangeFormElement('preferred_destination_id', value?.id),
            autoCompleteProps: {
                options: setAutocompleteOptions('locations', autocompleteOptions),
            },
            gridProps: { lg: 3 },
        },
        {
            key: 'external_reference_type_id',
            label: i18n.t('EXTERNAL_REFERENCE_TYPE'),
            placeholder: 'Active',
            textFieldProps: {
                fullWidth: true,
            },
            onChange: (event, value) => onChangeFormElement('external_reference_type_id', value?.id),
            autoCompleteProps: {
                options: setAutocompleteOptions('external-reference-types', autocompleteOptions),
            },
            gridProps: { lg: 3 },
        },
        {
            label: i18n.t('ROLE'),
            key: 'role_id',
            textFieldProps: {
                fullWidth: true,
            },
            onChange: (event, value) => onChangeFormElement('role_id', value?.id),
            autoCompleteProps: {
                options: [{ id: 1, title: i18n.t('ADMIN') }, { id: 2, title: i18n.t('CLIENT') }],
            },
            gridProps: { lg: 3 },
        },
    ]

    return {
        initialForm,
    }
}