import React from 'react';
import PropTypes from 'prop-types';
import { Autocomplete } from '@material-ui/lab';
import { TextField } from '@material-ui/core';

export default function MultiselectField({ item }) {
  return (
    <Autocomplete
      multiple
      fullWidth
      defaultValue={item.defaultValue ?? []}
      value={item.defaultValue ?? []}
      key={item?.textFieldProps?.id}
      onChange={item.onChange}
      options={item.multiselectProps?.options}
      getOptionLabel={(option) => option?.title || option?.name || ' ' }
      disabled={item.readOnly}
      renderInput={(params) => (
        <TextField
          {...params}
          {...item.textFieldProps}
          InputLabelProps={item.InputLabelProps}
          margin="dense"
          variant="outlined"
          label={item.label}
          fullWidth
          style={{ marginLeft: '8px' }}
        />
      )}
    />
  );
}

MultiselectField.propTypes = {
  item: PropTypes.shape({
    label: PropTypes.string,
    textFieldProps: PropTypes.shape({
      id: PropTypes.string,
    }),
    multiselectProps: PropTypes.shape({
      options: PropTypes.arrayOf(PropTypes.shape({})),
    }),
    InputLabelProps: PropTypes.shape({}),
    onChange: PropTypes.func.isRequired,
  }).isRequired,
};

MultiselectField.defaultProps = {};
