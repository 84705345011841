export const conditionalElement = (expresion, element) => (expresion ? [element] : []);

export const getEndpointParams = (endpoint, params) => {
    if (params instanceof Object && Object.keys(params).length) {
        const filters = [];

        Object.keys(params).forEach((key, index) => {
            if (params[key] || params[key] === 0) {
                if (index !== 0) {
                    filters.push('&');
                }
                filters.push(`${key}=${params[key]}`);
            }
        });

       return endpoint += `?${filters.join('')}`;
    }

    return endpoint
}


