import actionTypes from './actionTypes';
import apiClient from '../../domain/fetchers/apiClient';
import { setAlert } from './alertActions';

export function loadError(error) {
  return {
    type: actionTypes.LOAD_ERROR,
    data: error,
  };
}

export function setRequestData({ id, value }) {
  return {
    type: actionTypes.SET_REQUEST_DATA,
    data: { id, value },
  };
}

export function createAlert(alert, dispatch) {
  dispatch(setAlert(alert));
}

export function sendNewOrderRequest(orderRequest = {}, documents) {
  return async (dispatch) => {
    const endpoint = 'orders';
    const requestData = {};
    let newRequestID = null;
    if (Object.keys(orderRequest).length) {
      Object.keys(orderRequest).forEach((key) => {
        if (orderRequest[key] instanceof Object
          && ((orderRequest[key] instanceof Array) === false)) {
          if (key.includes('_id')) {
            requestData[key] = orderRequest[key]?.id;
          } else {
            requestData[key] = orderRequest[key]?.title;
          }
        } else {
          requestData[key] = orderRequest[key];
        }
      });
    }

    let alert = null;
    try {
      const response = await apiClient.post(endpoint, requestData);
      console.log("response", response.status, response.data);
      if (response.status === 200 && response.data) {
        if (response.data == -1) {
          alert = {
            message: 'Ha habido un error al intentar tramitar su solicitud, por favor póngase en contacto con nuestro equipo de atención al cliente, gracias. Código de error (ERROR API).',
            severity: 'error',
            duration: 100000
          };
          newRequestID = response.data;
        } else {
          alert = {
            message: '¡La solicitud se ha creado con éxito!',
            severity: 'success',
          };
          localStorage.setItem('alert', JSON.stringify(alert));
          newRequestID = response.data;
        }
      } else {
        let stringError = response + "";
        if(stringError.toLowerCase().indexOf("network") !== -1){
          setTimeout(() => {
            window.location.href = window.location.origin+"/order-requests";
          }, 1000*30);
        }else{
          alert = {
            message: response.data?.message || 'Ha ocurrido un error',
            severity: 'error',
          };
        }
      }
    } catch (error) {
      alert = {
        message: error.stack,
        severity: 'error',
      };
    }
    createAlert(alert, dispatch);

    if (newRequestID == -1) {
      return
    }
    
    let count = 0; 

    if (newRequestID && documents.length > 0) {
      const documentEndpoint = endpoint + `/${newRequestID}/add-document`;
      
      await Promise.all(documents.map(async (doc) => {
        const formData = new FormData();
        formData.append('file', doc);
        formData.append('name', doc?.name || 'file');
        count = count + 1; 
        try {
          const response = await apiClient.post(documentEndpoint, formData);
          if(response.status === 200) {
            if(count >= documents.length){
              window.location.href = '/order-requests';
            }
          }
        } catch (error) {
          alert = {
            message: error.stack,
            severity: 'error',
          };
          createAlert(alert, dispatch);
        }
      }));
    } else if (newRequestID && documents.length == 0) {
      window.location.href = '/order-requests';
    }
  };
}

export function goToOrders(){
  
} 

export function emptyNewRequestData() {
  return {
    type: actionTypes.EMPTY_NEW_REQUEST_DATA,
  };
}

export function setSaved(isSaved) {
  return {
    type: actionTypes.SET_SAVED,
    data: isSaved,
  };
}
