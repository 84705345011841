import actionTypes from './actionTypes';
import apiClient from '../../domain/fetchers/apiClient';
import {getEndpointParams} from "../../utility";

function loadError(error) {
  return {
    type: actionTypes.LOAD_ERROR,
    data: error,
  };
}

function setAutocompleteOptions({ id, value }) {
  return {
    type: actionTypes.SET_AUTOCOMPLETE_OPTIONS,
    data: { id, value },
  };
}

export function getAutocompleteOptions(id, params = null) {
  let url = null;
  if(params) {
    url = getEndpointParams(id, params)
  } else {
    url  = id
  }
  return async (dispatch) => {
    try {
      const response = await apiClient.get(url);
      if (response.status === 200) {
        dispatch(setAutocompleteOptions({ id, value: response.data.data }));
      }
    } catch (error) {
      dispatch(loadError(error));
    }
  };
}

export function emptyAutocompleteOptions() {
  return {
    type: actionTypes.EMPTY_AUTOCOMPLETE_OPTIONS,
  };
}
