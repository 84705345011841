/* eslint-disable max-len */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import {
  Paper,
  Table as MaterialTable,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow as MaterialRow,
  TablePagination,
  TableSortLabel,
} from '@material-ui/core';
import Global from '../../Contexts/Global';
import TableRow from '../TableRow';
import useStyles from './style';

export default function Table({ tableData, orderBy, orderProducts, onOrderProducts, pagination, pageObject, rowsPerPageObject, pageCount }) {
  const classes = useStyles();
  const { i18n } = useContext(Global);
  const tableRows = tableData?.rows?.length ? tableData.rows.map((row) => ({ row })) : null;

  function handleChangeOrder(column) {
    onOrderProducts(column);
  }

  return (
    <TableContainer component={Paper} style={{ position: 'relative' }}>
      <MaterialTable className={classes.table} aria-label="simple table">
        <TableHead>
          <MaterialRow>
            {tableData?.titles?.length && tableData?.titles?.map((title) => {
              let cell = null;
              if (title.title.length > 0) {
                cell = (
                  <TableCell align="center" className={classes.columnTitle} key={title.title}>
                    <TableSortLabel
                      active={orderBy === title.title || orderBy === title.sortColumn}
                      direction={orderBy === (title.sortColumn ?? title.title) ? orderProducts : 'asc'}
                      onClick={() => handleChangeOrder(title.sortColumn ?? title.title)}
                    >
                      <span style={{marginLeft: '28px'}}>{i18n.t(title.title).toUpperCase()}</span>
                    </TableSortLabel>
                  </TableCell>
                );
              } else {
                cell = (
                  <TableCell align="center" className={classes.columnTitle} key={title.title}>
                    <span style={{ marginLeft: '28px'}}>{i18n.t(title.title).toUpperCase()} </span>
                  </TableCell>
                );
              }
              return cell;
            })}
          </MaterialRow>
        </TableHead>
        <TableBody id="table" style={{ position: 'relative' }}>
          {Boolean(tableRows?.length) && tableRows.map((row) => (
            <TableRow
              row={row.row}
              backgroundColor={row.selected ? '#2e1f46' : null}
            />
          ))}
        </TableBody>
      </MaterialTable>
    </TableContainer>
  );
}

Table.propTypes = {
  tableData: PropTypes.shape({
    titles: PropTypes.arrayOf(PropTypes.shape({})),
    rows: PropTypes.arrayOf(PropTypes.shape({})),
    rowActions: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
  selectedItems: PropTypes.shape({
    items: PropTypes.arrayOf({}),
    setItems: PropTypes.func,
    key: PropTypes.string,
  }),
  onOrderProducts: PropTypes.func,
  orderBy: PropTypes.string,
  orderProducts: PropTypes.string,
  pageObject: PropTypes.shape({
    page: PropTypes.number,
    handleChangePage: PropTypes.func,
  }),
  rowsPerPageObject: PropTypes.shape({
    rowsPerPage: PropTypes.number,
    handleChangeRowsPerPage: PropTypes.func,
  }),
  pageCount: PropTypes.number,
  pagination: PropTypes.bool,
};

Table.defaultProps = {
  selectedItems: {},
  onOrderProducts: () => { },
  orderBy: '',
  orderProducts: '',
  pageObject: {
    page: 1,
    handleChangePage: () => { },
  },
  rowsPerPageObject: {
    rowsPerPage: 1,
    setRowsPerPage: () => { },
  },
  pageCount: 100,
  pagination: false,
};
