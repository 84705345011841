/* eslint-disable */
import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import { Paper, Switch, CircularProgress } from '@material-ui/core';
import Global from '../../Contexts/Global';
import Form from '../../Components/Form';
import { saveUser } from '../../redux/actions/userActions';
import { getAutocompleteOptions } from '../../redux/actions/autocompleteActions';
import useStyles from './style';
import { getConfig, checboxes } from './configs'
import PushNotification from "../../Components/PushNotification";
import {emptyAlerts, setAlert} from "../../redux/actions/alertActions";

const NewUser = props => {
    const {
        dispatch,
        autocompleteOptions = null
    } = props
    const autocompleteIds = ['external-reference-types', 'customers', 'locations'];

    const { i18n } = useContext(Global);

    const history = useHistory()
    const classes = useStyles();
    const [form, setForm] = useState({});
    // const [showErrorAlert, setShowErrorAlert] = useState(false)
    const [user, setUser] = useState({
        create_order: false,
        view_order: false,
        orders: false,
        forecast: false,
        invoices: false,
        contracts: false,
        active: true
    });
    const [selectedClientId, setSelectedClientId] = useState(null)

    useEffect(() => {
        autocompleteIds.forEach((id) => {
            dispatch(getAutocompleteOptions(id));
        });

        return () => {
            dispatch(emptyAlerts())
        }
    },[])

    useEffect(() => {
        if(!selectedClientId) {
            return
        }
        dispatch(getAutocompleteOptions('locations', {
            customer_id: selectedClientId
        }))

    }, [selectedClientId])

    const onChangeFormElement = (key, value) => {
        if (key == 'client_id') {
            setSelectedClientId(value)
        }
        setUser(user => ({...user, [key]: value}))
    }


    useEffect(() => {
        const params = {
            i18n,
            autocompleteOptions,
            onChangeFormElement
        }

        const {
            initialForm,
        } =  getConfig(params)

        setForm(initialForm)
    },[autocompleteOptions])

    const save = () => {
        saveUser({
            request: user,
            onSuccess: () => {
                history.push('/admin-users')
            },
            onError: (error) => {
                dispatch(setAlert({ message: error, severity: 'error' }));
            }
        })
    }

    const saveForm = {
        inputs: [],
        buttons: [
            {
                label: i18n.t('SAVE'),
                gridProps: { lg: 12 },
                buttonProps: { variant: 'contained' },
                onClick: save },
        ],
        grid: { spacing: 3 },
    };

    if (!Array.isArray(autocompleteOptions.customers) || !Array.isArray(autocompleteOptions.locations)) {
        return(<div style={{ display: 'flex', justifyContent: 'center'}}>
                <CircularProgress
                    color="inherit" />
            </div>)
    }

    return (
        <Paper>
            <div className={classes.mainContainer}>
                <Form newForm={form} isNew/>
                <div className={classes.checboxArea}>
                    <div className={classes.checboxContainer}>
                        {checboxes.map((checkbox, index) => (
                            <div className={classes.checboxBox}>
                                {checkbox.title}
                                <Switch
                                    color="primary"
                                    onChange={(event => onChangeFormElement(checkbox.key, event.target.checked))}
                                    checked={checboxes[index].checked} 
                                />
                            </div>
                        ))}
                    </div>
                </div>
                <Form newForm={saveForm} />
            </div>
            {/*<PushNotification*/}
            {/*    open={showErrorAlert}*/}
            {/*    message={"Por favor, complete todos los campos..."}*/}
            {/*    handleClose={() => setShowErrorAlert(false)}*/}
            {/*    anchor={{ top: '-3px', right: '10px' }}*/}
            {/*    severity={"error"}*/}
            {/*/>*/}
        </Paper>
    );
}

NewUser.propTypes = {
    dispatch: PropTypes.func.isRequired,
    autocompleteOptions: PropTypes.shape({}).isRequired,
};

function mapStateToProps({ userReducer, autocompleteReducer }) {
    return {
        userDetail: userReducer.userDetail,
        autocompleteOptions: autocompleteReducer.autocompleteOptions,
    };
}

export default connect(mapStateToProps)(NewUser);
