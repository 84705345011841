/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Grid } from '@material-ui/core';
import clsx from 'clsx';
import NavigationBar from '../Components/NavigationBar';
import SideBar from '../Components/SideBar';
import PushNotification from '../Components/PushNotification';
import { emptyAlerts, setAlert } from '../redux/actions/alertActions';
import useStyles from './style';

const Layout = ({ children, alerts, dispatch }) => {
  const classes = useStyles();
  const [openNav, setOpenNav] = useState(false);
  const [margin, setMargin] = useState('0px');
  const [openAlert, setOpenAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [alertSeverity, setAlertSeverity] = useState('');

  useEffect(() => {
    if (openNav) {
      setMargin('240px');
    } else {
      setMargin('0px');
    }
  }, [openNav]);

  useEffect(() => {
    if (alerts.length == 0) {
      setOpenAlert(false)
      return
    }

    if (alerts.length) {
      setAlertMessage(alerts[0].message);
      setAlertSeverity(alerts[0].severity);
      setOpenAlert(true);
    }
  }, [alerts.length]);

  useEffect(() => {
    const alert = JSON.parse(localStorage.getItem('alert'));
    if (alert) {
      dispatch(setAlert(alert));
      localStorage.removeItem('alert');
    }
  }, [localStorage.getItem('alert')]);

  const handleCloseAlert = () => {
    setOpenAlert(false);
    dispatch(emptyAlerts());
  };

  return (
    <>
      <Grid container justifyContent="center" alignItems="center">
        <Grid item xs={12}>
          <NavigationBar
            menuActive={openNav}
            handleToggleDrawer={() => setOpenNav(!openNav)}
          />
        </Grid>
        <Grid item xs={12}>
          <SideBar
            open={openNav}
            handleToggleDrawer={() => setOpenNav(!openNav)}
          />
          <main
            className={clsx(classes.content, {
              [classes.contentShift]: openNav,
            })}
          >
            <div className={classes.drawerHeader} />
            <div className={classes.paper} style={{ marginLeft: margin }}>
              <div style={{ marginTop: '20px' }}>
                {children}
              </div>
            </div>
          </main>
        </Grid>
      </Grid>
      <PushNotification
        open={openAlert}
        message={alertMessage}
        handleClose={handleCloseAlert}
        anchor={{ top: '-3px'}}
        severity={alertSeverity || 'error'}
      />
    </>
  );
};

function mapStateToProps({ alertReducer }) {
  return {
    alerts: alertReducer.alerts,
  };
}
export default connect(mapStateToProps)(Layout);
