import actionTypes from '../actions/actionTypes';

export default function autocompleteReducer(state = {}, { type, data }) {
  let newState = null;

  switch (type) {
    case actionTypes.LOAD_ERROR:
      newState = { ...state, error: data };
      break;
    case actionTypes.SET_AUTOCOMPLETE_OPTIONS:
      newState = {
        ...state,
        autocompleteOptions: { ...state.autocompleteOptions, [data.id]: data.value },
      };
      break;
    case actionTypes.EMPTY_AUTOCOMPLETE_OPTIONS:
      newState = {
        ...state,
        autocompleteOptions: {},
      };
      break;
    default:
      newState = state;
      break;
  }

  return newState;
}
