import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  spinnerContainer: {
    width: '100%',
    margin: '10% 0',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

export default useStyles;
