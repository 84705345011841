/* eslint-disable max-len */
import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Paper } from '@material-ui/core';
import Table from '../../Components/Table';
import Global from '../../Contexts/Global';
import Form from '../../Components/Form';
import { emptyPrevisions, getPrevisions } from '../../redux/actions/previsionActions';
import LoadingSpinner from '../../Components/LoadingSpinner';
import useStyles from './style';
import {setFiltersAction} from "../../redux/actions/filterActions";

const initialTableData = {
  rows: [],
  titles: [
    { align: 'center', title: 'STATUS'},
    { align: 'center', title: 'CHARGE_TO' },
    { align: 'center', title: 'EXTERNAL_REFERENCE' },
    { align: 'center', title: 'REAL_DELIVERY_DATE' },
    { align: 'center', title: 'MODEL' },
    { align: 'center', title: 'REGISTRATION_AND_FRAME' },
    { align: 'center', title: 'ORIGIN' },
    { align: 'center', title: 'DESTINY' },
    { align: 'center', title: 'IMPORT', sortColumn: 'car_amount' },
    { align: 'center', title: '' },
  ],
};

const initialParams = {
  order: 'desc',
  orderBy: 'created_at',
};

function getColumnName(column) {
  const columns = {
    model: 'car_model',
    charge_to: 'charge_to_id',
    registration_and_frame: 'car_plate_number',
    origin: 'origin_province',
    destiny: 'destination_province',
    import: 'amount',
    real_delivery_date: 'delivery_real_max',
    status: 'status',
  };
  const columnName = columns[column.toLowerCase()]?.toLowerCase() ?? column;
  return columnName;
}

function getFormattedImport(actualImport) {
  const formattedImport = actualImport + '';
  const [unities, decimals] = formattedImport.split('.');
  let formattedUnities = '';

  [...unities].forEach((unity, index) => {
    if (index === unities.length - 4 && unity[index] !== '-') {
      formattedUnities += `${unity}.`;
    } else {
      formattedUnities += unity;
    }
  });

  return `${formattedUnities},${decimals} €`;
}

function Previsions({ dispatch, previsions }) {
  const { i18n } = useContext(Global);
  const classes = useStyles();
  const [tableData, setTableData] = useState(initialTableData);
  const [filterValues, setFilterValues] = useState({
    search: '',
  });
  const [params, setParams] = useState(initialParams);
  const [orderBy, setOrderBy] = useState('');
  const [orderProducts, setOrderProducts] = useState('asc');
  const [isLoading, setIsLoading] = useState(false);
  const date = new Date();
  let month = date.getMonth();
  if (month < 10) month = `0${month}`;
  let day = date.getDate();
  if (day < 10) day = `0${day}`;

  useEffect(() => dispatch(setFiltersAction(params)),[params])

  async function handleRequestSort(column) {
    const isAsc = orderBy === column && orderProducts === 'asc';
    const isDesc = orderBy === column && orderProducts === 'desc';
    let newParams = { ...params, order: isAsc ? 'desc' : 'asc', orderBy: getColumnName(column) };
    if (isDesc) {
      setOrderBy(false);
    } else {
      setOrderBy(column);
    }
    setOrderProducts(isAsc ? 'desc' : 'asc');
    setParams(newParams);
    dispatch(getPrevisions({ params: newParams, setIsLoading }));
  }

  function searchPrevisions() {
    if (Object.values(params).length) {
      dispatch(getPrevisions({ params, setIsLoading }));
    } else {
      dispatch(getPrevisions({ setIsLoading }));
    }
  }

  const newForm = {
    inputs: [
      {
        label: i18n.t('SEARCH'),
        placeholder: 'Dato filtrado',
        textFieldProps: {
          value: filterValues.search,
          fullWidth: true,
          onChange: (event) => {
            setFilterValues({ ...filterValues, search: event.target.value });
            setParams({ ...params, search: event?.target?.value });
          },
          onKeyPress: (e) => { if(e.key === 'Enter'){ e.preventDefault();  searchPrevisions(); } },
        },
        gridProps: { xs: 12, sm: 6, md: 3, lg: 3 },
      },
    ],
    buttons: [
      { label: i18n.t('SEARCH'), gridProps: { xs: 12, sm: 6, md: 9, lg: 9 }, buttonProps: { variant: 'contained' }, onClick: searchPrevisions },
    ],
    grid: { spacing: 3 },
  };

  useEffect(() => {
    dispatch(getPrevisions({ params, setIsLoading }));
    return () => {
      dispatch(emptyPrevisions());
    };
  }, []);

  useEffect(() => {
    if (previsions) { 
      setTableData({
        ...tableData,
        rows: Object.values(previsions).map((prevision) => ({
          status: prevision.statusName, 
          chargeTo: prevision.charge_to?.name ?? '',
          externalReference: prevision.external_reference ?? '',
          real_arrival_date: prevision.tracking_delivery_real_max?.slice(0, 10).split('-').reverse().join('-') ?? '',
          // real_arrival_date: 'Cargando...',
          model: prevision.car_model ?? '',
          plateNumber: prevision.car_plate_number ?? '',
          origin: prevision.origin_province ?? prevision.location_origin_province ?? '',
          destiny: prevision.destination_province ?? prevision.location_destination_province ?? '',
          import: prevision.amount ? { title: getFormattedImport(prevision.amount), alignCell: 'center' } : '',
        })),
      });
    }
  }, [previsions]);

  return (
    <Paper>
      <div className={classes.mainContainer}>
        <Form newForm={newForm} />
        <div className={classes.tableContainer}>
          {isLoading
            ? (
              <LoadingSpinner />
            )
            : (
              <Table
                tableData={tableData}
                hasStatusColors
                orderBy={orderBy}
                orderProducts={orderProducts}
                onOrderProducts={handleRequestSort}
              />
            )}
        </div>
      </div>
    </Paper>
  );
}

Previsions.propTypes = {
  dispatch: PropTypes.func.isRequired,
  previsions: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

function mapStateToProps({ previsionReducer }) {
  return {
    previsions: previsionReducer.previsions,
  };
}

export default connect(mapStateToProps)(Previsions);
