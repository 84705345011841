/* eslint-disable max-len */
import React, {useContext, useEffect, useState} from 'react';
import { useLocation } from 'react-router-dom';
import { Paper } from '@material-ui/core';
import Form from '../../Components/Form';
import useStyles from './style'
import {connect} from "react-redux";
import Global from '../../Contexts/Global';
import {updateCustomerEmail , setCustomerEmail} from "../../redux/actions/customerActions";
function CustomerEmail({ dispatch, actualCustomer}) {
const { i18n } = useContext(Global);
const location = useLocation();
const classes = useStyles();
const [currEmail, setEmail] = useState('');
const customerId = location.pathname.split('/')[location.pathname.split('/').length - 1];


useEffect (() => {
        setCustomerEmail({
            params: {customer: customerId},
            onSuccess: (email) => {
                setEmail(email)
            },
        });
}, [])

const sendNewEmail = () => {
    let email = currEmail; 
    if(currEmail.length === 0){
        email = 'deleted'; 
    }
    updateCustomerEmail({
        params: {
            customer_id: customerId,
            email: email },
        onSuccess: (success) => {
            if(success) {
                window.location.href = '/admin-customers';
            }
        },
    }); 
}
 
const newForm = {
        inputs: [
            {
                label: i18n.t('EMAIL'),
                placehoder: 'email',
                textFieldProps: {
                    id: 'location',
                    fullwidth: true, 
                    value: currEmail, 
                    onChange: (event) => setEmail (event.target.value),
                },
                gridProps: { xs: 12, sm: 6, md: 6, lg: 6 },
            },
        ],
        buttons: [
            {   label: 'Guardar', 
                gridProps: { xs: 12, sm: 6, md: 6, lg: 6 }, 
                buttonProps: { variant: 'contained' }, 
                onClick: () => sendNewEmail()},
        ],
        grid: { spacing: 3 },
    };

return (
        <Paper>
            <div className={classes.mainContainer}>
                <Form newForm={newForm} />
            </div>
        </Paper>
    );
}

export default CustomerEmail;
