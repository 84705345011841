import actionTypes from './actionTypes';
import apiClient from '../../domain/fetchers/apiClient';
import { loadError } from './newRequestActions';

export function setPrevisions(previsions) {
  return {
    type: actionTypes.SET_PREVISIONS,
    data: previsions,
  };
}

export function emptyPrevisions() {
  return {
    type: actionTypes.EMPTY_PREVISIONS,
  };
}

export function getPrevisions({ params, setIsLoading = () => {} }) {
  setIsLoading(true);
  return async (dispatch) => {
    let endpoint = 'previsions';

    if (params instanceof Object && Object.keys(params).length) {
      const filters = [];

      Object.keys(params).forEach((key, index) => {
        if (params[key] || params[key] === 0) {
          if (index !== 0) {
            filters.push('&');
          }
          filters.push(`${key}=${params[key]}`);
        }
      });

      endpoint += `?${filters.join('')}`;
    }

    try {
      const { status, data } = await apiClient.get(endpoint);
      if (status === 200) {
        dispatch(setPrevisions(data.data));
      }
    } catch (error) {
      dispatch(loadError(error));
    }
    setIsLoading(false);
  };
}
