import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Button, TextField, Typography } from '@material-ui/core';
import { AddCircleOutline } from '@material-ui/icons';
import { Autocomplete } from '@material-ui/lab';
import { Link } from 'react-router-dom';
import Global from '../../Contexts/Global';
import { getDocument } from '../../redux/actions/documentActions';
import MultiselectField from '../MultiselectField';
import TextFieldComponent from '../TextFieldComponent';
import useStyles from './style';

export default function FlexForm({ form }) {
  const { i18n } = useContext(Global);
  const classes = useStyles();

  return (
    <div className={classes.container}>
      {form && form.inputs?.map((item) => (
        <div className={classes.filterInput} key={item.label}>
          {item.outerLabel && (
          <Typography className={classes.outerLabel}>
            {item.outerLabel}
          </Typography>
          )}
          {item.addBtn && (
          <Button
            className={classes.button}
            startIcon={<AddCircleOutline />}
            onClick={item.addBtn.function}
          >
            {item.addBtn.label}
          </Button>
          )}
          {
            item.space && <div />
          }
          { item.linkButtonProps && (
            <div
              className={classes.buttonLink}
              onClick={() => {
                item.linkButtonProps.onClick(item.linkButtonProps.id)
              }}
            >
              <span>{i18n.t(item.label)}</span>
            </div>
          )}
          { item.textProps && (
            <div className={classes.simpleText}>
              <span>{i18n.t(item.label)}</span>
            </div>
          )}
          { !item.space && !item.multiselectProps && !item.textProps
              && !item.autoCompleteProps && !item.linkButtonProps && !item.documentProps && (
                <TextFieldComponent item={item} />
          )}
          { !item.textFieldProps && item.textFieldProps?.type === 'date' && (
                <TextField
                  {...item.textFieldProps}
                  onClick={() => item.documentProps.hasValue
                          && item.documentProps.dispatch(getDocument({
                            id: item.documentProps.id,
                            name: item.documentProps.name,
                          }))}
                  inputProps={{
                    readOnly: false,
                    className: item.documentProps.hasValue && classes.textFieldLink,
                  }}
                  InputLabelProps={item.InputLabelProps}
                  select={false}
                  margin="dense"
                  label={item.label}
                  placeholder={item.placeholder}
                  className={classes.dateField}
                  name={item.name}
                  variant="outlined"
                />
          )}
          { !item.space && !item.multiselectProps && !item.textProps
              && !item.autoCompleteProps && !item.linkButtonProps && item.documentProps && (
                <TextField
                  {...item.textFieldProps}
                  onClick={() => item.documentProps.hasValue
                          && item.documentProps.dispatch(getDocument({
                            id: item.documentProps.id,
                            name: item.documentProps.name,
                          }))}
                  inputProps={{
                    readOnly: false,
                    className: item.documentProps.hasValue && classes.textFieldLink,
                  }}
                  InputLabelProps={item.InputLabelProps}
                  select={false}
                  margin="dense"
                  label={item.label}
                  placeholder={item.placeholder}
                  className={classes.textField}
                  name={item.name}
                  variant="outlined"
                />
          )}
          { !item.space && item.multiselectProps && !item.autoCompleteProps && (
            <MultiselectField item={item} />
          )}
          { !item.space && item.autoCompleteProps
              && item.autoCompleteProps?.options && item.readOnly && (
                <TextField
                  {...item.textFieldProps}
                  inputProps={{
                    readOnly: true,
                  }}
                  InputLabelProps={item.InputLabelProps}
                  select={false}
                  margin="dense"
                  label={item.label}
                  placeholder={item.placeholder}
                  className={classes.textField}
                  name={item.name}
                  variant="outlined"
                />
          )}
          { !item.space && item.autoCompleteProps
              && item.autoCompleteProps?.options && !item.readOnly && (
                <Autocomplete
                  options={item.autoCompleteProps.options}
                  getOptionLabel={(option) => option.title}
                  onChange={item.onChange}
                  fullWidth
                  disabled={item.autoCompleteProps?.disabled}
                  defaultValue={item.autoCompleteProps.defaultValue}
                  value={item.autoCompleteProps.value}
                  renderInput={(autocompleteParams) => (
                    <TextField
                      {...autocompleteParams}
                      {...item.textFieldProps}
                      InputLabelProps={item.InputLabelProps}
                      variant="outlined"
                      label={item.label}
                      placeholder={item.placeholder}
                      className={classes.textField}
                      margin="dense"
                    />
                  )}
                />
          )}
        </div>
      ))}
      {form?.buttons?.map((button) => (
        <div className={classes.buttonContainer} key={button.label}>
          {(button.linkProps && button.linkProps.href)
            ? (
              <Link to="/order-requests">
                <Button
                  {...button.buttonProps}
                  color="primary"
                  onClick={() => button.onClick()}
                  className={classes.buttonField}
                >
                  {button.label}
                </Button>
              </Link>
            )
            : (
              <Button
                {...button.buttonProps}
                color="primary"
                onClick={() => button.onClick()}
                className={classes.buttonField}
              >
                {button.label}
              </Button>
            )}
        </div>
      ))}
    </div>
  );
}

FlexForm.propTypes = {
  form: PropTypes.shape({
    inputs: PropTypes.arrayOf(PropTypes.shape({})),
    buttons: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
};
