import actionTypes from '../actions/actionTypes';

export default function requestDetailReducer(state = {}, { type, data }) {
  let newState = null;

  switch (type) {
    case actionTypes.SET_REQUEST_DETAIL:
      newState = {
        ...state,
        requestDetailData: data,
      };
      break;
    case actionTypes.EMPTY_NEW_REQUEST_DATA:
      newState = {
        ...state,
        requestDetailData: {},
      };
      break;
    case actionTypes.EDIT_REQUEST_DETAIL:
      newState = {
        ...state,
        requestDetailData: { ...state.requestDetailData, [data.id]: data.value },
      };
      break;
    case actionTypes.EMPTY_REQUEST_DETAIL:
      newState = {
        ...state,
        requestDetailData: {},
      };
      break;
    default:
      newState = state;
      break;
  }

  return newState;
}
