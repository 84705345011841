import actionTypes from './actionTypes';

export function setAlert({ message, severity, duration }) {
  return {
    type: actionTypes.SET_ALERT,
    data: { message, severity, duration },
  };
}

export function emptyAlerts() {
  return {
    type: actionTypes.EMPTY_ALERTS,
  };
}
