import React from 'react';
import PropTypes from 'prop-types';
import { Autocomplete } from '@material-ui/lab';
import { TextField, Checkbox } from '@material-ui/core';
import { CheckBoxIcon, CheckBoxOutlineBlankIcon } from '@material-ui/core';

export default function NewMultiselectField({ item }) {
  return (
    <Autocomplete
      multiple
      defaultValue={item.defaultValue}
      fullWidth
      filterSelectedOptions
      value={item.defaultValue}
      key={item?.textFieldProps?.id}
      onChange={item.onChange}
      options={item.multiselectProps?.options}
      getOptionLabel={(option) => option.name}
      /*renderOption={(props, option, {selected}) => (
        <li {...props}>
          <Checkbox
            icon = { <CheckBoxOutlineBlankIcon fontSize="small" /> }
            checkedIcon = { <CheckBoxIcon fontSize="small" /> }
            checked={selected} 
          />
          {option.name}
        </li>
      )}*/
      renderInput={(params) => (
        <TextField
          {...params}
          {...item.textFieldProps}
          InputLabelProps={item.InputLabelProps}
          margin="dense"
          variant="outlined"
          label={item?.label}
          fullWidth
          style={{ marginLeft: '8px' }}
        />
      )}
    />
  );
}

NewMultiselectField.propTypes = {
  item: PropTypes.shape({
    label: PropTypes.string,
    textFieldProps: PropTypes.shape({
      id: PropTypes.string,
    }),
    multiselectProps: PropTypes.shape({
      options: PropTypes.arrayOf(PropTypes.shape({})),
    }),
    InputLabelProps: PropTypes.shape({}),
    onChange: PropTypes.func.isRequired,
  }).isRequired,
};

NewMultiselectField.defaultProps = {};
