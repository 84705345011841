/* eslint-disable react/prop-types */
/* eslint-disable max-len */
import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import { Paper, Switch, Button, Dialog, DialogActions, DialogTitle } from '@material-ui/core';
import Global from '../../Contexts/Global';
import Form from '../../Components/Form';
import { editUserDetail, emptyUserDetail, getUserDetail, saveUser, removeUser } from '../../redux/actions/userActions';
import { getAutocompleteOptions } from '../../redux/actions/autocompleteActions';
import { emptyAlerts, setAlert } from '../../redux/actions/alertActions';
import LoadingSpinner from '../../Components/LoadingSpinner';
import { getExcel } from '../../redux/actions/documentActions';
import { erpSync } from '../../redux/actions/billActions';
import useStyles from './style';
const initialForm = {
  inputs: [],
  grid: { spacing: 3 },
};

function UserDetail({ dispatch, userDetail, autocompleteOptions }) {
  const { i18n } = useContext(Global);
  const location = useLocation();
  const classes = useStyles();
  const [form, setForm] = useState(initialForm);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingSync, setIsLoadingSync] = useState(false);
  const autocompleteIds = ['external-reference-types', 'customers'];
  const [checboxes, setCheckboxes] = useState([
    { title: 'Crear solicitud', checked: false },
    { title: 'Ver solicitud', checked: false },
    { title: 'Pedidos', checked: false },
    { title: 'Previsiones', checked: false },
    { title: 'Facturas', checked: false },
    { title: 'Contratos', checked: false },
  ]);
  const [selectedClientId, setSelectedClientId] = useState(null);
  const [open, setOpen] = useState(false);
  const currentYear = new Date().getFullYear();

  const userNumber = location.pathname.split('/')[location.pathname.split('/').length - 1];

  const date = new Date();
  let month = date.getMonth();
  if (month < 10) month = `0${month}`;
  let day = date.getDate();
  if (day < 10) day = `0${day}`;

  const editUser = () => {
    saveUser({
      isEdit: true,
      request: userDetail,
      onSuccess: () => {
        const alert = {
          message: 'Usuario editado con éxito',
          severity: 'success',
        };
        dispatch(setAlert(alert));
      },
      onError: (error) => {
        dispatch(setAlert({ message: error, severity: 'error' }));
      },
    });
  };
  const deleteUser = () => {
    removeUser({
      request: userDetail?.id,
      onSuccess: () => {
        const alert = {
          message: 'Usuario eliminado con éxito',
          severity: 'success',
        };
        dispatch(setAlert(alert));
        window.location.href = '/admin-users';
      },
      onError: (error) => {
        dispatch(setAlert({ message: error, severity: 'error' }));
      },
    })
  }

  const handleClose = () => {
    setOpen(!open);
  };

  const formData2 = {
    inputs: [],
    buttons: [{
        label: !isLoadingSync ? i18n.t('GET_BILLS') : i18n.t('GETING_BILLS'),
        gridProps: { lg: 4, direction: 'column' },
        buttonProps: { variant: 'contained' },
        onClick: () => {
          setIsLoadingSync(true);
          dispatch(erpSync({ route: 'bills', filters: { year: currentYear, customer: userDetail?.client_id?.id }}))
          .then(response => setIsLoadingSync(false))
          .catch(err => setIsLoadingSync(false))
        },
      },
      {
        label: i18n.t('SAVE'),
        gridProps: { lg: 4, direction: 'column' },
        buttonProps: { variant: 'contained' },
        onClick: editUser,
      },
      {
        label: i18n.t('DELETE_USER'),
        gridProps: { lg: 4, direction: 'column' },
        buttonProps: { variant: 'outlined' },
        onClick: handleClose
      },
    ],
    grid: { spacing: 3 },
  };

  function setAutocompleteOptions(id) {
    let newOptions = [];
    if (autocompleteOptions && autocompleteOptions[id]) {
      newOptions = autocompleteOptions[id].map((option) => ({
        ...option,
        title: option?.name,
      }));
    }
    return newOptions;
  }

  useEffect(() => {
    if (!selectedClientId) {
      return
    }
    dispatch(getAutocompleteOptions('locations', {
      customer_id: selectedClientId
    }))

  }, [selectedClientId])

  useEffect(() => {
    if (userDetail && Object.keys(userDetail).length) {
      setForm({
        ...form,
        inputs: [{
            label: i18n.t('CLIENT'),
            placeholder: 'Client 1',
            textFieldProps: {
              fullWidth: true,
            },
            onChange: (event, value) => {
              setSelectedClientId(value.id)
              dispatch(editUserDetail({ id: 'client_id', value }));
            },
            autoCompleteProps: {
              options: setAutocompleteOptions('customers'),
              defaultValue: { ...userDetail.client_id, title: userDetail?.client_id?.name },
            },
            gridProps: { lg: 3 },
          },
          {
            label: i18n.t('STATUS'),
            placeholder: 'Active',
            textFieldProps: {
              fullWidth: true,
            },
            onChange: (event, value) => {
              dispatch(editUserDetail({ id: 'active', value }));
            },
            autoCompleteProps: {
              options: [{ id: 1, title: 'Activo' }, { id: 0, title: 'Inactivo' }],
              defaultValue: userDetail.active ? { id: 0, title: 'Activo' } : { id: 1, title: 'Inactivo' },
            },
            gridProps: { lg: 3 },
          },
          {
            label: i18n.t('NAME'),
            placeholder: 'Juan',
            textFieldProps: {
              fullWidth: true,
              onChange: (event) => {
                dispatch(editUserDetail({ id: 'firstname', value: event.target.value }));
              },
              defaultValue: userDetail.firstname,
            },
            gridProps: { lg: 3 },
          },
          {
            label: i18n.t('LASTNAME'),
            placeholder: 'Gómez',
            textFieldProps: {
              fullWidth: true,
              onChange: (event) => {
                dispatch(editUserDetail({ id: 'lastname', value: event.target.value }));
              },
              defaultValue: userDetail.lastname,
            },
            gridProps: { lg: 3 },
          },
          {
            label: i18n.t('USER'),
            textFieldProps: {
              fullWidth: true,
              defaultValue: userDetail.username,
              onChange: (event) => {
                dispatch(editUserDetail({ id: 'username', value: event.target.value }));
              },
            },
            gridProps: { lg: 3 },
          },
          {
            label: i18n.t('EMAIL'),
            placeholder: 'user@mail.com',
            textFieldProps: {
              fullWidth: true,
              defaultValue: userDetail?.email,
              onChange: (event) => {
                dispatch(editUserDetail({ id: 'email', value: event.target.value }));
              },
            },
            gridProps: { lg: 3 },
          },
          {
            label: i18n.t('PASSWORD'),
            placeholder: '',
            textFieldProps: {
              fullWidth: true,
              onChange: (event) => {
                dispatch(editUserDetail({ id: 'password', value: event.target.value }));
              },
              defaultValue: 'CONTRASEÑA',
              type: 'password',
            },
            gridProps: { lg: 3 },
          },
          {
            label: i18n.t('CONFIRM_PASSWORD'),
            placeholder: '',
            textFieldProps: {
              fullWidth: true,
              onChange: (event) => {
                dispatch(editUserDetail({ id: 'confirmPassword', value: event.target.value }));
              },
              defaultValue: 'CONTRASEÑA',
              type: 'password',
            },
            gridProps: { lg: 3 },
          },
          {
            label: i18n.t('DEFAULT_ORIGIN'),
            placeholder: 'Barcelona',
            textFieldProps: {
              fullWidth: true,
            },
            onChange: (event, value) => {
              dispatch(editUserDetail({ id: 'preferred_origin_id', value }));
            },
            autoCompleteProps: {
              options: setAutocompleteOptions('locations'),
              defaultValue: { ...userDetail.preferred_origin_id, title: userDetail.preferred_origin_id?.name },
            },
            gridProps: { lg: 3 },
          },
          {
            label: i18n.t('DEFAULT_DESTINY'),
            placeholder: 'Barcelona',
            textFieldProps: {
              fullWidth: true,
            },
            onChange: (event, value) => {
              dispatch(editUserDetail({ id: 'preferred_destination_id', value }));
            },
            autoCompleteProps: {
              options: setAutocompleteOptions('locations'),
              defaultValue: { ...userDetail.preferred_destination_id, title: userDetail.preferred_destination_id?.name },
            },
            gridProps: { lg: 3 },
          },
          {
            label: i18n.t('EXTERNAL_REFERENCE_TYPE'),
            placeholder: 'Active',
            textFieldProps: {
              fullWidth: true,
            },
            onChange: (event, value) => {
              dispatch(editUserDetail({ id: 'external_reference_type_id', value }));
            },
            autoCompleteProps: {
              options: setAutocompleteOptions('external-reference-types').map((type) => ({ ...type, title: i18n.t(type.title.toUpperCase()) })),
              defaultValue: { ...userDetail?.external_reference_type_id, title: i18n.t(userDetail?.external_reference_type_id?.name?.toUpperCase()) },
            },
            gridProps: { lg: 3 },
          },
          {
            label: i18n.t('ROLE'),
            placeholder: 'Barcelona',
            textFieldProps: {
              fullWidth: true,
            },
            onChange: (event, value) => {
              dispatch(editUserDetail({ id: 'role_id', value }));
            },
            autoCompleteProps: {
              options: [{ id: 1, title: i18n.t('ADMIN') }, { id: 2, title: i18n.t('CLIENT') }],
              defaultValue: { ...userDetail.role_id, title: i18n.t(userDetail.role_id?.name?.toUpperCase()) },
            },
            gridProps: { lg: 3 },
          },
        ],
      });
      setCheckboxes([{
          title: 'Crear solicitud',
          checked: userDetail.create_order,
          onChange: (event, value) => dispatch(editUserDetail({ id: 'create_order', value: +value })),
        },
        {
          title: 'Ver solicitud',
          checked: userDetail.view_order,
          onChange: (event, value) => dispatch(editUserDetail({ id: 'view_order', value: +value })),
        },
        {
          title: 'Pedidos',
          checked: userDetail.orders,
          onChange: (event, value) => dispatch(editUserDetail({ id: 'orders', value: +value })),
        },
        {
          title: 'Previsiones',
          checked: userDetail.forecast,
          onChange: (event, value) => dispatch(editUserDetail({ id: 'forecast', value: +value })),
        },
        {
          title: 'Facturas',
          checked: userDetail.invoices,
          onChange: (event, value) => dispatch(editUserDetail({ id: 'invoices', value: +value })),
        },
        {
          title: 'Contratos',
          checked: userDetail.contracts,
          onChange: (event, value) => dispatch(editUserDetail({ id: 'contracts', value: +value })),
        },
      ]);
    }
  }, [userDetail, autocompleteOptions]);

  const handleGetLocations = (userDetail) => {
    dispatch(getAutocompleteOptions('locations', {
      customer_id: userDetail.client_id?.id
    }))
  }

  useEffect(() => {
    dispatch(getUserDetail({
      userNumber,
      setIsLoading,
      onSuccess: (userDetail) => { handleGetLocations(userDetail) }
    }));
    autocompleteIds.forEach((id) => {
      dispatch(getAutocompleteOptions(id));
    });
  }, []);

  useEffect(() => () => {
    dispatch(emptyUserDetail());
    dispatch(emptyAlerts())
  }, []);

  return (
    <Paper>
      <div className={classes.mainContainer}>
        {isLoading
          ? (
            <LoadingSpinner />
          )
          : (
            <>
              <Form newForm={form} />
              <div className={classes.checboxArea}>
                <div className={classes.checboxContainer}>
                  {checboxes.map((checkbox) => (
                    <div className={classes.checboxBox} key={checkbox.title}>
                      {checkbox.title}
                      <Switch
                        color="primary"
                        checked={Boolean(checkbox.checked)}
                        onChange={checkbox.onChange}
                      />
                    </div>
                  ))}
                </div>
              </div>
              <Form newForm={formData2} />
              <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
              >
                <DialogTitle id="alert-dialog-title">
                  {"Seguro que desea eliminar este usuario?"}
                </DialogTitle>
                <DialogActions>
                  <Button onClick={handleClose} autoFocus variant='contained'>No borrar</Button>
                  <Button onClick={deleteUser} auto variant='outlined'>Borrar</Button>
                </DialogActions>
            </Dialog>
            </>
          )}
      </div>
    </Paper>
  );
}

UserDetail.propTypes = {
  dispatch: PropTypes.func.isRequired,
  userDetail: PropTypes.shape({}),
  autocompleteOptions: PropTypes.shape({}).isRequired,
};

UserDetail.defaultProps = {
  userDetail: {},
};

function mapStateToProps({ userReducer, autocompleteReducer }) {
  return {
    userDetail: userReducer.userDetail,
    autocompleteOptions: autocompleteReducer.autocompleteOptions,
  };
}

export default connect(mapStateToProps)(UserDetail);
