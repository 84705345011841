/* eslint-disable max-len */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  TableCell,
  TableRow as MaterialRow,
  Typography,
} from '@material-ui/core';
import { connect } from 'react-redux';
import Global from '../../Contexts/Global';
import { getDocument } from '../../redux/actions/documentActions';
import useStyles from './style';

function TableRow({ row, style, tableFunctions, backgroundColor, selectItems, dispatch }) {
  const classes = useStyles();
  const { i18n } = useContext(Global);
  const rowKeys = row ? Object.keys(row) : null;

  return (
    <MaterialRow className={classes.row} style={backgroundColor ? { ...style, backgroundColor } : style}>
      {rowKeys && rowKeys.map((key) => {
        if (key == 'color') {
          return
        }
        let cell = null;
        if (typeof row[key] === 'object') {
          if (row[key]?.id && row[key].name) {
            cell = (
              <TableCell
                align="center"
                className={classes.cell}
                style={backgroundColor ? { backgroundColor } : null}
                onClick={(tableFunctions?.rowClickFunction && selectItems) ? () => tableFunctions.rowClickFunction({ row }) : null}
              >
                <Typography align="center" style={{ backgroundColor: backgroundColor || 'white', color:  row['color'] ? row['color'] : backgroundColor ? 'white' : 'black', padding: '0 2px' }}>
                  {i18n.t(row[key].name)}
                </Typography>
              </TableCell>
            );
          } else if (row[key]?.link || row[key]?.link2) {
            cell = (
              <TableCell align="center" className={classes.inlineCell} style={backgroundColor ? { backgroundColor } : null}>
                <Link
                  to={row[key].url}
                  className={classes.link}
                  onClick={() => row[key]?.onClick ? row[key]?.onClick(row[key].row) : null}

                >
                  {row[key].title}
                </Link>
              </TableCell>
            );
          } else if (row[key]?.document) {
            cell = (
              <TableCell
                align="center"
                className={classes.documentCell}
                onClick={() => dispatch(getDocument({ id: row[key].id, name: row[key].nameDoc ?? row[key].title, putExtension: row[key].putExtension}))}
                style={backgroundColor ? { backgroundColor } : null}
              >
                {row[key].title}
              </TableCell>
            );
          } else if (row[key]?.coloredText) {
            cell = (
              <TableCell
                align="center"
                className={classes.inlineCell}
                style={backgroundColor ? { backgroundColor } : null}
                onClick={(tableFunctions?.rowClickFunction && selectItems) ? () => tableFunctions.rowClickFunction({ row }) : null}
              >
                <Typography
                  align="center"
                  style={row[key].padding
                    ? { color: 'black', fontWeight: 600, backgroundColor: row[key].color, padding: '2px 4px', textAlign: 'center' }
                    : { color:  row[key].color || 'black', fontWeight: 600, textAlign: 'center' }}
                >
                  {i18n.t(row[key].title)}
                </Typography>
              </TableCell>
            );
          } else if (row[key]?.alignCell) {
            cell = (
              <TableCell
                align={row[key].alignCell}
                className={classes.cell}
                style={backgroundColor ? { backgroundColor } : null}
                onClick={(tableFunctions?.rowClickFunction && selectItems) ? () => tableFunctions.rowClickFunction({ row }) : null}
              >
                <Typography align={row[key].alignCell} style={{ backgroundColor: backgroundColor || 'white', color: row['color'] ? row['color'] : backgroundColor ? 'white' : 'black', padding: '0 2px' }}>
                  {i18n.t(row[key].title)}
                </Typography>
              </TableCell>
            );
          }
        } else if ((typeof row[key] === 'string' || typeof row[key] === 'number')) {
          cell = (
            <TableCell
              align="center"
              className={classes.cell}
              style={backgroundColor ? { backgroundColor } : null}
              onClick={(tableFunctions?.rowClickFunction && selectItems) ? () => tableFunctions.rowClickFunction({ row }) : null}
            >
              <Typography align="center" style={{ backgroundColor: backgroundColor || 'white', color: row['color'] ? row['color'] : backgroundColor ? 'white' : 'black', padding: '0 2px' }}>
                {i18n.t(row[key])}
              </Typography>
            </TableCell>
          );
        }
        return cell;
      })}
    </MaterialRow>
  );
}

TableRow.propTypes = {
  row: PropTypes.shape({
    row: {
      titles: PropTypes.arrayOf(PropTypes.string),
      rows: PropTypes.arrayOf(PropTypes.shape({})),
      rowActions: PropTypes.arrayOf(PropTypes.string),
    },
    selected: PropTypes.bool,
  }).isRequired,
  style: PropTypes.shape({}),
  tableFunctions: PropTypes.shape({
    handleClickActions: PropTypes.func,
    rowClickFunction: PropTypes.func,
  }),
  backgroundColor: PropTypes.string,
  selectItems: PropTypes.bool,
  dispatch: PropTypes.func.isRequired,
};

TableRow.defaultProps = {
  style: {},
  tableFunctions: {
    rowClickFunction: () => { },
    handleClickActions: null,
  },
  backgroundColor: 'white',
  selectItems: false,
};

function mapStateToProps() {
  return {};
}

export default connect(mapStateToProps)(TableRow);
