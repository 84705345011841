import actionTypes from './actionTypes';
import apiClient from '../../domain/fetchers/apiClient';
import { createAlert } from './newRequestActions';
import { USER_URL } from '../constants/endpoints';

function loadError(error) {
  console.log('ERROR', error);
}

function loadUser(user) {
  return {
    type: actionTypes.LOAD_USER,
    data: user,
  };
}

export const getExternalReference = async ({ userId, onSuccess = () => {}, onFailure = () => {} }) => {
  if (!userId) {
    return
  }
  const endpoint = 'users/' + userId + '/externalReference'

  try {
    const { data } = await apiClient.get(endpoint);
    onSuccess(data)
  }
  catch (error) {
    onFailure(error)
  }
}

export function loginUser({ user, setIsLoading = () => {} }) {
  setIsLoading(true);
  return async (dispatch) => {
    const endpointCookie = '/csrf-cookie';
    const endpointLogin = '/login';
    const endpointUser = '/user';
    const okStatuses = [200, 204];

    try {
      const response = await apiClient.get(endpointCookie);
      if (!okStatuses.includes(response.status)) {
        Object.keys(response.response.data.errors).forEach((error) => {
          const alert = {
            message: response.response.data.errors[error],
            severity: 'error',
          };
          createAlert(alert, dispatch);
        });
      }
    }
    catch (error) {
      loadError(error);
    }

    try {
      const { response, status } = await apiClient.post(endpointLogin, user);

      if (okStatuses.includes(status)) {
        try {
          const userResponse = await apiClient.get(endpointUser);
          if (okStatuses.includes(userResponse.status)) {
            localStorage.setItem('user', JSON.stringify(userResponse.data.data));
            dispatch(loadUser(userResponse.data.data));
          }
        }
        catch (error) {
          loadError(error);
        }
      }
      else {
        Object.keys(response.data.errors).forEach((error) => {
          const alert = {
            message: response.data.errors[error],
            severity: 'error',
          };
          createAlert(alert, dispatch);
        });
      }
    }
    catch (error) {
      loadError(error);
    }
    setIsLoading(false);
  };
}

function loadUsers(users) {
  return {
    type: actionTypes.LOAD_USERS,
    data: users,
  };
}

export const setActualCustomer = customer => {
  return {
    type: actionTypes.ACTUAL_CUSTOMER,
    data: customer
  }
}

export function editCustomerDetail({id, value}) {
    return {
        type: actionTypes.EDIT_CUSTOMER_DETAIL, 
        data: { id, value }, 
    }
}

export function getUsers({ params, setIsLoading = () => {} }) {
  setIsLoading(true);
  return async (dispatch) => {
    let endpoint = 'users';
    const okStatuses = [200, 204];

    if (params instanceof Object && Object.keys(params).length) {
      const filters = [];

      Object.keys(params).forEach((key, index) => {
        if (params[key] || params[key] === 0) {
          if (index !== 0) {
            filters.push('&');
          }
          filters.push(`${key}=${params[key]}`);
        }
      });

      endpoint += `?${filters.join('')}`;
    }

    try {
      const { status, data } = await apiClient.get(endpoint);

      if (okStatuses.includes(status)) {
        dispatch(loadUsers(data.data));
      }
    }
    catch (error) {
      loadError(error);
    }
    setIsLoading(false);
  };
}

export function emptyUsers() {
  return {
    type: actionTypes.EMPTY_USERS,
  };
}

function setUserDetail(user) {
  return {
    type: actionTypes.SET_USER_DETAIL,
    data: user,
  };
}

export function getUserDetail({ userNumber, setIsLoading = () => {}, onSuccess = () => {} }) {
  setIsLoading(true);
  return async (dispatch) => {
    if (userNumber) {
      const endpoint = 'user/' + userNumber;
      try {
        const { status, data } = await apiClient.get(endpoint);
        if (status === 200) {
          onSuccess(data.data)
          dispatch(setUserDetail(data.data));
        }
      }
      catch (error) {
        dispatch(loadError(error));
      }
    }
    setIsLoading(false);
  };
}

export function emptyUserDetail() {
  return {
    type: actionTypes.EMPTY_USER_DETAIL,
  };
}

export async function logOut() {
  const endpoint = '/logout';

  try {
    await apiClient.post(endpoint);
  }
  catch (error) {
    const alert = {
      message: error.stack,
      severity: 'error',
    };
    createAlert(alert);
  }
}

export async function saveUser({ request, onSuccess = () => {}, onError = () => {}, isEdit }) {
  try {
    let response = null;
    const data = {};
    Object.keys(request).forEach((key) => {
      if (request[key] instanceof Object) {
        data[key] = request[key].id;
      }
      else if (request[key] || request[key] === 0) {
        data[key] = request[key];
      }
    });

    if (request.confirmPassword != request.password && isEdit) {
      onError('Las contraseñas no coinciden');
      return
    }

    try {
      if (isEdit) {
        response = await apiClient.put(USER_URL + `/${request.id}`, data);
      }
      else {
        response = await apiClient.post(USER_URL, request);
      }
    }
    catch (error) {
      console.log(error, 'error');
    }

    if (response.status === 200) {
      onSuccess(response.data.data);
    }
    else {
      let errors = response?.response?.data?.errors
      if (errors) {
        errors = Object.values(errors)
        let errorMessageTranslationMap = {
          'The usuario field is required.': 'El campo usuario es obligatorio.',
          'The contraseña must be at least 6 characters.': 'El campo contaseña tiene que tener más de 6 caracteres.',
          'The contraseña and confirmar contraseña must match.': 'Las contraseñas no coinciden.',
          'The tipo de referencia externa field is required.': 'El campo tipo de referencia externa es obligatorio.',
          'The rol field is required.': 'El campo rol es obligatorio.'
        };
        let translations = Object.keys(errorMessageTranslationMap);
        let newErrors = [];
        for (let i in errors) {
          let totalErrors = errors[i];
          for(let curError of totalErrors){
            let index = translations.indexOf(curError+'');
            newErrors.push(errorMessageTranslationMap[translations[index]]);
          }
        }
        onError(newErrors.join(' '));
      }
      onError('La petición no se ha podido realizar correctamente');
    }
  }
  catch (error) {
    onError(error);
  }
}

export function editUserDetail({ id, value }) {
  return {
    type: actionTypes.EDIT_USER_DETAIL,
    data: { id, value },
  };
}

export async function removeUser ({ request, onSuccess = () => {}, onError = () => {} }){
  try {
    const url = 'user/delete/' + request;
    const requestDetails = {
      user_id: request,
    };
    const response = await apiClient.delete(url);
    if (response.status === 200){
      onSuccess(response.data.data);
    } else {
      onError('Hubo un error al eliminar el usuario!');
    }
  }catch (error) {
    console.log(error, 'error');
    onError('Hubo un error al eliminar el usuario');
  }
  
}