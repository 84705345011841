/* eslint-disable react/prop-types */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-array-index-key */
import React, { useContext, useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import {
  Drawer,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Collapse,
  IconButton,
  Menu,
  MenuItem,
} from '@material-ui/core';
import {
  MenuBook,
  ExpandMore,
  NavigateNext,
  AccountCircle,
  Close,
  VerifiedUserSharp,
} from '@material-ui/icons';
import Global from '../../Contexts/Global';
import logo from '../../assets/logo-gruas.png';
import { logOut } from '../../redux/actions/userActions';
import { getSyncStatus } from '../../redux/actions/syncActions';
import useStyles from './style';
import { conditionalElement } from '../../utility';

const SideBar = ({ open: active, handleToggleDrawer }) => {
  const classes = useStyles();
  const { i18n } = useContext(Global);
  const [open, setOpen] = useState(false);
  const location = useLocation();
  const user = JSON.parse(localStorage.getItem('user'));
  const [anchorEl, setAnchorEl] = useState(null);
  const [syncStatus, setSyncStatus] = useState('Cargando...');
  
  getStatus();
  async function getStatus(){
    let currentStatus = await getSyncStatus()
    setSyncStatus(currentStatus.data);
  }

  function getSelectedTab() {
    const path = location.pathname;
    let selectedIndex = null;
    if (path === '/new-order-request') {
      selectedIndex = 0;
    } else if (path.includes('/order-request/')) {
      selectedIndex = 1;
    } else if (path === '/orders-in-progress') {
      console.log("selectedIndex user", user);
      console.log("selectedIndex ", user?.invoices);
      selectedIndex = user?.create_order ? 2 : 0;
    } else if (path.includes('/order-in-progress/')) {
      selectedIndex = 2;
    } else if (path === '/previsions') {
      selectedIndex = 3;
    } else if (path === '/bills') {
      selectedIndex = 4;
    } else if (path === '/contracts') {
      selectedIndex = 5;
    } else if (path.includes('user')) {
      selectedIndex = 6;
    } else {
      selectedIndex = 1;
    }
    return selectedIndex;
  }
  
  const [selectedIndex, setSelectedIndex] = useState(getSelectedTab());

  const categories = {
    navigation: [
      ...conditionalElement(user?.create_order,
        {
          label: 'NEW_ORDER_REQUEST',
          icon: <MenuBook />,
          key: 'new_order_request',
          route: 'new-order-request',
        }),
      ...conditionalElement(user?.view_order, {
        label: 'ORDER_REQUESTS',
        icon: <MenuBook />,
        key: 'order_requests',
        route: 'order-requests',
      }),
      ...conditionalElement(user?.orders, {
        label: 'ORDERS_IN_PROGRESS',
        icon: <MenuBook />,
        key: 'orders_in_progress',
        route: 'orders-in-progress',
      }),
      ...conditionalElement(user?.forecast, {
        label: 'PREVISIONS',
        icon: <MenuBook />,
        key: 'previsions',
        route: 'previsions',
      }),
      ...conditionalElement(user?.invoices, {
        label: 'BILLS',
        icon: <MenuBook />,
        key: 'bills',
        route: 'bills',
      }),
      ...conditionalElement(user?.contracts, {
        label: 'CONTRACTS',
        icon: <MenuBook />,
        key: 'contracts',
        route: 'contracts',
      }),
      ...conditionalElement(user?.role_id?.name === 'admin', {
        label: 'ADMIN_USERS',
        icon: <VerifiedUserSharp />,
        key: 'admin_users',
        route: 'admin-users',
      }),
      ...conditionalElement(user?.role_id?.name === 'admin', {
        label: 'ADMIN_CUSTOMERS',
        icon: <VerifiedUserSharp />,
        key: 'admin_customers',
        route: 'admin-customers',
      }),
    ],
    end: [
      { label: user?.username || 'USERNAME', icon: <AccountCircle /> }
    ],
    syncStatus: [
      { label: syncStatus || 'syncStatus' }
    ],
  };

  const [toggleMenus, setToggleMenus] = useState(() => {
    let collapsedMenus = {};
    categories.navigation.forEach((category) => {
      if (category && category.subcategories) {
        collapsedMenus = { [category.key]: false, ...collapsedMenus };
      }
    });
    return collapsedMenus;
  });

  const handleListItemClick = (event, index) => {
    setSelectedIndex(index);
  };

  function handleToggleCollapse(prop) {
    setToggleMenus({ ...toggleMenus, [prop]: !toggleMenus[prop] });
  }

  const handleNavigate = () => {
    const hasTabletWidth = window.matchMedia('(max-width: 767px)').matches;
    if (hasTabletWidth) handleToggleDrawer();
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const logout = () => {
    logOut();
    localStorage.removeItem('user');
    window.location.href = '/login';
  };

  useEffect(() => {
    setOpen(active);
  }, [active]);

  return (
    <Drawer
      className={classes.drawer}
      variant="persistent"
      anchor="left"
      open={open}
      classes={{
        paper: classes.drawerPaper,
      }}
    >
      <List>
        <IconButton
          onClick={handleToggleDrawer}
          edge="start"
          color="inherit"
          aria-label="menu"
          className={classes.menuButton}
        >
          <Close />
        </IconButton>
        <div className={classes.logoContainer}>
          <img alt="Butransa Logo" className={classes.logo} src={logo} />
        </div>
        {categories?.navigation?.map((category, index) => (
          <React.Fragment key={category.key}>
            <Link
              to={!category.subcategories && '/' + category.route}
              className={classes.linkContainer}
              onClick={handleNavigate}
            >
              <ListItem
                button
                selected={selectedIndex === index}
                key={category.key}
                onClick={(event) => {
                  handleListItemClick(event, index);
                  handleToggleCollapse(category.key);
                }}
              >
                <ListItemIcon style={selectedIndex === index ? { color: '#fff', '&:hover': { color: 'inherit' } } : null}>{category.icon}</ListItemIcon>
                <ListItemText primary={i18n.t(category.label)} />
                {category.subcategories ? (
                  toggleMenus[category.key] ? (
                    <ExpandMore />
                  ) : (
                    <NavigateNext />
                  )
                ) : null}
              </ListItem>
            </Link>
            {category.subcategories
              && category.subcategories.map((subCategory) => (
                <Collapse
                  in={toggleMenus[category.key]}
                  timeout="auto"
                  unmountOnExit
                  key={subCategory.key}
                >
                  <List component="div" disablePadding>
                    <Link to={'/' + category.route + '/' + subCategory.route} className={classes.link}>
                      <ListItem button className={classes.nested}>
                        <ListItemIcon>{subCategory.icon}</ListItemIcon>
                        <ListItemText primary={subCategory.label} />
                      </ListItem>
                    </Link>
                  </List>
                </Collapse>
              ))}
          </React.Fragment>
        ))}
      </List>
      <Divider />
      {categories?.end && (
        <div className={classes.endList}>
          <List>
            {categories.end.map((category, index) => (
              <div key={category.label}>
                <ListItem
                  key={`listItem${index}}`}
                  button
                  onClick={handleClick}
                >
                  <ListItemIcon>{category.icon}</ListItemIcon>
                  <ListItemText primary={i18n.t(category.label)} />
                </ListItem>
                <Menu
                  id="simple-menu"
                  anchorEl={anchorEl}
                  keepMounted
                  open={Boolean(anchorEl)}
                  onClose={handleClose}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                  }}
                  transformOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                  }}
                >
                  <MenuItem onClick={logout}>{i18n.t('LOGOUT')}</MenuItem>
                </Menu>
              </div>
            ))}
            {user?.role_id?.name === 'admin' && (
              <div>
                <Divider/>
                <ListItem>
                  <ListItemText className={classes.syncStatus} primary={syncStatus} />
                </ListItem>
              </div>
            )}
          </List>
        </div>
      )}
    </Drawer>
  );
};

export default SideBar;
